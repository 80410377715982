
import { defineComponent } from "vue";
import { debounce } from "vue-debounce";
import { ElNotification, ElMessageBox } from "element-plus";
import moment from "moment";
import ElCurrencyInput from "../../components/commons/ElCurrencyInput.vue";
import { DefinitionSearchEntity } from "../../models/commons/DefinitionModels";
import {
  IdEntity,
  ExcelExportRequestEntity,
} from "../../models/commons/CommonModels";
import {
  SearchOrder,
  DeleteOrder,
  ExportOrderAsExcel,
  ExportSelectedOrderAsExcel,
  ExportPickupList,
  ExportSelectedPickupList,
  BatchConfirmShipped,
  BatchConfirmShippedSelected
} from "../../services/backstages/OrderService";
import { GetExternalBusinessDropdownList } from "../../services/backstages/ExternalBusinessService";
import { GetInternalUserDropdownList } from "../../services/backstages/InternalUserService";
import {
  OrderSearchEntity,
  OrderListResponseEntity,
  PickingListRequestEntity,
} from "../../models/backstages/OrderModels";
import { GetDefinitionDropdownList } from "../../services/DefinitionService";
import { FailAndNeedHandle } from "../../services/commons/CommonService";

export default defineComponent({
  name: "OrderListView",
  components: {
    ElCurrencyInput,
  },
  data() {
    return {
      isOrderDataLoading: false,
      isSpuStatusChanged: false,
      isOpenFilter: false,
      isOpenMenu: false,
      isOpenOrder: false,
      orderSearchEntity: {
        take: 10,
        skip: 0,
        code: "",
        status: "",
        skuCode: "",
        totalPriceStart: null!,
        totalPriceEnd: null!,
        shippingDateTimeStart: null!,
        shippingDateTimeEnd: null!,
        orderDateTimeStart: null!,
        orderDateTimeEnd: null!,
        finishDateTimeStart: null!,
        finishDateTimeEnd: null!,
        externalBusinessId: null!,
        externalBusinessName: "",
        externalUserId: null!,
        externalUserName: "",
        salesPersonId: null!,
        salesPersonName: "",
        orderCondition: {
          name: "Id",
          isAscending: false,
        },
      } as OrderSearchEntity,
      filterEntity: {
        launchDateStart: new Date(),
        launchDateEnd: new Date(),
        highestSellingPrice: undefined,
        lowestSellingPrice: undefined,
      },
      tableData: [] as Array<OrderListResponseEntity>,

      /**
       * 下拉清單
       */
      externalBusinessDropdownList: [] as any,
      orderStatusDropdownList: [] as any,
      internalUserDropdownList: [] as any,

      /**
       * Pagination vars
       */
      currentPage: 1,
      totalPage: 0,
      currentCount: 0,
      totalCount: 0,
      pageSize: 10,
      pageSizes: [10, 25, 50, 100],
    };
  },
  created() {
    this.ClearData();
    this.LoadData();
  },
  computed: {
    filterBtnText(): string {
      let text = "篩選";

      let filterCount = 0;
      if (this.orderSearchEntity.status != "") {
        filterCount++;
      }

      if (
        this.orderSearchEntity.totalPriceStart != undefined ||
        this.orderSearchEntity.totalPriceEnd != undefined
      ) {
        filterCount++;
      }

      if (
        this.orderSearchEntity.orderDateTimeStart != undefined ||
        this.orderSearchEntity.orderDateTimeEnd != undefined
      ) {
        filterCount++;
      }

      if (
        this.orderSearchEntity.shippingDateTimeStart != undefined ||
        this.orderSearchEntity.shippingDateTimeEnd != undefined
      ) {
        filterCount++;
      }

      if (
        this.orderSearchEntity.finishDateTimeStart != undefined ||
        this.orderSearchEntity.finishDateTimeEnd != undefined
      ) {
        filterCount++;
      }

      if (this.orderSearchEntity.code != "") {
        filterCount++;
      }

      if (this.orderSearchEntity.skuCode != "") {
        filterCount++;
      }

      if (this.orderSearchEntity.externalBusinessId != undefined) {
        filterCount++;
      }

      if (this.orderSearchEntity.externalUserName != "") {
        filterCount++;
      }

      if (this.orderSearchEntity.salesPersonId != undefined) {
        filterCount++;
      }

      if (filterCount != 0) {
        text = text + "(" + filterCount + ")";
      }

      return text;
    },
  },
  methods: {
    ClearConditionFilterData() {
      this.orderSearchEntity = {
        take: 10,
        skip: 0,
        code: "",
        status: "",
        skuCode: "",
        totalPriceStart: null!,
        totalPriceEnd: null!,
        shippingDateTimeStart: null!,
        shippingDateTimeEnd: null!,
        orderDateTimeStart: null!,
        orderDateTimeEnd: null!,
        finishDateTimeStart: null!,
        finishDateTimeEnd: null!,
        externalBusinessId: null!,
        externalUserName: "",
        salesPersonId: null!,
        orderCondition: {
          name: "Id",
          isAscending: false,
        },
      };
    },
    ClearData() {
      this.ClearConditionFilterData();
    },
    LoadData() {
      this.LoadDropdownList();
      this.SearchOrderData();
    },
    LoadDropdownList() {
      let searchEntity: DefinitionSearchEntity = {
        tableName: "orders",
        columnName: "status",
      };

      GetDefinitionDropdownList(searchEntity)
        .then((result) => {
          this.orderStatusDropdownList = result.data.data;
        })
        .catch((err) => {
          ElNotification({
            title: "訂單狀態清單載入失敗",
            message: err,
            duration: 3000,
            type: "error",
          });
        });

      this.LoadExternalBusinessDropdownList("");
      this.LoadInternalUserDropdownList();
    },
    LoadExternalBusinessDropdownList(query: string) {
      debounce(() => {
        GetExternalBusinessDropdownList(query)
          .then((result) => {
            if (FailAndNeedHandle(result)) {
              ElNotification({
                title: "客戶清單載入失敗",
                message: result.data.errorMessage,
                duration: 3000,
                type: "error",
              });
            } else {
              this.externalBusinessDropdownList = result.data.data;
            }
          })
          .catch(() => {
            console.error("Load LoadExternalBusinessDropdownList error");
          });
      }, "400ms")();
    },
    LoadInternalUserDropdownList() {
      GetInternalUserDropdownList()
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "負責業務清單載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            this.internalUserDropdownList = result.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    ApplyConditionFilter() {
      this.isOpenFilter = false;
      this.SearchOrderData();
    },
    ClearConditionFilter() {
      this.ClearConditionFilterData();
    },
    SearchOrderData() {
      this.isOrderDataLoading = true;

      this.orderSearchEntity.skip = this.pageSize * (this.currentPage - 1);
      this.orderSearchEntity.take = this.pageSize;

      SearchOrder(this.orderSearchEntity)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "訂單載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let paginationResult = result.data.data;
            this.tableData = paginationResult.data;
            this.currentCount = paginationResult.currentCount;
            this.totalCount = paginationResult.totalCount;
            this.totalPage = paginationResult.totalPage;
          }
        })
        .finally(() => {
          this.isOrderDataLoading = false;
        });
    },
    OpenUrl(url: string) {
      window.open(url, "_blank");
    },
    OpenFilter() {
      this.isOpenFilter = true;
    },
    OpenMenu() {
      this.isOpenMenu = true;
    },
    OpenOrder() {
      this.isOpenOrder = true;
    },
    DateFormatter(row: any, column: any) {
      let d = new Date();

      if (column.property == "orderDateTime") {
        if (row.orderDateTime == null) return "-";
        d = new Date(row.orderDateTime);
      } else if (column.property == "finishDateTime") {
        if (row.finishDateTime == null) return "-";
        d = new Date(row.finishDateTime);
      }

      let mm = d.getMonth() + 1;
      let dd = d.getDate();
      let h = d.getHours();
      let m = d.getMinutes();
      return `${d.getFullYear()}/${(mm > 9 ? "" : "0") + mm}/${(dd > 9 ? "" : "0") + dd
        } ${(h > 9 ? "" : "0") + h}:${(m > 9 ? "" : "0") + m}`;
    },
    HandleSizeChange(val: number) {
      this.pageSize = val;
      this.SearchOrderData();
    },
    HandleCurrentChange(val: number) {
      this.currentPage = val;
      this.SearchOrderData();
    },
    EditOrderAction(index: any, row: any) {
      this.OpenUrl("Edit/" + row.id);
    },
    DeleteOrderAction(index: any, row: any) {
      let id = row.id;

      ElMessageBox.confirm(`是否要刪除訂單 id: ${id}？`, "Warning", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DeleteOrder(id)
          .then(() => {
            ElNotification({
              title: `刪除訂單`,
              message: `訂單 Id:${id} 刪除成功`,
              duration: 3000,
              type: "success",
            });

            this.LoadData();
          })
          .catch(() => {
            ElNotification({
              title: `刪除訂單`,
              message: `訂單 Id:${id} 刪除失敗，請確認！`,
              duration: 3000,
              type: "error",
            });
          });
      });
    },
    /**
     * 根據搜尋條件，輸出訂單為 Excel
     */
    ExportOrderAsExcel() {
      let fileName = `訂單匯出_${moment().format("yyyy_MM_DD_hh_mm_ss")}.xlsx`;
      let entity: ExcelExportRequestEntity<OrderSearchEntity> = {
        searchCriteria: this.orderSearchEntity,
        fileName: fileName,
      };

      ExportOrderAsExcel(entity).then((result) => {
        if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "訂單匯出失敗",
            message: result.data.errorMessage,
            duration: 3000,
            type: "error",
          });
        } else {
          let data: any = result.data;
          let blob = new Blob([data]);
          var el = document.createElement("a");
          el.href = URL.createObjectURL(blob);
          el.download = fileName;
          el.click();
        }
      });
    },
    /**
     * 根據選取的訂單，輸出為 Excel
     */
    ExportSelectedOrderAsExcel() {
      let elTable = this.$refs.orderTableData as any;
      var idList = elTable.getSelectionRows().map((row: any) => {
        return {
          id: row.id,
        } as IdEntity;
      });

      if (idList == null || idList === [] || idList.length == 0) {
        ElNotification({
          title: "訂單匯出",
          message: "請選擇要匯出的訂單",
          duration: 3000,
          type: "warning",
        });
        return;
      }

      let fileName = `訂單匯出_${moment().format("yyyy_MM_DD_hh_mm_ss")}.xlsx`;

      ExportSelectedOrderAsExcel(idList).then((result) => {
        if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "訂單匯出失敗",
            message: result.data.errorMessage,
            duration: 3000,
            type: "error",
          });
        } else {
          let data: any = result.data;
          let blob = new Blob([data]);
          var el = document.createElement("a");
          el.href = URL.createObjectURL(blob);
          el.download = fileName;
          el.click();
        }
      });
    },
    HandleSelectItemClear(elementName: any, element: any) {
      element[elementName] = undefined!;
    },
    HandleSortChange(column: any) {
      if (column.column !== null) {
        this.orderSearchEntity.orderCondition.name = this.CapitalizeFirstLetter(
          column.prop
        );
        this.orderSearchEntity.orderCondition.isAscending =
          column.order == "ascending";
      } else {
        this.orderSearchEntity.orderCondition.name = "";
        this.orderSearchEntity.orderCondition.isAscending = true;
      }

      this.SearchOrderData();
    },

    /**
    * 根據搜尋條件，輸出出貨單
    */
    ExportPickupList() {
      const fileName = `出貨單匯出_${moment().format("yyyy_MM_DD_hh_mm_ss")}.pdf`;

      let entity: PickingListRequestEntity<OrderSearchEntity> = {
        isUpdateStatus: false,
        searchCondition: this.orderSearchEntity,
      };

      let isExportPdf = false;

      ElMessageBox.confirm("是否要將訂單狀態調整為出貨中", "Warning", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        distinguishCancelAndClose: true,
        type: "warning",
      }).then(() => {
        entity.isUpdateStatus = true;
        isExportPdf = true;
      }).catch((action) => {
        if (action === 'cancel') {
          entity.isUpdateStatus = false;
          isExportPdf = true;
        }
      }).finally(() => {
        if (isExportPdf == true) {
          this.isOrderDataLoading = true;

          ExportPickupList(entity).then((result) => {
            this.isOrderDataLoading = false;

            if (FailAndNeedHandle(result)) {
              ElNotification({
                title: "出貨單匯出失敗",
                message: result.data.errorMessage,
                duration: 3000,
                type: "error",
              });
            } else {
              let data: any = result.data;
              let blob = new Blob([data]);
              var el = document.createElement("a");
              el.href = URL.createObjectURL(blob);
              el.download = fileName;
              el.click();
            }
          });
        }
      });
    },

    /**
     * 根據選取的訂單，輸出為出貨單
     */
    ExportSelectedPickupList() {
      let elTable = this.$refs.orderTableData as any;
      var idList = elTable.getSelectionRows().map((row: any) => {
        return {
          id: row.id,
        } as IdEntity;
      });

      if (idList == null || idList === [] || idList.length == 0) {
        ElNotification({
          title: "出貨單匯出",
          message: "請選擇要匯出的出貨單",
          duration: 3000,
          type: "warning",
        });
        return;
      }

      const fileName = `出貨單匯出_${moment().format("yyyy_MM_DD_hh_mm_ss")}.pdf`;
      let req = { isUpdateStatus: false, searchCondition: idList } as PickingListRequestEntity<IdEntity[]>;
      let isExportPdf = false;

      ElMessageBox.confirm("是否要將訂單狀態調整為出貨中", "Warning", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        distinguishCancelAndClose: true,
        type: "warning",
      }).then(() => {
        req.isUpdateStatus = true;
        isExportPdf = true;
      }).catch((action) => {
        if (action === 'cancel') {
          req.isUpdateStatus = false;
          isExportPdf = true;
        }
      }).finally(() => {

        if (isExportPdf == true) {
          this.isOrderDataLoading = true;

          ExportSelectedPickupList(req).then((result) => {
            this.isOrderDataLoading = false;

            if (FailAndNeedHandle(result)) {
              ElNotification({
                title: "出貨單匯出失敗",
                message: result.data.errorMessage,
                duration: 3000,
                type: "error",
              });
            } else {
              let data: any = result.data;
              let blob = new Blob([data]);
              var el = document.createElement("a");
              el.href = URL.createObjectURL(blob);
              el.download = fileName;
              el.click();
            }
          });
        }
      });
    },

    /**
     * 根據搜尋條件，輸出訂單為 Excel
     */
    BatchConfirmShipped() {
      BatchConfirmShipped(this.orderSearchEntity).then((result) => {
        if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "出貨確認失敗",
            message: result.data.errorMessage,
            duration: 3000,
            type: "error",
          });
        } else {
          ElNotification({
            title: "批次出貨確認",
            message: "出貨確認成功!",
            duration: 3000,
            type: "success",
          });

          // reload data
          setTimeout(() => {
            this.LoadData();
          }, 500);
        }
      });
    },
    /**
     * 根據選取的訂單，輸出為 Excel
     */
    BatchConfirmShippedSelected() {
      let elTable = this.$refs.orderTableData as any;
      var idList = elTable.getSelectionRows().map((row: any) => {
        return {
          id: row.id,
        } as IdEntity;
      });

      if (idList == null || idList === [] || idList.length == 0) {
        ElNotification({
          title: "批次出貨確認",
          message: "請選擇訂單",
          duration: 3000,
          type: "warning",
        });
        return;
      }

      BatchConfirmShippedSelected(idList).then((result) => {
        if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "出貨確認失敗",
            message: result.data.errorMessage,
            duration: 3000,
            type: "error",
          });
        } else {
          ElNotification({
            title: "批次出貨確認",
            message: "出貨確認成功!",
            duration: 3000,
            type: "success",
          });

          // reload data
          setTimeout(() => {
            this.LoadData();
          }, 500);
        }
      });
    },

    /**
     * 將英文的首字母轉換成大寫
     * @param str 要轉換的字串
     * @returns 首字母大寫
     */
    CapitalizeFirstLetter(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    GetStatusDesc(status: string) {
      let result = "";

      switch (status) {
        case "Create":
          result = "訂單成立";
          break;
        case "Shipped":
          result = "已出貨";
          break;
        case "Arrived":
          result = "已送達";
          break;
        case "Finished":
          result = "已結單";
          break;
        case "WaitForShipping":
          result = "出貨處理中";
          break;
        case "Cancel":
          result = "取消";
          break;
        default:
          break;
      }

      return result;
    },
  },
});
