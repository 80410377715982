import { ApiResponseEntity } from "@/models/ApiModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService";
import { InternalBizInfoEntity } from "@/models/stores/InternalBusinessModels";

export async function GetInternalBizId(): Promise<
  AxiosResponse<ApiResponseEntity<InternalBizInfoEntity>, any>
> {
  const url = "/api/Auth/GetInternalBizId";
  const result = await Post<any, InternalBizInfoEntity>(url, {});

  return result;
}
