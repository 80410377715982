import { ApiResponseEntity } from "@/models/ApiModels";
import { 
    PointSettingDetailEntity,
    PointSettingUpdateEntity
} from "@/models/backstages/PointSettingModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService"

/** 
 * 取得點數設定
 * */
export async function GetPointSetting(): Promise<AxiosResponse<ApiResponseEntity<PointSettingDetailEntity>, any>> {
    const url = "/api/backstage/PointHistory/GetPointSetting";
    const result = await Post<object, PointSettingDetailEntity>(url, null!);

    return result;
}

/** 
 * 更新點數設定
 * */
export async function UpdatePointSetting(entity: PointSettingUpdateEntity): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
    const url = "/api/backstage/PointHistory/UpdatePointSetting";
    const result = await Post<PointSettingUpdateEntity, any>(url, entity);

    return result;
}