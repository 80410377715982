
import { defineComponent, h } from "vue";
import { ElNotification, ElMessage, ElMessageBox } from "element-plus";
import type { UploadUserFile } from "element-plus";
import { Plus, Delete, ZoomIn } from "@element-plus/icons-vue";
import { v4 as uuidv4 } from "uuid";
import ElTableDraggableVue from "../../components/commons/ElTableDraggable.vue";
import TextEditor from "../../components/commons/TextEditor.vue";
import HamIcon from "../../components/commons/icons/HamIcon.vue";
import {
  SpuCreateEntity,
  SpuUpdateEntity,
  SpuListResponseEntity,
  SpuOuterSysCodeDuplicationRequestEntity
} from "../../models/backstages/SpuModels";
import {
  SkuSearchEntity,
  SkuResponseEntity,
  SkuCreateEntity,
  SkuUpdateEntity,
  OuterSysCodeEntity,
} from "../../models/backstages/SkuModels";
import {
  GetSpuById,
  UpdateSpu,
  UploadSpuImage,
  CreateSpu,
  GetProductCategoryDropdownList,
  GetHashTagDropdownList,
  CheckOuterSysCodeDuplication,
} from "../../services/backstages/SpuService";
import {
  SearchSku,
  GetSkuById,
  UploadSkuImage,
  BatchCreateSku,
  BatchUpdateSku,
  CheckSkuOuterSysCodeDuplication,
} from "../../services/backstages/SkuService";
import {
  FailAndNeedHandle,
  GetAuthorizationHeaderByRoute,
} from "../../services/commons/CommonService";
import { TransformNumber } from "../../commons/FormatHelper"

export default defineComponent({
  name: "SPUInfoView",
  components: {
    Plus,
    Delete,
    ZoomIn,
    HamIcon,
    TextEditor,
    ElTableDraggableVue,
  },
  props: ["mode"],
  data() {
    return {
      title: (this.mode == "Create" ? "新增" : "編輯") + "商品",

      /**
       * Loading
       */
      loading: false,
      spuLoaded: true,
      isProductImageUploading: false,
      isSkuImageUploading: false,

      uploadSpuImageList: [] as any,
      uploadSkuImageList: [] as any,
      originalUploadSkuImageList: [] as any,

      labelPosition: "right",
      id: +this.$route.params.id || null!,

      /**
       * Spu
       */
      spuCreateEntity: {
        outerSysCode: "",
        name: "",
        productCategoryId: null!,
        description: "",
        briefDescription: "",
        skuQty: 0,
        status: "",
        unit: "",
        hashTags: [],
        imageLinks: [],
        skus: [],
        sort: 0,        
      } as SpuCreateEntity,
      spuUpdateEntity: {
        id: 0,
        outerSysCode: "",
        name: "",
        imageLinks: [],
        productCategoryId: null!,
        description: "",
        briefDescription: "",
        unit: "",
        hashTags: [],
        status: "",
        sort: 0,
      } as SpuUpdateEntity,

      /**
       * Sku
       */
      skuMode: "Create",
      currentSkuRowUuid: "",
      skuId: 0,
      skuEntityList: [] as SkuResponseEntity[],
      skuFormVisible: false,
      skuCreateEntity: {
        productId: 0,
        outerSysCode: "",
        status: "",
        name: "",
        unit: "",
        spec: "",
        price: 0,
        inventoryQty: 0,
        isAllowStockNegative: false,
        qtyPerUnitUpperLimit: undefined,
        imageLinks: [],
        sort: 0,
        canUsePoint: false,
        point: 0,
        sellPrice: 0,
        isAllowPointDiscount: false,
        isCustomOriginalPrice: false
      } as SkuCreateEntity,
      originalSkuCreateEntity: {
        productId: 0,
        outerSysCode: "",
        status: "",
        name: "",
        unit: "",
        spec: "",
        price: 0,
        inventoryQty: 0,
        isAllowStockNegative: false,
        qtyPerUnitUpperLimit: undefined,
        imageLinks: [],
        sort: 0,
        canUsePoint: false,
        point: 0,
        sellPrice: 0,
        isAllowPointDiscount: false,
        isCustomOriginalPrice: false
      } as SkuCreateEntity,
      skuFilter: "",
      skuFilterDropdownOptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "Active",
          label: "已上架 SKU",
        },
        {
          value: "Inactive",
          label: "已下架 SKU",
        },
      ],
      skuImageUploadWarnVisible: false,

      /**
       * 商品分類
       */
      productCategoryDropdownList: [] as any,

      /**
       * 圖片預覽
       */
      dialogImageUrl: "",
      dialogVisible: false,

      /**
       * Hash Tags
       */
      hashTagDropdownList: [] as any,

      /**
       * Validation rules
       */
      spuValidationRules: {
        name: [
          { required: true, message: "請輸入商品名稱", trigger: "blur" },
          { max: 50, message: "長度限制為 50 字元", trigger: "blur" },
        ],
        outerSysCode: [
          { required: true, message: "請輸入ERP對應編號", trigger: "blur" },
          { max: 50, message: "長度限制為 50 字元", trigger: "blur" },
        ],
        productCategoryId: [
          { required: true, message: "請選擇商品分類", trigger: "change" },
        ],
        sort: [
          { required: true, message: "請輸入數字 0~99999", trigger: "blur" },
          {
            type: "integer",
            min: 0,
            max: 99999,
            message: "請輸入數字 0~99999",
            trigger: "blur",
            transform: TransformNumber,
          },
        ],
      },
      skuValidationRules: {
        name: [
          { required: true, message: "請輸入SKU名稱", trigger: "blur" },
          { max: 50, message: "長度限制為 50 字元", trigger: "blur" },
        ],
        outerSysCode: [
          { required: true, message: "請輸入ERP對應編號", trigger: "blur" },
          { max: 50, message: "長度限制為 50 字元", trigger: "blur" },
        ],
        unit: [
          { required: true, message: "請輸入單位", trigger: "blur" },
          { max: 50, message: "長度限制為 50 字元", trigger: "blur" },
        ],
        price: [
          { required: true, message: "請輸入數字", trigger: "blur" },
          {
            type: "integer",
            min: 0,
            message: "數字應大於 0",
            trigger: "blur",
            transform: TransformNumber,
          },
        ],
        inventoryQty: [
          { required: true, message: "請輸入數字", trigger: "blur" },
          {
            type: "integer",
            min: 0,
            message: "數字應大於 0",
            trigger: "blur",
            transform: TransformNumber,
          },
        ],
        qtyPerUnitUpperLimit: [
          {
            validator: this.qtyPerUnitUpperLimitValidator,
            trigger: "blur",
          },
        ],
        point: [
          { required: true, message: "請輸入數字", trigger: "blur" },
          {
            type: "integer",
            min: 0,
            max: 99999,
            message: "請輸入數字 0~99999",
            trigger: "blur",
            transform: TransformNumber,
          },
        ],
        sellPrice: [
          { required: true, message: "請輸入數字", trigger: "blur" },
          {
            type: "integer",
            min: 0,
            message: "數字應大於 0",
            trigger: "blur",
            transform: TransformNumber,
          },
        ],
      },
    };
  },
  created() {
    this.ClearData();
    this.LoadData();
  },
  computed: {
    saveBtnLoading() {
      return (
        this.spuLoaded == false ||
        this.loading ||
        this.isProductImageUploading ||
        this.isSkuImageUploading
      );
    },
    isCreateMode() {
      return this.mode == "Create";
    },
    isSkuCreateMode() {
      return this.skuMode == "Create";
    },
    skuDialogTitle() {
      return this.isSkuCreateMode ? "新增 SKU" : "編輯 SKU";
    },
    skuDialogButtonText() {
      return this.isSkuCreateMode ? "新增" : "更新";
    },
    spuStatusDesc() {
      return this.spuCreateEntity.status == "Active" ? "上架" : "下架";
    },
    skuStatusDesc() {
      return this.skuCreateEntity.status == "Active" ? "上架" : "下架";
    },
    isSkuDataModified() {
      if (
        this.skuCreateEntity.name !== this.originalSkuCreateEntity.name ||
        this.skuCreateEntity.outerSysCode !==
          this.originalSkuCreateEntity.outerSysCode ||
        this.skuCreateEntity.unit !== this.originalSkuCreateEntity.unit ||
        this.skuCreateEntity.spec !== this.originalSkuCreateEntity.spec ||
        +this.skuCreateEntity.price !== +this.originalSkuCreateEntity.price ||
        +this.skuCreateEntity.inventoryQty !==
          +this.originalSkuCreateEntity.inventoryQty
      ) {
        return true;
      }

      if (
        this.uploadSkuImageList.length !==
        this.originalUploadSkuImageList.length
      ) {
        return true;
      }

      let isListItemSame = this.originalUploadSkuImageList.every(
        (element: any, index: any) => {
          return element.url == this.uploadSkuImageList[index].url;
        }
      );

      if (!isListItemSame) {
        return true;
      }

      return false;
    },
  },
  methods: {
    /**
     * 清空資料
     */
    ClearData() {
      this.ClearSkuData();
    },
    /**
     * 載入資料
     */
    LoadData() {
      this.LoadProductCategory();
      this.LoadHashTag();

      switch (this.mode) {
        case "Create":
          break;

        case "Edit":
          this.loading = true;
          this.LoadSpuData(this.id);
          this.LoadSkuData(this.id);
          break;

        default:
      }
    },
    /**
     * 載入商品分類下拉清單
     */
    async LoadProductCategory() {
      GetProductCategoryDropdownList()
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "商品分類載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            this.productCategoryDropdownList = result.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 載入標籤下拉清單
     */
    async LoadHashTag() {
      GetHashTagDropdownList()
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "商品標籤載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let map = result.data.data.map((element: any) => {
              return {
                label: element.name,
                value: element.id,
              };
            });
            this.hashTagDropdownList = map;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 載入商品資料
     */
    async LoadSpuData(id: number) {
      this.spuLoaded = false;
      await GetSpuById(id)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "商品載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let spu: SpuListResponseEntity = result.data.data;

            this.spuCreateEntity.productCategoryId = spu.productCategoryId;
            this.spuCreateEntity.outerSysCode = spu.outerSysCode;
            this.spuCreateEntity.name = spu.name;
            this.spuCreateEntity.skuQty = spu.skuQty;
            this.spuCreateEntity.status = spu.status;
            this.spuCreateEntity.imageLinks = spu.imageLinks;
            this.spuCreateEntity.description = spu.description;
            this.spuCreateEntity.briefDescription = spu.briefDescription;
            this.spuCreateEntity.hashTags = spu.hashTags.map(
              (element: any) => element.id
            );
            this.spuCreateEntity.sort = spu.sort;

            this.uploadSpuImageList = spu.imageLinks.map((element: string) => {
              return {
                name: element,
                url: element,
              };
            });

            this.spuLoaded = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 載入 SKU 資料
     */
    async LoadSkuData(productId: number) {
      let skuSearchEntity: SkuSearchEntity = {
        productId: productId,
        status: this.skuFilter,
        outerSysCode: "",
        name: "",
        highestPrice: null!,
        lowestPrice: null!,
        highestPoint: null!,
        lowestPoint: null!,
        highestInventoryQty: null!,
        lowestInventoryQty: null!,
        orderBy: "",
      };

      await SearchSku(skuSearchEntity)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "SKU 載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let skuList: Array<SkuResponseEntity> = result.data.data;
            this.skuEntityList = skuList;
            this.skuCreateEntity.productId = this.id;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 根據 SKU Id 載入 SKU 資料
     */
    async LoadSkuDataBySkuId(skuId: number) {
      await GetSkuById(skuId)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "SKU 載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let sku: SkuResponseEntity = result.data.data;
            this.skuId = sku.id;
            this.skuCreateEntity.productId = this.id;
            this.skuCreateEntity.outerSysCode = sku.outerSysCode;
            this.skuCreateEntity.name = sku.name;
            this.skuCreateEntity.price = sku.price;
            this.skuCreateEntity.status = sku.status;
            this.skuCreateEntity.unit = sku.unit;
            this.skuCreateEntity.spec = sku.spec;
            this.skuCreateEntity.inventoryQty = sku.inventoryQty;
            this.skuCreateEntity.imageLinks = sku.imageLinks;
            this.skuCreateEntity.isAllowStockNegative =
              sku.isAllowStockNegative;
            this.skuCreateEntity.qtyPerUnitUpperLimit =
              sku.qtyPerUnitUpperLimit || undefined;
            this.skuCreateEntity.isCustomOriginalPrice = sku.isCustomOriginalPrice

            this.uploadSkuImageList = sku.imageLinks.map((element: any) => {
              return {
                name: element,
                url: element,
              };
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 開啟新增 SKU dialog
     */
    OpenCreateSkuWindow() {
      this.skuMode = "Create";
      this.skuFormVisible = true;
      this.ClearSkuData();
    },
    /**
     * 開啟更新 SKU dialog
     */
    OpenUpdateSkuWindow() {
      this.skuMode = "Update";
      this.skuFormVisible = true;
      this.ClearSkuData();
    },

    ShowMessageBox(message: string, callback: Function) {
      ElMessageBox({
          title: '提醒',
          message: h('p', null, [
            h('b', {}, message),
          ]),
          showCancelButton: true,
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              callback()
            }
            done()
          },
        })
    },

    async BeforeCreateSpuAndSku() {
      let entity = {
        id: this.id,
        outerSysCode: this.spuCreateEntity.outerSysCode
      } as SpuOuterSysCodeDuplicationRequestEntity;

      let result = await CheckOuterSysCodeDuplication(entity)

      if (result.data.data) {
        this.ShowMessageBox('SPU 存在重複的 ERP對應編號，確定要新增嗎？', this.CreateSpuAndSku)
      }
      else {
        this.CreateSpuAndSku()
      }
    },

    async BeforeUpdateSpuAndSku() {
      let entity = {
        id: this.id,
        outerSysCode: this.spuCreateEntity.outerSysCode
      } as SpuOuterSysCodeDuplicationRequestEntity;

      let result = await CheckOuterSysCodeDuplication(entity)

      if (result.data.data) {
        this.ShowMessageBox('SPU 存在重複的 ERP對應編號，確定要新增嗎？', this.UpdateSpuAndSku)
      }
      else {
        this.UpdateSpuAndSku()
      }
    },
    
    /**
     * 建立 商品 及 SKU 資料
     */
    async CreateSpuAndSku() {
      if (!(await this.SpuFormValidation())) {
        return;
      }

      // should have at least 1 SKU item
      if (this.skuEntityList == undefined || this.skuEntityList.length == 0) {
        ElNotification({
          title: "SKU",
          message: "請建立至少 1 筆 SKU",
          duration: 3000,
          type: "error",
        });
        return;
      }

      this.loading = true;
      this.spuCreateEntity.status = "Active";
      this.spuCreateEntity.imageLinks = this.GetUploadedImageLinks(
        this.uploadSpuImageList
      );

      CreateSpu(this.spuCreateEntity)
        .then((result) => {
          let createdProductId: any = result.data;

          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "商品新增失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let createList = this.skuEntityList
              .filter((m) => m.id == 0)
              .map((element: SkuResponseEntity) => {
                return {
                  productId: createdProductId,
                  outerSysCode: element.outerSysCode,
                  name: element.name,
                  price: element.price,
                  status: element.status,
                  unit: element.unit,
                  spec: element.spec,
                  inventoryQty: element.inventoryQty,
                  imageLinks: element.imageLinks,
                  isAllowStockNegative: element.isAllowStockNegative,
                  qtyPerUnitUpperLimit: element.qtyPerUnitUpperLimit,
                  sort: element.sort,
                  canUsePoint: element.canUsePoint,
                  point: element.point,
                  sellPrice: element.sellPrice,
                  isAllowPointDiscount: element.isAllowPointDiscount,
                  isCustomOriginalPrice: element.isCustomOriginalPrice
                } as SkuCreateEntity;
              });

            BatchCreateSku(createList)
              .then((result) => {
                let isCreatedSuccessful = result.status > 200 == false;

                if (isCreatedSuccessful) {
                  ElNotification({
                    title: "新增",
                    message: "商品新增成功!",
                    duration: 3000,
                    type: "success",
                  });

                  this.$router.push({
                    name: "SPUEdit",
                    params: { id: createdProductId },
                  });
                }
              })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        })
        .catch(() => {
          ElNotification({
            title: "新增",
            message: "商品新增失敗!",
            duration: 3000,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 驗證商品表單欄位
     */
    async SpuFormValidation() {
      let refs: any = this.$refs.spuCreateFormRef;
      let isValid = false;

      await refs.validate((valid: any) => {
        isValid = valid;
      });

      return isValid;
    },
    /**
     * 更新商品 & SKU
     */
    async UpdateSpuAndSku() {
      if (!(await this.SpuFormValidation())) {
        return;
      }

      this.loading = true;

      this.spuUpdateEntity.id = this.id;
      this.spuUpdateEntity.outerSysCode = this.spuCreateEntity.outerSysCode;
      this.spuUpdateEntity.name = this.spuCreateEntity.name;
      this.spuUpdateEntity.productCategoryId =
        this.spuCreateEntity.productCategoryId;
      this.spuUpdateEntity.status = this.skuEntityList.every(
        (item: any) => item.status == "Inactive"
      )
        ? "Inactive"
        : this.spuCreateEntity.status;
      this.spuUpdateEntity.hashTags = this.spuCreateEntity.hashTags;
      this.spuUpdateEntity.description = this.spuCreateEntity.description;
      this.spuUpdateEntity.briefDescription =
        this.spuCreateEntity.briefDescription;
      this.spuUpdateEntity.imageLinks = this.GetUploadedImageLinks(
        this.uploadSpuImageList
      );

      this.spuUpdateEntity.sort = this.spuCreateEntity.sort;

      await UpdateSpu(this.spuUpdateEntity)
        .then((result) => {
          let isSkuUpdatedSuccessful = true;
          let isSkuCreatedSuccessful = true;

          if (result.status === 200) {
            let updateList = this.skuEntityList
              .filter((m) => m.id != 0)
              .map((element: SkuResponseEntity) => {
                return {
                  id: element.id,
                  productId: element.productId,
                  outerSysCode: element.outerSysCode,
                  name: element.name,
                  price: element.price,
                  status: element.status,
                  unit: element.unit,
                  spec: element.spec,
                  inventoryQty: element.inventoryQty,
                  imageLinks: element.imageLinks,
                  isAllowStockNegative: element.isAllowStockNegative,
                  qtyPerUnitUpperLimit: element.qtyPerUnitUpperLimit,
                  sort: element.sort,
                  canUsePoint: element.canUsePoint,
                  point: element.point,
                  sellPrice: element.sellPrice,
                  isAllowPointDiscount: element.isAllowPointDiscount,
                  isCustomOriginalPrice: element.isCustomOriginalPrice
                } as SkuUpdateEntity;
              });

            //// update SKU first
            if (updateList.length > 0) {
              BatchUpdateSku(updateList)
                .then(() => {})
                .catch((err) => {
                  isSkuUpdatedSuccessful = false;
                  console.error(err);
                });
            }

            let createList = this.skuEntityList
              .filter((m) => m.id == 0)
              .map((element: any) => {
                return {
                  productId: element.productId,
                  outerSysCode: element.outerSysCode,
                  name: element.name,
                  price: element.price,
                  status: element.status,
                  unit: element.unit,
                  spec: element.spec,
                  inventoryQty: element.inventoryQty,
                  imageLinks: element.imageLinks,
                  isAllowStockNegative: element.isAllowStockNegative,
                  qtyPerUnitUpperLimit: element.qtyPerUnitUpperLimit,
                  sort: element.sort,
                  canUsePoint: element.canUsePoint,
                  point: element.point,
                  sellPrice: element.sellPrice,
                  isAllowPointDiscount: element.isAllowPointDiscount,
                  isCustomOriginalPrice: element.isCustomOriginalPrice
                } as SkuCreateEntity;
              });

            //// then insert new SKUs
            if (createList.length > 0) {
              BatchCreateSku(createList)
                .then(() => {})
                .catch((err) => {
                  isSkuCreatedSuccessful = false;
                  console.error(err);
                });
            }

            if (isSkuUpdatedSuccessful && isSkuCreatedSuccessful) {
              ElNotification({
                title: "編輯商品",
                message: "商品更新成功!",
                duration: 3000,
                type: "success",
              });

              // reload data
              setTimeout(() => {
                this.LoadData();
                this.loading = false;
              }, 500);
            }
          } else {
            ElNotification({
              title: "商品更新失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          }
        })
        .catch((err) => {
          ElNotification({
            title: "商品更新失敗",
            message: err,
            duration: 3000,
            type: "error",
          });
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Check whether the SKU outercode duplicates
     */
    CheckCurrentSkuOuterSysCodeDuplication(outerSysCode: string) {
      return (
        this.skuEntityList.find(
          (m) =>
            this.currentSkuRowUuid != m.uuid && m.outerSysCode === outerSysCode
        ) !== undefined
      );
    },

    /**
     * SKU 欄位檢核
     */
    async SkuFormValidation() {
      let isValid = false;
      let refs: any = this.$refs.skuCreateFormRef;
      await refs.validate((valid: any) => {
        isValid = valid;
      });

      return isValid;
    },

    async BeforeCreateSkuInfo() {
      if (!(await this.SkuFormValidation())) {
        return;
      }

      let entity = {
        productId: this.id,
        outerSysCode: this.skuCreateEntity.outerSysCode
      } as OuterSysCodeEntity

      let isDbDuplicates = (await CheckSkuOuterSysCodeDuplication(entity)).data.data

      if (isDbDuplicates || this.CheckCurrentSkuOuterSysCodeDuplication(this.skuCreateEntity.outerSysCode)) {
        this.ShowMessageBox('SKU ERP對應編號重複，確定要新增嗎？', this.CreateSkuInfo)
      }
      else {
        this.CreateSkuInfo();
      } 
    },

    async BeforeUpdateSkuInfo() {
      if (!(await this.SkuFormValidation())) {
        return;
      }

      let entity = {
        productId: this.id,
        outerSysCode: this.skuCreateEntity.outerSysCode
      } as OuterSysCodeEntity

      let isDbDuplicates = (await CheckSkuOuterSysCodeDuplication(entity)).data.data

      if (isDbDuplicates || this.CheckCurrentSkuOuterSysCodeDuplication(this.skuCreateEntity.outerSysCode)) {
        this.ShowMessageBox('SKU ERP對應編號重複，確定要更新嗎？', this.UpdateSkuInfo)
      }
      else {
        this.UpdateSkuInfo();
      } 
    },

    /**
     * 新增 SKU 清單資訊
     */
    async CreateSkuInfo() {
      let newSku: SkuResponseEntity = {
            uuid: uuidv4(),
            id: 0,
            businessId: 0,
            imageLinks: this.GetUploadedImageLinks(this.uploadSkuImageList),
            outerSysCode: this.skuCreateEntity.outerSysCode,
            name: this.skuCreateEntity.name,
            price: this.skuCreateEntity.price,
            unit: this.skuCreateEntity.unit,
            spec: this.skuCreateEntity.spec,
            inventoryQty: this.skuCreateEntity.inventoryQty,
            status: this.skuCreateEntity.status,
            isAllowStockNegative: this.skuCreateEntity.isAllowStockNegative,
            qtyPerUnitUpperLimit: this.skuCreateEntity.qtyPerUnitUpperLimit,
            productId: this.skuCreateEntity.productId,
            sort: 0,
            canUsePoint: this.skuCreateEntity.canUsePoint,
            point: this.skuCreateEntity.point,
            sellPrice: this.skuCreateEntity.sellPrice,
            isAllowPointDiscount: this.skuCreateEntity.isAllowPointDiscount,
            isCustomOriginalPrice: this.skuCreateEntity.isCustomOriginalPrice
          };

      this.skuEntityList.push(newSku);
      this.skuFormVisible = false;
      this.ClearSkuData();
    },
    /**
     * 更新 SKU 清單資訊
     */
    async UpdateSkuInfo() {
      let existingSkuEntity = this.skuEntityList.find(
            (m) => m.uuid == this.currentSkuRowUuid
          );
      if (existingSkuEntity === undefined) {
        ElNotification({
          title: "SKU",
          message: "SKU 更新異常",
          duration: 3000,
          type: "error",
        });
        return;
      }

      existingSkuEntity.id = this.skuId;
      existingSkuEntity.outerSysCode = this.skuCreateEntity.outerSysCode;
      existingSkuEntity.name = this.skuCreateEntity.name;
      existingSkuEntity.price = this.skuCreateEntity.price;
      existingSkuEntity.sellPrice = this.skuCreateEntity.sellPrice;
      existingSkuEntity.status = this.skuCreateEntity.status;
      existingSkuEntity.unit = this.skuCreateEntity.unit;
      existingSkuEntity.spec = this.skuCreateEntity.spec;
      existingSkuEntity.inventoryQty = this.skuCreateEntity.inventoryQty;
      existingSkuEntity.isAllowStockNegative =
        this.skuCreateEntity.isAllowStockNegative;
      existingSkuEntity.qtyPerUnitUpperLimit =
        this.skuCreateEntity.qtyPerUnitUpperLimit || undefined;
      existingSkuEntity.canUsePoint = this.skuCreateEntity.canUsePoint;
      existingSkuEntity.point = this.skuCreateEntity.point;
      existingSkuEntity.imageLinks = this.GetUploadedImageLinks(
        this.uploadSkuImageList
      );
      existingSkuEntity.isAllowPointDiscount = this.skuCreateEntity.isAllowPointDiscount;
      existingSkuEntity.isCustomOriginalPrice = this.skuCreateEntity.isCustomOriginalPrice;

      this.skuFormVisible = false;
      this.ClearSkuData();
    },
    /**
     * 因 Element UI Upload 元件會將上傳結果包裹在 list 裡，故需另外截取
     */
    GetUploadedImageLinks(list: any) {
      return list.map((item: any) => {
        if (item.percentage != null && item.percentage == 100) {
          return item.response.data.fullFilePath;
        } else {
          return item.name;
        }
      });
    },
    /**
     * 清空 SKU 清單資訊
     */
    ClearSkuData() {
      this.currentSkuRowUuid = "";
      this.uploadSkuImageList = [];
      this.skuCreateEntity = {
        productId: this.id,
        outerSysCode: "",
        status: "Active",
        name: "",
        price: 0,
        unit: "",
        spec: "",
        inventoryQty: 0,
        imageLinks: [],
        isAllowStockNegative: false,
        qtyPerUnitUpperLimit: undefined,
        sort: 0,
        canUsePoint: false,
        point: 0,
        sellPrice: 0,
        isAllowPointDiscount: false,
        isCustomOriginalPrice: false
      };
    },
    /**
     * 編輯 SKU 資訊處理
     */
    HandleSkuEdit(index: any, row: any) {
      this.OpenUpdateSkuWindow();

      this.currentSkuRowUuid = row.uuid;
      this.skuId = row.id;
      this.skuCreateEntity = {
        productId: this.id,
        outerSysCode: row.outerSysCode,
        status: row.status,
        name: row.name,
        price: row.price,
        unit: row.unit,
        spec: row.spec,
        inventoryQty: row.inventoryQty,
        imageLinks: row.imageLinks,
        isAllowStockNegative: row.isAllowStockNegative,
        qtyPerUnitUpperLimit: row.qtyPerUnitUpperLimit,
        sort: 0,
        canUsePoint: row.canUsePoint,
        point: row.point,
        sellPrice: row.sellPrice,
        isAllowPointDiscount: row.isAllowPointDiscount,
        isCustomOriginalPrice: row.isCustomOriginalPrice
      };

      this.uploadSkuImageList = this.skuCreateEntity.imageLinks.map(
        (element: any) => {
          return {
            name: element,
            url: element,
          };
        }
      );

      this.originalSkuCreateEntity = JSON.parse(
        JSON.stringify(this.skuCreateEntity)
      );
      this.originalUploadSkuImageList = JSON.parse(
        JSON.stringify(this.uploadSkuImageList)
      );
    },
    /**
     * SKU 狀態異動處理
     */
    HandleSkuStatusChange(index: any, row: any) {
      this.skuCreateEntity.status = row.status;
    },
    /**
     * SKU 過濾功能處理
     */
    HandleSkuFilterChange() {
      if (this.id != undefined) {
        this.LoadSkuData(this.id);
      }
    },
    /**
     * 移除 SKU 處理
     */
    HandleSkuImageRemove(file: UploadUserFile) {
      this.uploadSkuImageList = this.uploadSkuImageList.filter(
        (m: any) => m.url !== file.url
      );
    },
    /**
     * 移除商品處理
     */
    HandleSpuImageRemove(file: UploadUserFile) {
      this.uploadSpuImageList = this.uploadSpuImageList.filter(
        (m: any) => m.url !== file.url
      );
    },
    /**
     * SKU dialog 關閉處理
     */
    HandleSkuDialogClose() {},
    /**
     * 圖片預覽
     */
    HandleImagePreview(file: UploadUserFile) {
      this.dialogImageUrl = file.url!;
      this.dialogVisible = true;
    },
    /**
     * 圖片點擊
     */
    HandleImageClick(e: any) {
      if (this.uploadSkuImageList && this.uploadSkuImageList.length >= 1) {
        // prevent file dialog
        e.preventDefault();

        ElNotification({
          title: "圖片上傳",
          message: "SKU 圖片僅限 1 張",
          duration: 3000,
          type: "error",
        });
      }
    },
    /**
     *  上傳商品圖片
     */
    UploadSpuImage(params: any) {
      const file = params.file;

      let forms = new FormData();
      forms.append("file", file);

      this.isProductImageUploading = true;
      UploadSpuImage(forms)
        .then((result) => {
          if (result.status === 200) {
            this.uploadSpuImageList.push({
              name: result.data.data.fullFilePath,
              url: result.data.data.fullFilePath,
            });
          } else {
            ElNotification({
              title: "上傳圖片",
              message: "上傳商品圖片失敗",
              duration: 3000,
              type: "error",
            });
            console.error("result:", result);
          }
        })
        .catch((err) => {
          ElNotification({
            title: "上傳圖片",
            message: "上傳商品圖片失敗",
            duration: 3000,
            type: "error",
          });
          console.error("err", err);
        })
        .finally(() => {
          this.isProductImageUploading = false;
        });
    },
    HandleSpuImageProgress() {
      this.isProductImageUploading = true;
    },
    HandleSpuImageSuccess() {
      this.isProductImageUploading = false;
    },
    HandleSkuImageProgress() {
      this.isSkuImageUploading = true;
    },
    HandleSkuImageSuccess() {
      this.isSkuImageUploading = false;
    },
    BeforeUploadFile(rawFile: any) {
      if (rawFile.type !== "image/jpeg") {
        ElMessage.error("檔案格式應為 jpg、jpeg");
        return false;
      } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error("檔案大小不得超過 2MB");
        return false;
      }
      return true;
    },
    /**
     *  上傳 SKU 圖片
     */
    UploadSkuImage(params: any) {
      const file = params.file;

      let forms = new FormData();
      forms.append("file", file);

      this.isSkuImageUploading = true;

      UploadSkuImage(forms)
        .then((result) => {
          if (result.status === 200) {
            this.uploadSkuImageList.push({
              name: result.data.data.fullFilePath,
              url: result.data.data.fullFilePath,
            });
          } else {
            ElNotification({
              title: "上傳圖片",
              message: "上傳 SKU 圖片失敗",
              duration: 3000,
              type: "error",
            });
            console.error("result:", result);
          }
        })
        .catch((err) => {
          ElNotification({
            title: "上傳圖片",
            message: "上傳 SKU 圖片失敗",
            duration: 3000,
            type: "error",
          });
          console.error("err:", err);
        })
        .finally(() => {
          this.isSkuImageUploading = false;
        });
    },
    /**
     * 拖拉事件結束後處理
     */
    HandleDragEnd(evt: any) {
      const arr = this.skuEntityList;
      const targetRow = arr.splice(evt.oldIndex, 1)[0];
      // insert target row into position of new index
      arr.splice(evt.newIndex, 0, targetRow);

      var index = 0;
      // re-order the sorting index
      arr.forEach((sku: SkuResponseEntity) => {
        sku.sort = index++;
      });
    },
    GetHeaderByRoute(params: string) {
      return GetAuthorizationHeaderByRoute(params);
    },
  },
  setup() {
    const qtyPerUnitUpperLimitValidator = (
      rule: any,
      value: any,
      callback: any
    ) => {
      if (value == undefined || value == null || value == "") {
        callback();
        return;
      }

      if (isNaN(value)) {
        callback(new Error("請輸入數字"));
        return;
      }

      if (value <= 0) {
        callback(new Error("數字應大於 0"));
        return;
      }

      callback();
    };

    return {
      qtyPerUnitUpperLimitValidator,
    };
  },
});
