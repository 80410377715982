import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import ElementPlus from 'element-plus'
import zhTw from 'element-plus/es/locale/lang/zh-tw'
import 'element-plus/dist/index.css'
import Vant from 'vant';
import { ConfigProvider } from 'vant';
import 'vant/lib/index.css';
import { Locale } from 'vant';
import vantZhTw from 'vant/es/locale/lang/zh-TW';
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import './styles/scss/element-plus-theme.scss'

Locale.use('zh-TW', vantZhTw);

const piniaStore = createPinia(); 

piniaStore.use(piniaPluginPersistedstate);

createApp(App).use(piniaStore).use(router).use(ElementPlus, {
  locale: zhTw
}).use(Vant).use(ConfigProvider).mount('#app')
