
import { defineComponent } from "vue";
import { ElNotification } from "element-plus";
import moment from "moment";
import {
  GetInternalUserDropdownList,
  SearchInternalUser,
  ExportInternalUserAsExcel,
  GetInternalUserStatusDropdownList,
} from "../../services/backstages/InternalUserService";
import {
  InternalUserSearchEntity,
  InternalUserListResponseEntity,
} from "../../models/backstages/InternalUserModels";
import {
  DropdownEntity,
  ExcelExportRequestEntity,
  ExcelImportResponseEntity,
} from "../../models/commons/CommonModels";
import {
  FailAndNeedHandle,
  GetAuthorizationHeaderByRoute,
} from "../../services/commons/CommonService";
import FileImportDialog from "../../components/commons/FileImportDialog.vue";

export default defineComponent({
  name: "OrderListView",
  components: {
    FileImportDialog
  },
  data() {
    return {
      importStep: 1,
      isImportDryRun: true,
      isOpenImportFile: false,
      importFileList: [] as any,

      isInternalUserDataLoading: false,
      isOpenFilter: false,
      isOpenMenu: false,

      internalUserSearchEntity: {
        skip: 0,
        take: 10,
        internalUserId: null!,
        internalUserName: "",
        email: "",
        status: "",
        orderCondition: {
          name: "Id",
          isAscending: false,
        },
      } as InternalUserSearchEntity,
      tableData: [] as Array<InternalUserListResponseEntity>,
      importResultData: {
        result: [],
        totalCount: 0,
        status: "",
        isDryRun: true,
      } as ExcelImportResponseEntity,
      /**
       * 下拉清單
       */
      internalUserDropdownList: [] as Array<DropdownEntity>,
      internalUserStatusDropdownList: [] as Array<DropdownEntity>,

      /**
       * Pagination vars
       */
      currentPage: 1,
      totalPage: 0,
      currentCount: 0,
      totalCount: 0,
      pageSize: 10,
      pageSizes: [10, 25, 50, 100],
    };
  },
  created() {
    this.ClearData();
    this.LoadData();
  },
  computed: {
    filterBtnText(): string {
      let text = "篩選";

      let filterCount = 0;
      if (this.internalUserSearchEntity.internalUserId != undefined) {
        filterCount++;
      }

      if (this.internalUserSearchEntity.email != "") {
        filterCount++;
      }

      if (this.internalUserSearchEntity.status != "") {
        filterCount++;
      }

      if (filterCount != 0) {
        text = text + "(" + filterCount + ")";
      }

      return text;
    },
  },
  methods: {
    ClearConditionFilterData() {
      this.internalUserSearchEntity = {
        skip: 0,
        take: 10,
        internalUserId: null!,
        internalUserName: "",
        email: "",
        status: "",
        orderCondition: {
          name: "Id",
          isAscending: false,
        },
      };
    },
    ClearImportDialog() {
      this.importStep = 1;
      this.isImportDryRun = true;
      this.importResultData = {} as any;
      this.importFileList = [] as any;
    },
    ClearData() {
      this.ClearImportDialog();
      this.ClearConditionFilterData();
    },
    LoadData() {
      this.LoadDropdownList();
      this.SearchInternalUserData();
    },
    LoadDropdownList() {
      this.LoadInternalUserDropdownList();
      this.LoadInternalUserStatusDropdownList();
    },
    LoadInternalUserDropdownList() {
      GetInternalUserDropdownList()
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "使用者清單載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            this.internalUserDropdownList = result.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    LoadInternalUserStatusDropdownList() {
      GetInternalUserStatusDropdownList()
          .then((result) => {
            if (FailAndNeedHandle(result)) {
              ElNotification({
                title: "使用者狀態清單載入失敗",
                message: result.data.errorMessage,
                duration: 3000,
                type: "error",
              });
            } else {
              this.internalUserStatusDropdownList = result.data.data;
            }
          })
          .catch((err) => {
            console.error(err);
          });
    },
    ApplyConditionFilter() {
      this.isOpenFilter = false;
      this.SearchInternalUserData();
    },
    ClearConditionFilter() {
      this.ClearConditionFilterData();
    },
    SearchInternalUserData() {
      this.isInternalUserDataLoading = true;

      this.internalUserSearchEntity.skip =
        this.pageSize * (this.currentPage - 1);
      this.internalUserSearchEntity.take = this.pageSize;

      SearchInternalUser(this.internalUserSearchEntity)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "使用者載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let paginationResult = result.data.data;
            this.tableData = paginationResult.data;
            this.currentCount = paginationResult.currentCount;
            this.totalCount = paginationResult.totalCount;
            this.totalPage = paginationResult.totalPage;
          }
        })
        .catch(() => {
          ElNotification({
            title: "使用者載入失敗",
            message: "使用者載入失敗",
            duration: 3000,
            type: "error",
          });
        })
        .finally(() => {
          this.isInternalUserDataLoading = false;
        });
    },
    OpenUrl(url: string) {
      window.open(url, "_blank");
    },
    OpenFilter() {
      this.isOpenFilter = true;
    },
    OpenMenu() {
      this.isOpenMenu = true;
    },
    DateFormatter(row: any, column: any) {
      let d = new Date();

      if (column.property == "createdDateTime") {
        if (row.createdDateTime == null) return "-";
        d = new Date(row.createdDateTime);
      } else if (column.property == "updatedDateTime") {
        if (row.updatedDateTime == null) return "-";
        d = new Date(row.updatedDateTime);
      }

      let mm = d.getMonth() + 1;
      let dd = d.getDate();
      return `${d.getFullYear()}/${(mm > 9 ? "" : "0") + mm}/${
        (dd > 9 ? "" : "0") + dd
      }`;
    },
    HandleSizeChange(val: number) {
      this.pageSize = val;
      this.SearchInternalUserData();
    },
    HandleCurrentChange(val: number) {
      this.currentPage = val;
      this.SearchInternalUserData();
    },
    EditInternalUserAction(index: any, row: any) {
      this.OpenUrl("Edit/" + row.id);
    },
    HandleSelectItemClear(elementName: any, element: any) {
      element[elementName] = undefined!;
    },
    HandleSortChange(column: any) {
      if (column.column !== null) {
        this.internalUserSearchEntity.orderCondition.name =
          this.CapitalizeFirstLetter(column.prop);
        this.internalUserSearchEntity.orderCondition.isAscending =
          column.order == "ascending";
      } else {
        this.internalUserSearchEntity.orderCondition.name = "";
        this.internalUserSearchEntity.orderCondition.isAscending = true;
      }

      this.SearchInternalUserData();
    },
    /**
     * 將英文的首字母轉換成大寫
     * @param str 要轉換的字串
     * @returns 首字母大寫
     */
    CapitalizeFirstLetter(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    /**
     * 根據 使用者 尋條件，輸出資料為 Excel
     */
    ExportInternalUserAsExcel() {
      let fileName = `使用者匯出_${moment().format(
        "yyyy_MM_DD_hh_mm_ss"
      )}.xlsx`;

      let entity: ExcelExportRequestEntity<InternalUserSearchEntity> = {
        searchCriteria: this.internalUserSearchEntity,
        fileName: fileName,
      };

      ExportInternalUserAsExcel(entity).then((result) => {
        if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "使用者匯出失敗",
            message: result.data.errorMessage,
            duration: 3000,
            type: "error",
          });
        } else {
          let data: any = result.data;
          let blob = new Blob([data]);
          var el = document.createElement("a");
          el.href = URL.createObjectURL(blob);
          el.download = fileName;
          el.click();
        }
      });
    },
    HandleImportSuccess(response: any) {
      this.importResultData = response.data;

      if (
        this.importResultData.isDryRun ||
        this.importResultData.status == "Error"
      ) {
        this.importStep = 2;
      } else {
        this.importStep = 3;
        this.LoadData();
      }
    },
    HandleImportError() {
      ElNotification({
        title: "檔案上傳失敗",
        message: "請通知系統人員",
        duration: 3000,
        type: "error",
      });
    },
    HandleBeforeImport(file: any) {
      let extensionReg = /(\.xls|\.xlsx)$/;
      if (extensionReg.test(file.name) == false) {
        ElNotification({
          title: "檔案上傳失敗",
          message: "副檔名須為 .xls 或 .xlsx",
          duration: 3000,
          type: "error",
        });

        return false;
      }
    },
    ConfirmImport() {
      this.isImportDryRun = false;
      // Set status to 'ready' so that the file can be uploaded again after triggering submit
      this.importFileList[0].status = "ready";
      (this.$refs as any).importFileRef.submit();
    },
    GetHeaderByRoute(params: string) {
      return GetAuthorizationHeaderByRoute(params);
    },
  },
});
