
import { defineComponent } from "vue";
import { ElNotification } from "element-plus";
import {
  ExcelImportResponseEntity
} from "../../models/commons/CommonModels";
import {
  GetAuthorizationHeaderByRoute,
} from "../../services/commons/CommonService";
import { UploadFilled } from "@element-plus/icons-vue";

export default defineComponent({
  name: "FileImportDialog",
  components: {
    UploadFilled
  },
  props: {
    isDialogVisible: Boolean,
    importApiUrl: String,
    dialogTitle: String
  },
  data() {
    return {
      importStep: 1,
      isImportDryRun: true,
      importFileList: [] as any,
      importResultData: {
        result: [],
        totalCount: 0,
        status: "",
        isDryRun: true,
      } as ExcelImportResponseEntity,
      importFailureRecordDisplayLimit: 100,
      loading: false,
    }
  },
  computed: {
    showDialog() {
      return this.isDialogVisible;
    },
    buttonDisabled() {
      return this.loading;
    }
  },
  methods: {
    ClearImportDialog() {
      this.importStep = 1;
      this.isImportDryRun = true;
      this.importResultData = {} as any;
      this.importFileList = [] as any;
    },
    HandleImportSuccess(response: any, file: any, fileList: any) {
      this.importFileList = fileList;
      this.loading = false;
      this.importResultData = response.data;

      if (
        this.importResultData.isDryRun ||
        this.importResultData.status == "Error"
      ) {
        this.importStep = 2;
      } else {
        this.importStep = 3;
      }
    },
    HandleImportError() {
      this.loading = false;
      ElNotification({
        title: "檔案上傳失敗",
        message: "請通知系統人員",
        duration: 3000,
        type: "error",
      });
    },
    HandleBeforeImport(file: any) {
      let extensionReg = /(\.xls|\.xlsx)$/;
      if (extensionReg.test(file.name) == false) {
        ElNotification({
          title: "檔案上傳失敗",
          message: "副檔名須為 .xls 或 .xlsx",
          duration: 3000,
          type: "error",
        });

        return false;
      }
    },
    HandleImportProgress() {
      this.loading = true;
    },
    ConfirmImport() {
      this.isImportDryRun = false;
      // Set status to 'ready' so that the file can be uploaded again after triggering submit
      this.importFileList[0].status = "ready";
      (this.$refs as any).importFileRef.submit();
    },
    GetHeaderByRoute(params: string) {
      return GetAuthorizationHeaderByRoute(params);
    },
    CloseDialog() {
      this.$emit('close');
    } 
  }
});
