
import { defineComponent } from "vue";
import LineButton from "../../components/commons/LineButton.vue";
import { ElNotification, ElMessageBox, ElLoading } from "element-plus";
import {
  ExternalUserLogin,
  ExternalUserForgetPassword,
  ExternalUserResetPassword,
} from "../../services/stores/ExternalUserService";
import {
  ExternalUserForgetPasswordEntity,
  ExternalUserResetPasswordEntity,
} from "../../models/stores/ExternalUserModels";
import { mapStores } from "pinia";
import { useStorePersistStore } from "../../store/StorePersistStore";
import { GetCartQty } from "../../services/stores/ShoppingCartService";
import { LineRedirect } from "../../services/stores/LineAuthService";
import { LoginEntity } from "../../models/commons/CommonModels";
import { FailAndNeedHandle } from "../../services/commons/CommonService";
import {
  GoogleRecaptcha,
  GoogleRecaptchaAction
} from "../../models/commons/GoogleRecaptchaModels"

export default defineComponent({
  name: "StoreLoginView",
  components: {
    LineButton
  },
  data() {
    return {
      /**
       * Loading
       * */
      forgetPasswordBtnLoading: false,
      resetPasswordBtnLoading: false,
      lineBtnDisabled: false,

      googleRecaptcha: null! as GoogleRecaptcha,

      loginEntity: {
        account: "",
        password: "",
        recaptchaToken: "",
        recaptchaAction: ""
      } as LoginEntity,
      forgetPasswordEntity: {
        account: "",
      } as ExternalUserForgetPasswordEntity,
      resetPasswordEntity: {
        account: "",
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      } as ExternalUserResetPasswordEntity,
      rememberPassword: false,
      forgetPasswordDialogFormVisible: false,
      resetPasswordDialogFormVisible: false,
      formLabelWidth: "120px",
    };
  },
  methods: {
    LoadRecaptcha() {
      this.googleRecaptcha = new GoogleRecaptcha();
      this.googleRecaptcha.LoadRecaptchaEnterprise();
    },
    async Login(): Promise<void> {
      const loading = ElLoading.service({
        lock: true,
        text: "登入中",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 取得 reCAPTCHA Token
      this.loginEntity.recaptchaToken = await this.googleRecaptcha.GetEnterpriseTokenAsync(GoogleRecaptchaAction.Login);
      this.loginEntity.recaptchaAction = GoogleRecaptchaAction.Login;

      var result = await ExternalUserLogin(this.loginEntity);

      loading.close();

      if (FailAndNeedHandle(result)) {
        ElNotification({
          title: "登入失敗",
          message: result.data.errorMessage,
          duration: 3000,
          type: "error",
        });
      } else if (result.data.data.isFirstTimeLogin) {
        // 首次登入處理
        this.resetPasswordDialogFormVisible = true;
      } else {
        const token = result.headers.authorization.replace("Bearer", "").trim();

        this.storePersistStore.setPermission(token);
        this.storePersistStore.setCurrentBranchId(
          this.storePersistStore.externalUserEntity.permission.branchSetting.defaultBranchId
        );

        const res = await GetCartQty();

        this.storePersistStore.setCartQty(res.data.data.quantity);

        this.$router.push("ProductSearch");
      }
    },
    async LineRedirect() {
      this.lineBtnDisabled = true;

      LineRedirect()
        .then((res) => {
          if (FailAndNeedHandle(res)) {
            this.lineBtnDisabled = false
            return
          }

          let redirectUrl = res.data.data
          window.location.replace(redirectUrl)
        })
        .catch((err) => { 
          this.lineBtnDisabled = false
          console.error(err)
        })
    },
    /**
     * 忘記密碼，以簡訊或 Email 寄發密碼重新設定的通知
     */
    async ForgetPassword() {
      if (
        this.forgetPasswordEntity.account === null ||
        this.forgetPasswordEntity.account === ""
      ) {
        ElMessageBox.alert("帳號不得為空", "重設密碼", {
          customClass: "login-el-message",
          confirmButtonText: "確定",
        });
        return;
      }

      this.forgetPasswordBtnLoading = true;

      // 取得 reCAPTCHA Token
      this.forgetPasswordEntity.recaptchaToken = await this.googleRecaptcha.GetEnterpriseTokenAsync(GoogleRecaptchaAction.ForgetPassword);
      this.forgetPasswordEntity.recaptchaAction = GoogleRecaptchaAction.ForgetPassword;

      ExternalUserForgetPassword(this.forgetPasswordEntity)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElMessageBox.alert(result.data.errorMessage, "忘記密碼", {
              customClass: "login-el-message",
              confirmButtonText: "確定",
            });
          } else {
            ElMessageBox.alert(
              result.data.data,
              "忘記密碼",
              {
                customClass: "login-el-message",
                confirmButtonText: "確定",
                callback: () => {
                  this.forgetPasswordDialogFormVisible = false;
                },
              });
          }
        })
        .catch(() => {
          ElMessageBox.alert("系統錯誤，請重新嘗試", "忘記密碼", {
            customClass: "login-el-message",
            confirmButtonText: "確定",
          });
        })
        .finally(() => {
          this.forgetPasswordBtnLoading = false;
        });
    },
    ResetPassword() {
      if (
        this.resetPasswordEntity.oldPassword === null ||
        this.resetPasswordEntity.oldPassword === ""
      ) {
        ElMessageBox.alert("舊密碼不得為空", "重設密碼", {
          confirmButtonText: "確定",
        });
        return;
      } else if (
        this.resetPasswordEntity.newPassword === null ||
        this.resetPasswordEntity.newPassword === ""
      ) {
        ElMessageBox.alert("新密碼不得為空", "重設密碼", {
          customClass: "login-el-message",
          confirmButtonText: "確定",
        });
        return;
      } else if (
        this.resetPasswordEntity.confirmNewPassword === null ||
        this.resetPasswordEntity.confirmNewPassword === ""
      ) {
        ElMessageBox.alert("確認密碼不得為空", "重設密碼", {
          customClass: "login-el-message",
          confirmButtonText: "確定",
        });
        return;
      }

      this.resetPasswordEntity.account = this.loginEntity.account;

      this.resetPasswordBtnLoading = true;

      ExternalUserResetPassword(this.resetPasswordEntity)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElMessageBox.alert(result.data.errorMessage, "重設密碼", {
              customClass: "login-el-message",
              confirmButtonText: "確定",
            });
          } else {
            ElMessageBox.alert("重設密碼成功! 請重新登入", "重設密碼", {
              customClass: "login-el-message",
              confirmButtonText: "確定",
              callback: () => {
                this.resetPasswordDialogFormVisible = false;
                this.loginEntity.password = "";
              },
            });
          }
        })
        .catch(() => {
          ElMessageBox.alert("系統錯誤，請重新嘗試", "忘記密碼", {
            customClass: "login-el-message",
            confirmButtonText: "確定",
          });
        })
        .finally(() => {
          this.resetPasswordBtnLoading = false;
        });
    },
  },

  computed: {
    ...mapStores(useStorePersistStore),
  },
  mounted() {
    this.LoadRecaptcha();
  }
});
