import { ApiResponseEntity } from "@/models/ApiModels";
import { DropdownEntity } from "@/models/commons/CommonModels";
import { AxiosResponse } from "axios";
import { DefinitionSearchEntity } from "@/models/commons/DefinitionModels"
import { Post } from "./commons/CommonService"

/**
 * 取得下拉選單定義資料
 * @param tableName 資料表名稱
 * @param columnName 欄位名稱
 * @returns Array<DropdownEntity>
 */
 export async function GetDefinitionDropdownListByTableAndColumnName(tableName: string, columnName: string): Promise<AxiosResponse<ApiResponseEntity<Array<DropdownEntity>>, any>> {
  const searchEntity = {
    tableName: tableName,
    columnName: columnName
  } as DefinitionSearchEntity;

  return GetDefinitionDropdownList(searchEntity);
}

/** 
 * 取得下拉選單定義資料
 * @param params DefinitionSearchEntity
 * @returns Array<DropdownEntity>
 */
export async function GetDefinitionDropdownList(entity: DefinitionSearchEntity): Promise<AxiosResponse<ApiResponseEntity<Array<DropdownEntity>>, any>> {

  const url = "/api/backstage/Definition/GetDefinitionDropdownList";

  const result = await Post<DefinitionSearchEntity, Array<DropdownEntity>>(url, entity);

  return result;
}
