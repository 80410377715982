import { 
    GetSiteKey,
    LoadRecaptchaEnterpriseScript, 
    IsRecaptchaEnterpriseLoaded,
    RequestForRecaptchaToken
  } from "../../services/commons/GoogleRecaptchaService";
import { 
    FailAndNeedHandle
} from "../../services/commons/CommonService";

/**
 * Google reCAPTCHA 類別
 */
export class GoogleRecaptcha {
    private siteKey: string = '';

    /**
     * 建構子
     */
    constructor() {}

    LoadRecaptchaEnterprise() : void {
        if (IsRecaptchaEnterpriseLoaded()) {
            return
        }

        GetSiteKey()
        .then((result) => {
            if (FailAndNeedHandle(result)) {
                console.error('載入 reCAPTCHA 元件失敗 (無法取得 Site Key)')
                return
            }
            else {
                this.siteKey = result.data.data
                LoadRecaptchaEnterpriseScript(this.siteKey);
            }
        })
        .catch(() => {
            console.error('載入 reCAPTCHA 元件失敗 (無法取得 Site Key)')
        })
    }

    async GetEnterpriseTokenAsync(action: string) : Promise<string> {
        if (!IsRecaptchaEnterpriseLoaded()) {
            this.LoadRecaptchaEnterprise();
        }
        else if (this.siteKey == '' || this.siteKey == undefined) {
            const result = await GetSiteKey();
            this.siteKey = result.data.data;
        }

        return RequestForRecaptchaToken(this.siteKey, action);
    }
}

export enum GoogleRecaptchaAction {
    ForgetPassword = 'forget_password',
    Login = 'login',
    VerificationCode = 'verification_code',
    MemberRegister = 'member_register',
    MemberBind = 'member_bind',
}