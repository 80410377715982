import { ExternalUserEntity } from "@/models/stores/ExternalUserModels";
import { defineStore } from "pinia";
import { ref } from "vue";
import jwt_decode from "jwt-decode";
import { DropdownEntity, SubMenuEntity } from "@/models/commons/CommonModels";
import { InternalBusinessSettingEntity } from "@/models/stores/InternalBusinessModels";

export const useStorePersistStore = defineStore(
  "storePersist",
  () => {
    const externalUserEntity = ref({} as ExternalUserEntity);

    const jwtToken = ref("");

    const currentBranchId = ref(0 as number);

    const branch = ref([] as Array<DropdownEntity>);

    const menu = ref([] as Array<SubMenuEntity>);

    const cartQty = ref(0 as number);

    const availablePoint = ref(0 as number);

    const internalBizId = ref(0);

    const isB2CEnabled = ref(false as boolean);

    const isLineLoginEnabled = ref(false as boolean);

    const internalBusinessSetting = ref({} as InternalBusinessSettingEntity);

    const setPermission = (token: string) => {
      jwtToken.value = token;

    const payload = jwt_decode<ExternalUserEntity>(token);
      externalUserEntity.value = payload;
    };

    const resetPermission = () => {
      externalUserEntity.value = {} as ExternalUserEntity;
      jwtToken.value = "";
    };

    const resetBranch = () => {
      branch.value = [] as Array<DropdownEntity>;
      resetCurrentBranchId();
    };

    const setBranch = (params: Array<DropdownEntity>) => {
      branch.value = params;
    };

    const resetCurrentBranchId = () => {
      currentBranchId.value = 0;
    };

    const setCurrentBranchId = (id: number) => {
      currentBranchId.value = id;
    };

    const resetMenu = () => {
      menu.value = [] as Array<SubMenuEntity>;
    };

    const resetCartQty = () => {
      cartQty.value = 0;
    };

    const setCartQty = (params: number) => {
      cartQty.value = params;
    };

    const resetAvailablePoint = () => {
      availablePoint.value = 0;
    };

    const setAvailablePoint = (params: number) => {
      availablePoint.value = params;
    };

    const setMenu = (params: Array<SubMenuEntity>) => {
      menu.value = params;
    };

    const isAllowed = (path: string) => {
      const element = menu.value.find((p) =>
        p.menuItem.find((c) => c.path.indexOf(path) > -1)
      );
      return !(element == undefined);
    };

    const isExpired = () => {
      if (
        externalUserEntity.value != null &&
        externalUserEntity.value.id != undefined &&
        new Date(externalUserEntity.value.expiredDateTime) < new Date()
      ) {
        return true;
      }

      return false;
    };

    const isLogin = () => {
      if (jwtToken.value === "") return false;
      return true;
    };

    const isShowDiscountActivity = () => {
      return externalUserEntity.value.permission?.allowShowDiscountActivities
        ?.length > 0
        ? true
        : false;
    };

    const isAllowPointOperation = () => {
      return externalUserEntity.value.permission?.moduleSetting?.isPointEnabled
          && externalUserEntity.value.permission?.isAllowPointOperation
    };

    const isShowDiscountPrice = () => {
      return externalUserEntity.value.permission?.isShowDiscountPrice;
    };

    const getMenuItemName = (id: number) => {
      const element = menu.value.find((p) =>
        p.menuItem.find(
          (c) => c.path.indexOf("/Store/ProductSearch/" + id) > -1
        )
      );

      if (element == undefined) {
        return "";
      }

      const result = element.menuItem.find(
        (p) => p.path.indexOf("/Store/ProductSearch/" + id) > -1
      )?.name;

      return result?.substring(0, 2) + "..";
    };

    const setInternalBizId = (id: number) => {
      internalBizId.value = id;
    };

    const setInternalBusinessSetting = (entity: InternalBusinessSettingEntity) => {
      internalBusinessSetting.value = entity
      isB2CEnabled.value = entity?.module?.isB2CEnabled
      isLineLoginEnabled.value = entity?.module?.isLineLoginEnabled
    }

    const isShowProductDescription = () => {
      return isLogin() || internalBusinessSetting.value.isVisitorAllowProductDescription
    }

    const isShowProductBriefDescription = () => {
      return isLogin() || internalBusinessSetting.value.isVisitorAllowProductBriefDescription
    }

    const isShowProductPrice = () => {
      return isLogin() || internalBusinessSetting.value.isVisitorAllowProductPrice
    }

    return {
      externalUserEntity,
      jwtToken,
      cartQty,
      availablePoint,
      menu,
      internalBizId,
      branch,
      currentBranchId,
      isB2CEnabled,
      isLineLoginEnabled,
      internalBusinessSetting,
      setPermission,
      resetPermission,
      resetBranch,
      resetCurrentBranchId,
      resetMenu,
      resetAvailablePoint,
      setAvailablePoint,
      resetCartQty,
      setCartQty,
      setBranch,
      setCurrentBranchId,
      setMenu,
      isAllowed,
      isExpired,
      isLogin,
      getMenuItemName,
      isShowDiscountActivity,
      isShowDiscountPrice,
      isAllowPointOperation,
      setInternalBizId,
      setInternalBusinessSetting,
      isShowProductDescription,
      isShowProductBriefDescription,
      isShowProductPrice
    };
  },
  { persist: true }
);
