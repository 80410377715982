import { ApiResponseEntity } from "@/models/ApiModels";
import { PointHistoryListEntity, PointHistorySearchEntity, PointHistoryInfoEntity } from "@/models/stores/PointHistoryModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService"

const prefix = "/api/store"

/**
 * 取得點數歷程
 * @returns 點數歷程
 */
export async function GetPointHistoryList(): Promise<AxiosResponse<ApiResponseEntity<Array<PointHistoryListEntity>>, any>> {

    const url = prefix + "/PointHistory/GetPointHistoryList";

    const result = await Post<object, Array<PointHistoryListEntity>>(url, {});

    return result;
}

/**
 * 取得點數餘額
 * @returns 點數餘額
 */
export async function GetPointBalance(): Promise<AxiosResponse<ApiResponseEntity<number>, any>> {

    const url = prefix + "/PointHistory/GetPointBalance";

    const result = await Post<object, number>(url, {});

    return result;
}

/**
 * 查詢點數歷程
 * @param entity 查詢 entity
 * @returns 點數歷程
 */
export async function SearchPointHistory(entity: PointHistorySearchEntity): Promise<AxiosResponse<ApiResponseEntity<PointHistoryInfoEntity>, any>> {
    const url = prefix + "/PointHistory/SearchPointHistory";

    const result = await Post<object, PointHistoryInfoEntity>(url, entity);

    return result;
}

/**
 * 每日點數獎勵功能是否啟用
 * @returns True: 啟用; False: 不啟用
 */
export async function IsDailyPointRewardEnabled(): Promise<AxiosResponse<ApiResponseEntity<boolean>, any>> {
    const url = prefix + "/PointHistory/IsDailyPointRewardEnabled";

    const result = await Post<object, boolean>(url, null!);

    return result;
}

/**
 * 獲得每日點數獎勵
 */
export async function ClaimDailyPointReward(): Promise<AxiosResponse<ApiResponseEntity<null>, any>> {
    const url = prefix + "/PointHistory/ClaimDailyPointReward";

    const result = await Post<object, null>(url, null!);

    return result;
}