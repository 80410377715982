import { ApiResponseEntity, PaginationEntity } from "@/models/ApiModels";
import {
  IdEntity,
  ExcelExportRequestEntity,
} from "@/models/commons/CommonModels";
import {
  OrderSearchEntity,
  OrderCreateEntity,
  OrderListResponseEntity,
  OrderSlaveResponseEntity,
  OrderExcelExportRequestEntity,
  PickingListRequestEntity,
} from "@/models/backstages/OrderModels";

import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService";
import { useBackStagePersistStore } from "@/store/BackStagePersistStore";

/**
 * 條件搜尋訂單清單
 * */
export async function SearchOrder(
  request: OrderSearchEntity
): Promise<
  AxiosResponse<
    ApiResponseEntity<PaginationEntity<OrderListResponseEntity>>,
    any
  >
> {
  const url = "/api/backstage/Order/Search";
  const result = await Post<object, PaginationEntity<OrderListResponseEntity>>(
    url,
    request
  );

  return result;
}

/**
 * 根據訂單序號取得訂單資料
 * */
export async function GetOrderById(
  id: number
): Promise<AxiosResponse<ApiResponseEntity<OrderListResponseEntity>, any>> {
  const url = "/api/backstage/Order/GetById/" + id;
  const result = await Post<object, OrderListResponseEntity>(url, null!);

  return result;
}

/**
 * 根據訂單序號取得訂單的子單列表
 * */
export async function GetOrderSlaveListByOrderId(
  orderId: number
): Promise<
  AxiosResponse<ApiResponseEntity<Array<OrderSlaveResponseEntity>>, any>
> {
  const url = "/api/backstage/Order/GetOrderSlaveList/" + orderId;
  const result = await Post<object, Array<OrderSlaveResponseEntity>>(
    url,
    null!
  );

  return result;
}

/**
 * 建立訂單
 * @param request 訂立訂單 entity
 * @returns 該訂單的序號
 */
export async function CreateOrder(
  request: OrderCreateEntity
): Promise<AxiosResponse<ApiResponseEntity<number>, any>> {
  const url = "/api/backstage/Order/Create";
  const result = await Post<object, number>(url, request);

  return result;
}

/**
 * 移除訂單
 * */
export async function DeleteOrder(
  id: number
): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const url = "/api/backstage/Order/Delete/" + id;
  const result = await Post<object, any>(url, null!);

  return result;
}

/**
 * 根據訂單查詢條件，輸出訂單資料為 Excel Stream
 * @param entity ExcelExportRequestEntity<OrderSearchEntity>
 * @returns Stream
 */
export async function ExportOrderAsExcel(
  entity: ExcelExportRequestEntity<OrderSearchEntity>
): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const store = useBackStagePersistStore();
  const token = store.jwtToken;
  const config: any = {
    withCredential: true,
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = "/api/backstage/Order/ExportOrderAsExcel";
  const result = await Post<object, any>(url, entity, config);

  return result;
}

/**
 * 根據選取的訂單序號，輸出訂單資料為 Excel Stream
 * @param entity OrderExcelExportRequestEntity
 * @returns Stream
 */
export async function ExportSelectedOrderAsExcel(
  idEntityList: Array<IdEntity>
): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const store = useBackStagePersistStore();
  const token = store.jwtToken;

  const config: any = {
    withCredential: true,
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  };

  const url = "/api/backstage/Order/ExportSelectedOrderAsExcel";
  const result = await Post<object, any>(url, idEntityList, config);

  return result;
}

/**
 * 根據訂單查詢條件，輸出出貨單
 * @param entity PickingListRequestEntity<OrderSearchEntity>
 * @returns Stream
 */
export async function ExportPickupList(
  entity: PickingListRequestEntity<OrderSearchEntity>
): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const store = useBackStagePersistStore();
  const token = store.jwtToken;
  const config: any = {
    withCredential: true,
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = "/api/backstage/Order/ExportPickingList";
  const result = await Post<object, any>(url, entity, config);

  return result;
}

/**
 * 根據訂單查詢條件，輸出出貨單
 * @param entity PickingListRequestEntity<Array<IdEntity>>
 * @returns Stream
 */
export async function ExportSelectedPickupList(
  idEntityList: PickingListRequestEntity<Array<IdEntity>>
): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const store = useBackStagePersistStore();
  const token = store.jwtToken;

  const config: any = {
    withCredential: true,
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  };

  const url = "/api/backstage/Order/ExportSelectedPickingList";
  const result = await Post<object, any>(url, idEntityList, config);

  return result;
}

/**
 * 確認出貨
 * */
export async function ConfirmShipped(
  entity: IdEntity
): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const url = "/api/backstage/Order/ConfirmShipped";
  const result = await Post<object, any>(url, entity);

  return result;
}

/**
 * 確認出貨
 * */
export async function BatchConfirmShippedSelected(
  entity: IdEntity[]
): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const url = "/api/backstage/Order/BatchConfirmShippedSelected";
  const result = await Post<object, any>(url, entity);

  return result;
}

export async function BatchConfirmShipped(
  entity: OrderSearchEntity
): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const url = "/api/backstage/Order/BatchConfirmShipped";
  const result = await Post<object, any>(url, entity);

  return result;
}
