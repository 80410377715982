import { ApiResponseEntity } from "@/models/ApiModels";
import { DropdownEntity } from "@/models/commons/CommonModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService"

/** 
 * 取得內部角色下拉選單
 * */
export async function GetInternalRoleDropdownList(): Promise<AxiosResponse<ApiResponseEntity<Array<DropdownEntity>>, any>> {

    const url = "/api/backstage/InternalRole/GetDropDownList";
    const result = await Post<object, Array<DropdownEntity>>(url, {});

    return result;
}