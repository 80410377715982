
import { defineComponent } from "vue";
import { mapState, mapStores } from "pinia";
import { ElMessageBox } from "element-plus";
import { useStorePersistStore } from "../../../store/StorePersistStore";
import { ArrowDownBold, ArrowUpBold } from "@element-plus/icons-vue";
import {
  BatchAddShoppingCart,
  DeleteShoppingCart,
  GetShoppingCart,
  UpdateItemPurchaseQuantity,
  RedeemShoppingCartItem,
  UpdateShoppingCart,
  GetGiftListByDiscountActivityId,
  SyncGift,
  GetShippingProfileList
} from "../../../services/stores/ShoppingCartService";
import { CreateOrder } from "../../../services/stores/OrderService";
import {
  ExternalUserLogout,
  GetMenuByBranch
} from "../../../services/stores/ExternalUserService";
import {
  IsShowBranch,
  GetBranchDropdownList
} from "../../../services/stores/BranchService";
import {
  AddShoppingCartEntity,
  AddShoppingCartItemEntity,
  ShoppingCartEntity,
  ShoppingCartItemEntity,
  ShoppingCartItemGroupEntity,
  ShoppingCartRedeemItemEntity,
  UpdateShoppingCartItemEntity,
  UpdateShoppingCartEntity,
  GiftInfoEntity,
  GiftSkuListEntity,
  SyncGiftEntity,
  GiftListResultEntity,
  DiscountInfoEntity
} from "../../../models/stores/ShoppingCartModels";
import { format } from "date-fns";
import { Toast } from "vant";
import { ElLoading } from "element-plus";
import {
  FuzzySearchProductListEntity,
  StoreProductFuzzySearchEntity,
} from "../../../models/stores/ProductModels";
import { GetStoreProductByFuzzySearch } from "../../../services/stores/ProductService";
import { SearchCriteriaEntity } from "../../../models/ApiModels";
import { Icon } from "@iconify/vue";
import _ from "lodash";
import { FailAndNeedHandle } from "../../../services/commons/CommonService";
import { TypeEnum } from "../../../models/stores/enums/DiscountActivityEnums";
import { IdEntity } from "@/models/commons/CommonModels";
import { GetInternalBizId } from "@/services/auth/AuthService";
import { GetAsset, GetLogo } from "../../../services/stores/AssetsHelper";
import ElCurrencyInput from "../../../components/commons/ElCurrencyInput.vue"
import TipBox from "../../../components/commons/TipBox.vue"

export default defineComponent({
  name: "StoreLayout",
  components: {
    Icon,
    ArrowDownBold,
    ArrowUpBold,
    ElCurrencyInput,
    TipBox,
  },

  data() {
    return {
      Collapse: true,
      isMenuOpen: false,
      isOpenCart: false,
      isOpenUserInfo: false,
      isOpenRedeemItem: false,

      /** Point Discount Dialog */
      isOpenPointDiscountDialog: false,
      dialogPointDiscount: {
        remainingPoint: 0,
        pointCanDiscountMaxAmount: 0,
        discountAmount: 0,
      },

      redeemItem: {} as ShoppingCartItemEntity,
      redeemItemQuantityRange: [] as number[],
      shoppingCartDetail: {} as ShoppingCartEntity,
      cartBuyerInfo: {
        cartBuyerName: '',
        cartShippingAddress: '',
        cartMemo: '',
        isSyncToUserProfile: false
      },
      shoppingCartItemCollapseArr: ["item"],
      shoppingCartPointItemCollapseArr: ["point"],
      giftItemCollapse: ["gift"],

      loading: false,
      isOpenFuzzySearch: false,

      fuzzySearchCriteria: {
        skip: 0,
        take: 20,
        condition: { branchId: 0, keyWords: "" } as StoreProductFuzzySearchEntity,
      } as SearchCriteriaEntity<StoreProductFuzzySearchEntity>,

      isShowSummaryDetail: true,
      viewMode: "list",

      isOpenQuickOrderDialog: false,
      quickOrderProductList: [] as Array<FuzzySearchProductListEntity>,

      carouselImageList: [] as Array<string>,
      isAutoPlay: 2000,

      carouselSize: true,
      isSearchMore: true,

      sortingOption: "Default",
      sortingOptionList: [
        { text: "依加入順序", value: "Default" },
        { text: "依商品頁分類", value: "GroupByProductId" },
      ],

      //Gift List
      getGiftListResult: {} as GiftListResultEntity,
      isOpenGiftListDrawer: false,

      /**
       * 分館
       */
      isShowBranch: false,

      /**
       * 配送方式
       */
      shippingProfileInfoList: [] as any,

      shoppingCartBuyerInfoRules: {
        cartBuyerName: [
          { required: true, message: '請輸入名字', trigger: 'blur' },
        ],
        cartShippingAddress: [
            { required: true, message: '請輸入地址', trigger: 'blur' },
        ],
      }
    };
  },

  methods: {
    Scrolling: _.throttle(function (this: any, scrollEvent: any) {
      if (scrollEvent.scrollTop > 0) {
        this.carouselSize = false;
        return;
      }

      const scrollbar = (this.$refs as any).elScrollbar.$el.clientHeight;
      const infinteScroll = (this.$refs as any).elInfiniteScroll.clientHeight;

      if (this.carouselSize == false && scrollbar > infinteScroll) {
        return;
      }

      this.carouselSize = true;
    }, 100),

    async Logout() {
      this.isOpenUserInfo = false;
      ExternalUserLogout().then((result) => {
        if (FailAndNeedHandle(result)) {
          Toast({
            message: "登出失敗，請重新嘗試",
            icon: "fail",
            duration: 1000,
            className: "van-toast-z-index",
          });
          throw new Error("logout fail");
        } else {
          this.storePersistStore.resetPermission();
          this.storePersistStore.resetMenu();
          this.storePersistStore.resetBranch();
          this.storePersistStore.resetCartQty();
          this.storePersistStore.resetAvailablePoint();

          Toast({
            message: "已登出",
            icon: "passed",
            duration: 500,
            className: "van-toast-z-index",
          });

          this.GoRoute("/Store/ProductSearch");
        }
      });
    },

    async MenuCollapse() {
      const loading = ElLoading.service({
        lock: true,
        text: "載入側欄",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.LoadBranchInfo();
      const res = await GetMenuByBranch(this.storePersistStore.currentBranchId);
      this.storePersistStore.setMenu(res.data.data);

      this.isMenuOpen = !this.isMenuOpen;

      loading.close();
    },

    async GetShoppingCartDetail(): Promise<void> {
      if (this.storePersistStore.isLogin() == false) {
        this.GoRoute("/Store/Login");
        return;
      }

      const loading = ElLoading.service({
        lock: true,
        text: "讀取中",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 載入運送方式清單
      this.LoadShippingProfile();

      const res = await GetShoppingCart();

      loading.close();

      if (FailAndNeedHandle(res)) {
        Toast({
          message: "取得購物車清單失敗",
          icon: "warning",
          duration: 500,
          className: "vant-toast-custom",
        });
      } else {
        this.shoppingCartDetail = res.data.data;
        this.storePersistStore.setCartQty(res.data.data.itemList.length);
        this.storePersistStore.setAvailablePoint(res.data.data.availablePoint);

        if (this.shoppingCartDetail.itemList.some((m) => m.isSystemModified)) {
          Toast({
            message: "部分品項商品已異動，請確認",
            icon: "fail",
            duration: 3000,
            className: "vant-toast-custom",
          });
        }

        // 購物車資料 "訂購人名字" 跟 "地址" 都為空，則預設打勾 ”是否更新個人資料"
        if (this.IsEmpty(this.shoppingCartDetail.orderPeople) 
          && this.IsEmpty(this.shoppingCartDetail.shippingAddress)) {
          this.cartBuyerInfo.isSyncToUserProfile = true
        }

        // 下列 檢查購物車目前的資料，若為空，則帶入後端回傳的資料做為預設值
        if (this.IsEmpty(this.cartBuyerInfo?.cartBuyerName)) {
          this.cartBuyerInfo.cartBuyerName = this.shoppingCartDetail.orderPeople
        }

        if (this.IsEmpty(this.cartBuyerInfo?.cartShippingAddress)) {
          this.cartBuyerInfo.cartShippingAddress = this.shoppingCartDetail.shippingAddress
        }

        if (this.IsEmpty(this.cartBuyerInfo?.cartMemo)) {
          this.cartBuyerInfo.cartMemo = this.shoppingCartDetail.memo;
        }

        this.isOpenCart = true
      }
    },

    IsEmpty (val: string) {
      return val == '' || val == undefined
    },

    async FormValidation (refs: any) {
      return refs.validate((valid: any) => {
        if (valid == false) {
          return valid;
        }
      });
    },
    
    async CreateOrder(): Promise<void> {
      
      if (await this.FormValidation(this.$refs.shoppingCartFormRef) == false) {
        return;
      }

      const loading = ElLoading.service({
        lock: true,
        text: "品項檢查",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const resp = await GetShoppingCart();

      if (FailAndNeedHandle(resp)) {
        loading.close();

        Toast({
          message: "品項檢查失敗",
          icon: "fail",
          duration: 3000,
          className: "vant-toast-custom",
        });
      } else if (this.CheckDiscountInfo(resp.data.data.discountInfoList) == false) {
        Toast({
          message: "部分活動已異動，請確認",
          icon: "fail",
          duration: 3000,
          className: "vant-toast-custom",
        });

        this.shoppingCartDetail = resp.data.data;
        this.storePersistStore.setCartQty(resp.data.data.itemList.length);

        loading.close();
      } else {
        this.shoppingCartDetail = resp.data.data;
        this.storePersistStore.setCartQty(resp.data.data.itemList.length);
        this.shoppingCartDetail.orderPeople = this.cartBuyerInfo.cartBuyerName;
        this.shoppingCartDetail.shippingAddress = this.cartBuyerInfo.cartShippingAddress;
        this.shoppingCartDetail.memo = this.cartBuyerInfo.cartMemo;
        this.shoppingCartDetail.isSyncToUserProfile = this.cartBuyerInfo.isSyncToUserProfile;

        if (this.shoppingCartDetail.itemList.some(m => m.isSystemModified) || this.shoppingCartDetail.itemList.length == 0) {
          Toast({
            message: "部分品項商品已異動，請確認",
            icon: "fail",
            duration: 3000,
            className: "vant-toast-custom",
          });

          loading.close();
        }
        else if (this.isDisabledSendOrder == true) {
          Toast({
            message: "部分活動贈品品可選數量已變更，請重新選擇",
            icon: "fail",
            duration: 3000,
            className: "vant-toast-custom",
          });

          loading.close();
        }
        else if (this.CheckShippingInfo() == false) {
          Toast({
            message: "請選擇運送方式",
            icon: "fail",
            duration: 3000,
            className: "vant-toast-custom",
          });

          loading.close();
        }
        else {
          loading.setText("送出訂單");

          console.log('this.shoppingCartDetail', this.shoppingCartDetail)

          var result = await CreateOrder(
            this.shoppingCartDetail as ShoppingCartEntity
          );

          loading.close();

          if (result.status == 400 && result.data.status == "Error") {
            Toast({
              message: result.data.errorMessage,
              icon: "fail",
              duration: 2000,
              className: "vant-toast-custom",
            });
          } else if (FailAndNeedHandle(result)) {
            Toast({
              message: "失敗",
              icon: "fail",
              duration: 1000,
              className: "vant-toast-custom",
            });
          } else {
            this.storePersistStore.setCartQty(result.data.data.quantity);

            Toast({
              message: "成功",
              icon: "success",
              duration: 1000,
              className: "vant-toast-custom",
            });

            this.isOpenCart = false;
            this.ResetCartSettings();
          }
        }
      }
    },

    async UpdateShoppingCart() {
      const loading = ElLoading.service({
        lock: true,
        text: "",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 折抵金額檢核
      if (
        this.shoppingCartDetail.pointDiscountAmount >= this.calculateTotalPrice
      ) {
        this.shoppingCartDetail.pointDiscountAmount = this.calculateTotalPrice;
      }

      if (this.shoppingCartDetail.pointDiscountAmount % 1 != 0) {
        Toast({
          message: '折抵金額請輸入整數',
          icon: "fail",
          duration: 2000,
          className: "vant-toast-custom",
        });
        return;
      }

      let entity = {
        pointDiscountAmount: this.shoppingCartDetail.pointDiscountAmount || 0,
        itemList: this.shoppingCartDetail.itemList,
        shippingAddress: this.shoppingCartDetail.shippingAddress || "",
        memo: this.shoppingCartDetail.memo || "",
        shippingMethod: this.shoppingCartDetail.shippingMethod || ""
      } as UpdateShoppingCartEntity;

      let res = await UpdateShoppingCart(entity);

      if (res.status != 200) {
        Toast({
          message: res.data.errorMessage,
          icon: "fail",
          duration: 2000,
          className: "vant-toast-custom",
        });
      }

      // reload
      this.GetShoppingCartDetail();

      loading.close();
    },

    async UpdateItemPurchaseQuantity(
      item: ShoppingCartItemEntity,
      index: number
    ): Promise<void> {

      //// 當元件的 Quantity 被異動為 0 以下時，需處理「移除商品」動作
      if (item.quantity <= 0) {
        ElMessageBox.confirm(
          `您要移除商品 ${item.skuName}？`,
          '購物車',
          {
            confirmButtonText: '確定',
            cancelButtonText: '取消',
            showClose: false,
            center: true,
            customClass: 'top-center-message-box'
          })
          .then(() => {
            this.DeleteShoppingCart(item, index);
          })
          .catch(() => {
            //// 取消刪除，應回歸數量 1 
            item.quantity = 1;
          })
        return;
      }

      const loading = ElLoading.service({
        lock: true,
        text: "",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let entity = {
        pointDiscountAmount: this.shoppingCartDetail.pointDiscountAmount,
        item: item,
      } as UpdateShoppingCartItemEntity;

      let res = await UpdateItemPurchaseQuantity(entity);
      if (res.status != 200) {
        Toast({
          message: res.data.errorMessage,
          icon: "fail",
          duration: 1000,
          className: "vant-toast-custom",
        });
      }

      // reload
      this.GetShoppingCartDetail();

      loading.close();
    },

    async DoFuzzySearchAndOpenDialog(): Promise<void> {
      this.quickOrderProductList = [];
      this.carouselImageList = [];
      this.fuzzySearchCriteria.skip = 0;
      this.fuzzySearchCriteria.take = 20;
      this.fuzzySearchCriteria.condition.branchId = this.storePersistStore.currentBranchId;
      this.isSearchMore = true;

      const loading = ElLoading.service({
        lock: true,
        text: "讀取中",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var res = await GetStoreProductByFuzzySearch(this.fuzzySearchCriteria);

      loading.close();

      if (res.status == 200) {
        if (res.data.data.list.length > 0) {
          this.quickOrderProductList = res.data.data.list;

          if (res.data.data.list.length == this.fuzzySearchCriteria.take) {
            this.fuzzySearchCriteria.skip =
              this.fuzzySearchCriteria.skip + this.fuzzySearchCriteria.take;
          }

          if (res.data.data.totalCount == this.quickOrderProductList.length) {
            this.isSearchMore = false;
          }

          this.SetCarouselImage(res.data.data.list);

          this.isOpenQuickOrderDialog = true;
        } else {
          Toast({
            message: "無資料",
            icon: "fail",
            duration: 500,
          });
        }
      } else {
        Toast({
          message: "查詢異常，請洽相關人員",
          icon: "fail",
          duration: 500,
        });
      }
    },

    async SearchMore(): Promise<void> {
      const loading = ElLoading.service({
        lock: true,
        text: "讀取中",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var res = await GetStoreProductByFuzzySearch(this.fuzzySearchCriteria);

      loading.close();

      if (res.status == 200) {
        const ids = new Set(this.quickOrderProductList.map((m) => m.id));

        this.quickOrderProductList = [
          ...this.quickOrderProductList,
          ...res.data.data.list.filter((m) => ids.has(m.id) == false),
        ];

        if (res.data.data.list.length == this.fuzzySearchCriteria.take) {
          this.fuzzySearchCriteria.skip =
            this.fuzzySearchCriteria.skip + this.fuzzySearchCriteria.take;
        }

        if (res.data.data.totalCount == this.quickOrderProductList.length) {
          this.isSearchMore = false;
        }

        if (res.data.data.list.length > 0) {
          this.SetCarouselImage(res.data.data.list);
        }
      } else {
        Toast({
          message: "查詢異常，請洽相關人員",
          icon: "fail",
          duration: 500,
        });
      }
    },

    SetCarouselImage(data: Array<FuzzySearchProductListEntity>): void {
      data.forEach((m) => {
        this.carouselImageList.push(m.imageUrl);

        m.list.forEach((element) => {
          if (
            element.imageUrl != undefined &&
            element.imageUrl != null &&
            element.imageUrl != ""
          ) {
            this.carouselImageList.push(element.imageUrl);
          }
        });
      });
    },

    GetTypeTagColor(type: string) {
      switch (type) {
        case TypeEnum.DiscountWhenFillPriceCondition:
          return "#FF0000";
        case TypeEnum.DiscountWhenFillQtyCondition:
          return "#F55E3C";
        case TypeEnum.FreeGiftWhenFillQtyCondition:
          return "#FF8F8F";
        case TypeEnum.NthSpecialPrice:
          return "#00A7CC";
        case TypeEnum.FreeShippingWhenFillPrice:
          return "#8FCE00";
        default:
          return "#FFFFFF";
      }
    },

    DateFormat(value: Date): string {
      if (value == undefined || value == null) {
        return "";
      }

      return format(value, "yyyy/MM/dd");
    },
    /**
     * 轉換金額，且金額為給定值 (value) 與下邊界 (lowerBound) 取最大值
     * e.g.
     * 1) value: 5, lowerBound: 0 => 則結果為 max(5, 0) => 5
     * 2) value: -10, lowerBound: 0 => 則結果為 max(-10, 0) => 0
     */
    TransMinPrice(value: number, lowerBound: number = 0) {
      return this.TransPrice(Math.max(value, lowerBound));
    },

    TransPrice(value: number) {
      if (typeof value !== "number") {
        return 0;
      }
      var formatter = new Intl.NumberFormat("zh-Hant", {
        maximumFractionDigits: 2,
      });

      return formatter.format(value);
    },

    GoRoute(url: string): void {
      window.location.href = url;
    },

    async DeleteShoppingCart(
      item: ShoppingCartItemEntity,
      index: number
    ): Promise<void> {
      const loading = ElLoading.service({
        lock: true,
        text: "刪除品項中",
        background: "rgba(0, 0, 0, 0.7)",
      });

      var result = await DeleteShoppingCart(item);

      loading.close();

      if (FailAndNeedHandle(result)) {
        Toast({
          message: "刪除失敗",
          icon: "fail",
          duration: 1000,
          className: "vant-toast-custom",
        });
      } else {
        this.shoppingCartDetail.itemList.splice(index, 1);
        this.storePersistStore.setCartQty(result.data.data.quantity);

        // reload
        this.GetShoppingCartDetail();

        loading.close();
      }
    },

    async OpenRedeemShoppingCartItemWindow(
      item: ShoppingCartItemEntity
    ): Promise<void> {
      if (this.IsUserAllowPointOperation() == false || item.canUsePoint == false) {
        return;
      }

      this.redeemItem = item;
      this.redeemItemQuantityRange = Array.from(
        { length: item.quantity },
        (_, index) => index + 1
      );
      this.isOpenRedeemItem = true;
    },

    async RedeemShoppingCartItem(value: any): Promise<void> {
      if (value > this.redeemItem.quantity) {
        Toast({
          message: "超過可兌換的數量",
          icon: "fail",
          duration: 1000,
          className: "vant-toast-custom",
        });
        return;
      }

      let param = {
        item: this.redeemItem,
        redeemQuantity: value,
      } as ShoppingCartRedeemItemEntity;

      const loading = ElLoading.service({
        lock: true,
        text: "兌換點數商品",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const result = await RedeemShoppingCartItem(param);

      loading.close();

      if (result.status > 200) {
        Toast({
          message: result.data.errorMessage,
          icon: "fail",
          duration: 1000,
          className: "vant-toast-custom",
        });
      } else {
        // reload
        this.GetShoppingCartDetail();
      }

      this.isOpenRedeemItem = false;
    },

    QuickOrderDrawerCloseEvent(): void {
      this.carouselImageList = [];
      this.quickOrderProductList = [];
      this.carouselSize = true;
      this.isAutoPlay = 2000;
    },

    CalculateNumberUpperLimit(
      isAllowStockNegative: boolean,
      qtyPerUnitUpperLimit: number | undefined,
      quantity: number
    ): number {
      if (isAllowStockNegative == true && !!qtyPerUnitUpperLimit) {
        return qtyPerUnitUpperLimit;
      }

      if (isAllowStockNegative == false && !qtyPerUnitUpperLimit) {
        return quantity > 0 ? quantity : 0;
      }

      if (isAllowStockNegative == false && !!qtyPerUnitUpperLimit) {
        return qtyPerUnitUpperLimit >= quantity
          ? quantity
          : qtyPerUnitUpperLimit;
      }

      return Infinity;
    },

    CarouselSwipe(url: string) {
      if (url != undefined && url != null && url != "") {
        const index = this.carouselImageList.indexOf(url);

        if (index > -1) {
          (this.$refs as any).carousel.swipeTo(index);
          this.isAutoPlay = 0;
        }
      }
    },

    async AddCart() {
      const param = this.quickOrderProductList
        .filter((m) => m.list.find((m) => m.purchaseQty > 0) != null)
        .map((m) => {
          return {
            productId: m.id,
            itemList: m.list
              .filter((n) => n.purchaseQty > 0)
              .map((n) => {
                return {
                  stockKeepingUnitId: n.id,
                  quantity: n.purchaseQty,
                } as AddShoppingCartItemEntity;
              }),
          } as AddShoppingCartEntity;
        });

      const loading = ElLoading.service({
        lock: true,
        text: "加至購物車",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const result = await BatchAddShoppingCart(param);

      loading.close();
      this.storePersistStore.setCartQty(result.data.data.quantity);

      this.isOpenQuickOrderDialog = false;
    },

    async ResetCartSettings() {
      this.dialogPointDiscount.discountAmount = 0
      this.cartBuyerInfo = {
        cartBuyerName: '',
        cartShippingAddress: '',
        cartMemo: '',
        isSyncToUserProfile: false
      }
    },

    /**
     * 打開點數折抵現金 Dialog
     */
    OpenPointDiscountDialog() {
      if (this.storePersistStore.isAllowPointOperation() == false) {
        Toast({
          message: "未啟用點數模組",
          icon: "fail",
          duration: 2000,
          className: "vant-toast-custom",
        });
        return
      }

      this.isOpenPointDiscountDialog = true;
      this.dialogPointDiscount.remainingPoint = this.storePersistStore.availablePoint - this.cartPointItemUsedPoint;

      // 點數可折抵之現金：
      // (A) 點數折抵現金最大值 = 點數折抵現金前所剩餘點數 / 點數兌換比例
      // (B) 點數折抵現金前之總價 = 總價 - 活動折扣金額
      // => 取 MIN(A, B)
      this.dialogPointDiscount.pointCanDiscountMaxAmount =
        Math.min(
          Math.floor(this.dialogPointDiscount.remainingPoint / this.shoppingCartDetail.pointExchangeRate),
          this.allowPointDiscountTotalActivityDiscount
        );
    },
    /**
     * 處理點數折抵現金 Dialog 確認事件
     */
    async PointDiscountDialogConfirm() {

      // validate
      if (this.dialogPointDiscount.discountAmount < 0
        || this.dialogPointDiscount.discountAmount % 1 != 0) {
        Toast({
          message: "折抵金額請輸入 >= 0 的整數",
          icon: "fail",
          duration: 2000,
          className: "vant-toast-custom",
        });
        return;
      }
      else if (this.dialogPointDiscount.discountAmount > this.dialogPointDiscount.pointCanDiscountMaxAmount) {
        Toast({
          message: `折抵金額超過可折抵上限 $${this.dialogPointDiscount.pointCanDiscountMaxAmount}`,
          icon: "fail",
          duration: 2000,
          className: "vant-toast-custom",
        });
        return;
      }

      const loading = ElLoading.service({
        lock: true,
        text: "",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let entity = {
        pointDiscountAmount: this.dialogPointDiscount.discountAmount,
        itemList: this.shoppingCartDetail.itemList,
        shippingAddress: this.shoppingCartDetail.shippingAddress || "",
        memo: this.shoppingCartDetail.memo || "",
        shippingMethod: this.shoppingCartDetail.shippingMethod || ""
      } as UpdateShoppingCartEntity;

      let res = await UpdateShoppingCart(entity);

      if (res.status != 200) {
        Toast({
          message: res.data.errorMessage,
          icon: "fail",
          duration: 1000,
          className: "vant-toast-custom",
        });
      } else {
        // reload
        this.isOpenPointDiscountDialog = false;
        this.GetShoppingCartDetail();
      }

      loading.close();
    },
    /**
     * 處理點數折抵現金 Dialog 取消事件
     */
    async PointDiscountDialogCancel() {
      this.isOpenPointDiscountDialog = false;
      this.dialogPointDiscount.discountAmount = 0;
    },

    GetGiftCount(id: number): number {
      if (this.shoppingCartDetail.giftList == null) return 0;

      const result = this.shoppingCartDetail.giftList.filter(m => m.discountActivityId == id)
        .map(m => m.quantity)
        .reduce((a, b) => a + b, 0);

      return result;
    },

    async OpenGiftListDrawer(id: number) {
      var res = await GetGiftListByDiscountActivityId({ id: id } as IdEntity);

      if (FailAndNeedHandle(res)) {
        Toast({
          message: `取得贈品清單失敗`,
          icon: "fail",
          duration: 2000,
          className: "vant-toast-custom",
        });
        return;
      }
      else {
        this.getGiftListResult = res.data.data;

        for (let i = 0; i < this.getGiftListResult.list.length; i++) {
          for (let j = 0; j < this.getGiftListResult.list[i].list.length; j++) {
            if (this.getGiftListResult.list[i].list[j].imageUrl != null && this.getGiftListResult.list[i].list[j].imageUrl != '') {
              this.carouselImageList.push(this.getGiftListResult.list[i].list[j].imageUrl);
            }
          }
        }

        this.isOpenGiftListDrawer = true;
      }
    },

    GiftListDrawerCloseEvent() {
      this.getGiftListResult = {} as GiftListResultEntity
      this.isOpenGiftListDrawer = false;
      this.carouselImageList = [];
    },

    ClearSelectedGift() {
      this.getGiftListResult.list.forEach((m) => {
        m.list.forEach((n) => {
          n.qty = 0;
        })
      })
    },

    async SyncGiftAndCloseDrawer() {
      const id = this.getGiftListResult.list[0].list[0].discountActivityId;

      const list = [] as Array<GiftSkuListEntity>;

      this.getGiftListResult.list.forEach(m => {
        m.list.forEach(n => {
          if (n.qty > 0) {
            list.push(n);
          }
        })
      })

      const req = { id: id, list: list } as SyncGiftEntity;
      const res = await SyncGift(req);

      this.isOpenGiftListDrawer = false;

      if (FailAndNeedHandle(res)) {
        Toast({
          message: "同步贈品失敗",
          icon: "fail",
          duration: 1000,
          className: "vant-toast-custom",
        });
      } else {
        this.shoppingCartDetail = (await GetShoppingCart()).data.data;
      }
    },

    GiftMaxSelectdCount(qty: number): number {
      const limit = this.giftUpperLimit;
      const currentCount = this.selectedGiftCount;

      return limit - currentCount < 1 ? qty : limit - currentCount + qty;
    },

    GetDiscountActivityGiftSummaryCSS(item: DiscountInfoEntity): string {
      let defaultCss = "summary-price-item-info-right select-options";
      const value = (item.miscinfo as any).giftQtyUpperLimit - this.GetGiftCount(item.id);

      if (value == 0) {
        return defaultCss + " just-make-font-color";
      }
      else if (value > 0) {
        return defaultCss + " undervalue-make-font-color";
      }

      return defaultCss + " exceed-font-color";
    },

    CheckDiscountInfo(data: Array<DiscountInfoEntity>): boolean {

      if (data.length != this.shoppingCartDetail.discountInfoList.length) {
        return false;
      }

      for (let i = 0; i < data.length; i++) {
        if (this.shoppingCartDetail.discountInfoList.some(m => m.id == data[i].id) == false) {
          return false;
        }
      }

      return true;
    },

    CheckShippingInfo() {
      if (this.shippingProfileInfoList != null
        && this.shippingProfileInfoList.length > 0
        && this.shoppingCartDetail.shippingMethod == null) {
        return false
      }

      return true;
    },
    /**
     * 回傳當前使用者是否有權限操作點數功能
     */
    IsUserAllowPointOperation(): boolean {
      return this.storePersistStore.isAllowPointOperation();
    },
    IsShowProductPrice(): boolean {
      return this.storePersistStore.isShowProductPrice();
    },
    IsBoss(): boolean {
      return this.storePersistStore.externalUserEntity.roleType == 'Boss'
    },
    DoGetAsset(path: string): string {
      return GetAsset(path);
    },

    DoGetLogo(): string {
      return GetLogo();
    },

    /**
     * 取得分館下拉清單
     */
    async LoadBranchDropdownList() {
      let result = await GetBranchDropdownList();

      if (FailAndNeedHandle(result)) {
        console.error(result.data.errorMessage);
        Toast({
          message: result.data.errorMessage,
          icon: "fail",
          duration: 2000,
          className: "vant-toast-custom",
        });
      }
      else {
        this.storePersistStore.setBranch(result.data.data)
      }
    },
    /**
     * 載入分館資訊
     */
    async LoadBranchInfo() {
      var isShowBranchResult = await IsShowBranch();

      if (!FailAndNeedHandle(isShowBranchResult) && isShowBranchResult.data.data) {
        this.isShowBranch = true;

        let dropdownListResult = await GetBranchDropdownList();

        if (FailAndNeedHandle(dropdownListResult)) {
          console.error(dropdownListResult.data.errorMessage);
          Toast({
            message: dropdownListResult.data.errorMessage,
            icon: "fail",
            duration: 2000,
            className: "vant-toast-custom",
          });
        }
        else {
          this.storePersistStore.setBranch(dropdownListResult.data.data)
        }
      }
    },
    /**
     * 載入配送方式
     */
    async LoadShippingProfile() {
      GetShippingProfileList()
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            Toast({
              message: "載入配送資訊失敗",
              icon: "fail",
              duration: 2000,
              className: "vant-toast-custom",
            });
          } else {
            this.shippingProfileInfoList = result.data.data.map((m) => ({ label: m.displayName, value: m.shippingMethod}));
          }
        })
        .catch(() => {
          Toast({
            message: "載入配送資訊失敗",
            icon: "fail",
            duration: 2000,
            className: "vant-toast-custom",
          });
        });
    },

    OpenUserInfo() {
      if (this.storePersistStore.isLogin() == false) {
        this.GoRoute("/Store/Login");
        return;
      }

      this.isOpenUserInfo = true
    },

    GoFavoriteSearch() {
      if (this.storePersistStore.isLogin() == false) {
        this.GoRoute("/Store/Login");
        return;
      }

      this.GoRoute('/Store/ProductSearch?favorite=true');
    },

    GoToOrderSearchView() {
      if (this.storePersistStore.isLogin() == false) {
        this.GoRoute("/Store/Login");
        return;
      }

      this.GoRoute('/Store/OrderSearch');
    }
  },

  computed: {
    ...mapStores(useStorePersistStore),

    ...mapState(useStorePersistStore, {
      Menu: (store: any) => {
        return store.menu;
      },
      Branch: (store: any) => {
        return store.branch;
      },

      CartQty: (store: any) => {
        return store.isLogin() == false ? 0 : store.cartQty;
      },
      DefaultOpenedsIndexArray: (store: any) =>
        store.isLogin() == false ? [] : [...Array(store.menu.length).keys()],
    }),

    shoppingCartCollapseArr(): number[] {
      if (this.cartCashItemGroupList == null) {
        return [];
      }

      let arr = this.cartCashItemGroupList.map((x: any) => {
        return x.productId;
      });

      return arr;
    },

    calculateTotalPrice(): number {
      let result = 0;

      if (this.shoppingCartDetail == null || this.shoppingCartDetail.itemList == null) {
        return result;
      }

      for (let item of this.shoppingCartDetail.itemList.filter(
        (m) => m.isUsingPoint == false
      )) {
        result = result + item.price * item.quantity;
      }

      return result;
    },
    /**
     * 扣掉活動折扣的總金額
     */
    allowPointDiscountTotalActivityDiscount(): number {
      let result = 0;

      if (this.shoppingCartDetail == null || this.shoppingCartDetail.discountInfoList == null) {
        return result;
      }

      for (let item of this.shoppingCartDetail.itemList.filter(m => m.isAllowPointDiscount)) {

        result = result + (item.price * item.quantity - item.activityTotalDiscountPrice);
      }

      return result;
    },

    calculateTotalActivityDiscount(): number {
      let result = 0;

      if (this.shoppingCartDetail == null || this.shoppingCartDetail.discountInfoList == null) {
        return result;
      }

      const excludeTypeList = [TypeEnum.FreeShippingWhenFillPrice.toString()];

      for (let item of this.shoppingCartDetail.discountInfoList.filter(m => excludeTypeList.includes(m.type) == false)) {
        if (item.detailList.length == 0) {
          continue;
        }

        result = result + item.detailList.map((m) => m.discountPrice).reduce((a, b) => a + b);
      }

      return result;
    },

    discountTotalPriceWithShippingFee(): number {
      return this.shoppingCartDetail.isShippingFeeFree ? this.discountedTotalPrice : this.discountedTotalPrice + this.shoppingCartDetail.shippingFee
    },

    discountedTotalPrice(): number {
      return this.calculateTotalPrice -
        this.calculateTotalActivityDiscount -
        this.shoppingCartDetail.pointDiscountAmount <
        0
        ? 0
        : this.calculateTotalPrice -
        this.calculateTotalActivityDiscount -
        this.shoppingCartDetail.pointDiscountAmount
    },
    /**
     * 折扣後的運費
     */
    discountedShippingFee(): number {
      let result = 0;

      if (this.shoppingCartDetail.discountInfoList == undefined) {
        return result;
      }

      let freeShippingDiscounts = this.shoppingCartDetail.discountInfoList.filter(m => m.type == TypeEnum.FreeShippingWhenFillPrice.toString());

      for (let freeShippingDiscount of freeShippingDiscounts) {
        result = result + freeShippingDiscount.detailList.map((m) => m.discountPrice).reduce((a, b) => a + b);
      }

      return Math.max(0, this.shoppingCartDetail.shippingFee - result);
    },
    /**
     * 剩餘點數
     * = 目前點數餘額 - 折抵現金所使用點數 - 兌換商品所使用點數
     */
    calculateRemainingPoint(): number {
      if (this.shoppingCartDetail == null) {
        return 0;
      }

      let sum = this.shoppingCartDetail.availablePoint;

      // 折抵現金所使用點數
      if (this.shoppingCartDetail.pointDiscountAmount > 0) {
        sum =
          sum -
          this.shoppingCartDetail.pointDiscountAmount *
          this.shoppingCartDetail.pointExchangeRate;
      }

      // 兌換商品所使用點數
      if (this.cartPointItemList.length > 0) {
        for (let pointItem of this.cartPointItemList) {
          sum = sum - pointItem.point * pointItem.quantity;
        }
      }

      return sum;
    },
    /**
     * 剩餘點數可使用的現金折抵額
     * = 剩餘點數/點數匯率 (並無條件捨去)
     */
    calculatePointExchangeRate(): number {
      if (
        this.calculateRemainingPoint == null ||
        this.shoppingCartDetail == null
      ) {
        return 0;
      }

      return Math.floor(
        this.calculateRemainingPoint / this.shoppingCartDetail.pointExchangeRate
      );
    },
    /**
     * 訂單計算後將會獲得的點數
     */
    rewardPoint(): number {
      return Math.ceil(
        this.discountedTotalPrice *
        this.shoppingCartDetail.priceConvertPointPercentage *
        0.01
      );
    },

    isDisabledAddCart(): boolean {
      const anySelected = this.quickOrderProductList.find(
        (m) => m.list.find((m) => m.purchaseQty > 0) != undefined
      );

      return anySelected == undefined ? true : false;
    },

    isShoppingCartEmpty(): boolean {
      return (
        this.shoppingCartDetail == undefined ||
        this.shoppingCartDetail == null ||
        this.shoppingCartDetail.itemList == null ||
        this.shoppingCartDetail.itemList.length == 0
      );
    },

    cartPointItemList(): ShoppingCartItemEntity[] {
      if (this.shoppingCartDetail.itemList == null) {
        return [];
      }

      let result = _.chain(this.shoppingCartDetail.itemList)
        .filter("isUsingPoint")
        .orderBy("productId")
        .value();

      return result;
    },

    cartNonPointItemList(): ShoppingCartItemEntity[] {
      if (this.shoppingCartDetail.itemList == null) {
        return [];
      }

      let result = _.chain(this.shoppingCartDetail.itemList)
        .filter(["isUsingPoint", false])
        .orderBy("productId")
        .value();

      return result;
    },

    cartPointItemUsedPoint(): number {
      if (this.cartPointItemList == null) {
        return 0;
      }

      let sum = 0;
      for (let item of this.cartPointItemList) {
        sum = sum + item.point * item.quantity;
      }

      return sum;
    },

    cartCashItemGroupList(): ShoppingCartItemGroupEntity[] {
      if (this.shoppingCartDetail.itemList == null) {
        return [];
      }

      var result = _.chain(this.shoppingCartDetail.itemList)
        .filter(["isUsingPoint", false])
        .orderBy("productId")
        .groupBy("productId")
        .map((x) => {
          return {
            productName: x[0].productName,
            productId: x[0].productId,
            itemList: x,
          } as ShoppingCartItemGroupEntity;
        })
        .value();

      return result;
    },

    giftUpperLimit(): number {
      if (this.shoppingCartDetail.discountInfoList == undefined) {
        return 0;
      }

      const info = this.shoppingCartDetail.discountInfoList.find(m => m.id == this.getGiftListResult.discountActivityId);
      if (info == undefined || info.miscinfo == null) {
        return 0;
      }

      return (info.miscinfo as any).giftQtyUpperLimit;
    },

    selectedGiftCount(): number {
      if (this.getGiftListResult == undefined || this.getGiftListResult.list == null) {
        return 0;
      }

      let result = 0;
      this.getGiftListResult.list.forEach(m => {
        m.list.forEach(m => {
          result = result + m.qty;
        })
      });

      return result;
    },

    isDisabledSyncGift(): boolean {
      const upperLimit = this.giftUpperLimit;
      const currentSelecedCount = this.selectedGiftCount;

      return currentSelecedCount > upperLimit ? true : false;
    },

    giftSummaryClass(): string {
      const diff = this.giftUpperLimit - this.selectedGiftCount;
      return diff == 0 ? "just-make-font-color" : diff > 0 ? "" : "exceed-font-color";
    },

    isDisabledSendOrder(): boolean {
      if (this.shoppingCartDetail == undefined || this.shoppingCartDetail.discountInfoList == undefined) {
        return false;
      }

      return this.giftCountValidation ? false : true;
    },

    giftCountValidation(): boolean {
      if (this.shoppingCartDetail.discountInfoList == undefined) {
        return false;
      }
      const activityCount = this.shoppingCartDetail.discountInfoList.length;

      for (let i = 0; i < activityCount; i++) {
        const activity = this.shoppingCartDetail.discountInfoList[i];

        if (activity.type == TypeEnum.FreeGiftWhenFillQtyCondition.toString()) {

          const selectedGiftCount = this.shoppingCartDetail.giftList
            .filter(m => m.discountActivityId == activity.id)
            .map(m => m.quantity)
            .reduce((a, b) => a + b, 0);

          if (selectedGiftCount != (activity.miscinfo as any).giftQtyUpperLimit) {
            return false;
          }
        }
      }

      return true;
    },

    sendOrderText(): string {
      return this.giftCountValidation ? "送出訂單" : "送出訂單(請選擇贈品)";
    },

    giftGroupList(): GiftInfoEntity[] {
      if (this.shoppingCartDetail.giftList == null) {
        return [];
      }

      var result = _.chain(this.shoppingCartDetail.giftList)
        .orderBy("stockKeepingUnitId")
        .groupBy("stockKeepingUnitId")
        .map((x) => {
          return {
            unit: x[0].unit,
            stockKeepingUnitId: x[0].stockKeepingUnitId,
            productId: x[0].productId,
            imageUrl: x[0].imageUrl,
            productName: x[0].productName,
            skuName: x[0].skuName,
            skuCode: x[0].skuCode,
            price: x[0].price,
            quantity: x.map(m => m.quantity).reduce((a, b) => a + b, 0),
            discountActivityId: x[0].discountActivityId
          } as GiftInfoEntity;
        })
        .value();

      return result;
    },

    shippingFeeFreeActivityFulfilled(): boolean {
      if (this.shoppingCartDetail.discountInfoList == null || this.shoppingCartDetail.discountInfoList.length == 0) {
        return false
      }

      return this.shoppingCartDetail.discountInfoList.some(x => x.type == TypeEnum.FreeShippingWhenFillPrice)
    }
  },

  async created() {
    const res = await GetInternalBizId();

    if (FailAndNeedHandle(res)) {
      return;
    }

    this.storePersistStore.setInternalBizId(res.data.data.id);
    this.storePersistStore.setInternalBusinessSetting(res.data.data.setting)

    var bodyStyles = document.body.style;

    for (var i = 0; i < res.data.data.themeList.length; i++) {
      bodyStyles.setProperty(res.data.data.themeList[i].key, res.data.data.themeList[i].value);
    }
  },

  async mounted() {
  }
});
