
import { defineComponent } from "vue";
import { ElMessageBox, ElNotification } from "element-plus";
import {
  DropdownEntity,
  ExcelExportRequestEntity,
  IdEntity,
  OrderByEntity,
  UpdateStatusEntity,
} from "../../models/commons/CommonModels";
import {
  Delete,
  ExportDiscountActivityAsExcel,
  Search,
  UpdateStatus,
} from "../../services/backstages/DiscountActivityService";
import {
  DiscountActivitySearchEntity,
  DiscountActivityListEntity,
} from "../../models/backstages/DiscountActivityModels";
import { SearchCriteriaEntity } from "../../models/ApiModels";
import { FailAndNeedHandle } from "../../services/commons/CommonService";
import { DefinitionSearchEntity } from "../../models/commons/DefinitionModels";
import { GetDefinitionDropdownList } from "../../services/DefinitionService";
import moment from "moment";

export default defineComponent({
  name: "DiscountActivityListView",
  data() {
    return {
      isOrderDataLoading: false,
      isSpuStatusChanged: false,
      isOpenFilter: false,
      searchEntity: {} as DiscountActivitySearchEntity,
      tableData: [] as Array<DiscountActivityListEntity>,

      /**
       * 下拉清單
       */
      sourceTypeList: [] as Array<DropdownEntity>,
      typeList: [] as Array<DropdownEntity>,
      statusList: [
        { 
          label: '啟用',
          value: 'Active'
        },
        { 
          label: '停用',
          value: 'Inactive'
        },
      ] as Array<DropdownEntity>,

      /**
       * Pagination vars
       */
      currentPage: 1,
      totalPage: 0,
      currentCount: 0,
      totalCount: 0,
      pageSize: 10,
      pageSizes: [10, 25, 50, 100],
    };
  },
  async created() {
    await this.InitData();
    await this.LoadData();
  },
  computed: {
    filterBtnText(): string {
      let text = "篩選";
      let filterCount = 0;
      if (this.searchEntity.code != "") {
        filterCount++;
      }

      if (this.searchEntity.sourceType != "") {
        filterCount++;
      }

      if (this.searchEntity.type != "") {
        filterCount++;
      }

      if (this.searchEntity.name != "") {
        filterCount++;
      }

      if (this.searchEntity.status != "") {
        filterCount++;
      }

      if (filterCount != 0) {
        text = text + "(" + filterCount + ")";
      }
      return text;
    },
  },
  methods: {
    ClearConditionFilterData() {
      this.searchEntity = {
        code: "",
        name: "",
        sourceType: "",
        type: "",
        status: "",
        orderBy: { isAscending: true, name: "" } as OrderByEntity,
      } as DiscountActivitySearchEntity;
    },
    ClearData() {
      this.ClearConditionFilterData();
    },
    async InitData() {
      this.ClearConditionFilterData();
    },
    async LoadData() {
      await this.LoadDropdownList();

      this.DoSearch();
    },
    ApplyConditionFilter() {
      this.isOpenFilter = false;
      this.DoSearch();
    },
    ClearConditionFilter() {
      this.ClearConditionFilterData();
    },
    DoSearch() {
      this.isOrderDataLoading = true;

      const req = {
        skip: this.pageSize * (this.currentPage - 1),
        take: this.pageSize,
        condition: this.searchEntity,
      } as SearchCriteriaEntity<DiscountActivitySearchEntity>;

      Search(req)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "訂單載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let paginationResult = result.data.data;
            this.tableData = paginationResult.data;

            this.currentCount = paginationResult.currentCount;
            this.totalCount = paginationResult.totalCount;
            this.totalPage = paginationResult.totalPage;
          }
        })
        .catch(() => {
          ElNotification({
            title: "載入失敗",
            message: "優惠設定載入失敗",
            duration: 3000,
            type: "error",
          });
        })
        .finally(() => {
          this.isOrderDataLoading = false;
        });
    },
    OpenUrl(url: string) {
      window.open(url, "_blank");
    },
    OpenFilter() {
      this.isOpenFilter = true;
    },
    HandleSizeChange(val: number) {
      this.pageSize = val;
      this.DoSearch();
    },
    HandleCurrentChange(val: number) {
      this.currentPage = val;
      this.DoSearch();
    },
    EditAction(index: any, row: any) {
      const link = this.$router.resolve({
        path: "/BackStage/DiscountActivity/Edit/" + row.id,
      });
      window.open(link.href);
    },

    HandleSortChange(column: any) {
      if (column.column !== null) {
        this.searchEntity.orderBy.name = this.CapitalizeFirstLetter(
          column.prop
        );
        this.searchEntity.orderBy.isAscending = column.order == "ascending";
      } else {
        this.searchEntity.orderBy.name = "";
        this.searchEntity.orderBy.isAscending = true;
      }

      this.DoSearch();
    },

    /**
     * 將英文的首字母轉換成大寫
     * @param str 要轉換的字串
     * @returns 首字母大寫
     */
    CapitalizeFirstLetter(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    async LoadDropdownList() {
      Promise.all([
        GetDefinitionDropdownList({
          tableName: "discount_activity",
          columnName: "source_type",
        } as DefinitionSearchEntity).then((res) => {
          if (res.status == 200) {
            this.sourceTypeList = res.data.data;
          }
        }),
        GetDefinitionDropdownList({
          tableName: "discount_activity",
          columnName: "type",
        } as DefinitionSearchEntity).then((res) => {
          if (res.status == 200) {
            this.typeList = res.data.data;
          }
        }),
      ]);
    },

    GetDefinitionDesc(value: string, list: Array<DropdownEntity>) {
      const item = list.find((m) => m.value == value);

      return item == undefined ? "" : item.label;
    },

    HandleStatusChange(index: any, row: any) {
      let entity: UpdateStatusEntity = {
        id: row.id,
        status: row.status,
      };

      UpdateStatus(entity)
        .then((result) => {
          if (result.status === 200) {
            ElNotification({
              title: "更新折扣",
              message: "更新折扣狀態成功",
              duration: 3000,
              type: "success",
            });
          } else {
            ElNotification({
              title: "更新折扣",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });

            this.DoSearch();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    DateFormatter(row: any, column: any) {
      let d = new Date();

      if (column.property == "createdDateTime") {
        d = new Date(row.createdDateTime);
      } else {
        d = new Date(row.updatedDateTime);
      }

      let mm = d.getMonth() + 1;
      let dd = d.getDate();
      let hh = d.getHours();
      let m = d.getMinutes();
      return `${d.getFullYear()}/${(mm > 9 ? "" : "0") + mm}/${
        (dd > 9 ? "" : "0") + dd
      } ${(hh > 9 ? "" : "0") + hh}:${(m > 9 ? "" : "0") + m}`;
    },

    DeleteAction(index: any, row: any) {
      let id = row.id;

      ElMessageBox.confirm(`是否要刪除折扣  ${row.code}？`, "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          Delete({ id: id } as IdEntity)
            .then(() => {
              ElNotification({
                title: `刪除折扣`,
                message: `折扣 ${row.code} 刪除成功`,
                duration: 3000,
                type: "success",
              });

              this.DoSearch();
            })
            .catch(() => {
              ElNotification({
                title: `刪除折扣`,
                message: `折扣 ${row.code} 刪除失敗，請確認！`,
                duration: 3000,
                type: "error",
              });
            });
        })
        .catch(() => {});
    },
    ExportDiscountActivityData() {
      let fileName = `折扣活動匯出_${moment().format("yyyy_MM_DD_hh_mm_ss")}.xlsx`;

      let entity: ExcelExportRequestEntity<SearchCriteriaEntity<DiscountActivitySearchEntity>> = {
        searchCriteria: {
          skip: this.pageSize * (this.currentPage - 1),
          take: this.pageSize,
          condition: this.searchEntity,
        },
        fileName: fileName,
      };

      ExportDiscountActivityAsExcel(entity).then((result) => {
        if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "折扣活動匯出失敗",
            message: result.data.errorMessage,
            duration: 3000,
            type: "error",
          });
        } else {
          let data: any = result.data;
          let blob = new Blob([data]);
          var el = document.createElement("a");
          el.href = URL.createObjectURL(blob);
          el.download = fileName;
          el.click();
        }
      });
    }
  },
});
