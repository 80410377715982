import { ApiResponseEntity } from "@/models/ApiModels";
import {
    SubMenuEntity
} from "@/models/commons/CommonModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService"

/** 
 * 條件搜尋客戶清單
 * */
export async function GetMenu(): Promise<AxiosResponse<ApiResponseEntity<Array<SubMenuEntity>>, any>> {
    const url = "/api/backstage/View/GetMenu";
    const result = await Post<object, Array<SubMenuEntity>>(url, {});

    return result;
}