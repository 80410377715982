import { InternalUserEntity } from "@/models/backstages/InternalUserModels";
import { ref } from "vue";
import { defineStore } from "pinia";
import jwt_decode from "jwt-decode";
import { SubMenuEntity } from "@/models/commons/CommonModels";
import { NotificationEntity } from "@/models/backstages/NotificationModels";

export const useBackStagePersistStore = defineStore(
  "backStagePersist",
  () => {
    const internalUserEntity = ref({} as InternalUserEntity);

    const menuList = ref([] as Array<SubMenuEntity>);

    const jwtToken = ref("");

    const notifyList = ref([] as Array<NotificationEntity>);

    const internalBizId = ref(0);

    const setPermission = (token: string) => {
      jwtToken.value = token;

      const payload = jwt_decode<InternalUserEntity>(token);
      internalUserEntity.value = payload;
    };

    const setMenu = (params: Array<SubMenuEntity>) => {
      menuList.value = params;
    };

    const resetPermission = () => {
      internalUserEntity.value = {} as InternalUserEntity;
      jwtToken.value = "";
      notifyList.value = [];
    };

    const isAllowed = (moduleName: string) => {
      const element = internalUserEntity.value.permission.modules.find(
        (p) => p === moduleName
      );

      return !(element == undefined);
    };

    const isExpired = () => {
      if (jwtToken.value === "") {
        return false;
      }

      return false;
    };

    const isLogin = () => {
      if (
        internalUserEntity.value == null ||
        internalUserEntity.value.id == undefined
      ) {
        return false;
      }

      return true;
    };

    const menu = () => {
      return menuList.value;
    };

    const getMenuItemName = (id: number) => {
      const element = menuList.value.find((p) =>
        p.menuItem.find((c) => c.id == id)
      );

      if (element == undefined) {
        return "";
      }

      return element?.name;
    };

    const setInternalBizId = (id: number) => {
      internalBizId.value = id;
    };

    const setNotifyList = (list: Array<NotificationEntity>) => {
      notifyList.value = list;
    };

    const setMsgRead = () => {
      notifyList.value.forEach((m) => (m.isRead = true));
    };

    const countUnreadMsg = () => {
      return notifyList.value.filter((m) => m.isRead == false).length;
    };

    return {
      internalUserEntity,
      jwtToken,
      menuList,
      internalBizId,
      notifyList,
      setMenu,
      setPermission,
      isAllowed,
      isExpired,
      isLogin,
      menu,
      getMenuItemName,
      resetPermission,
      setInternalBizId,
      setNotifyList,
      countUnreadMsg,
      setMsgRead,
    };
  },
  { persist: true }
);
