import {
  ApiResponseEntity,
  PaginationEntity,
  SearchCriteriaEntity,
  SearchResultEntity,
} from "@/models/ApiModels";
import {
  DiscountActivityCreateEntity,
  DiscountActivityDetailEntity,
  DiscountActivityListEntity,
  DiscountActivitySearchEntity,
  FuzzySearchEntity,
  GenericListEntity,
} from "@/models/backstages/DiscountActivityModels";
import { ExcelExportRequestEntity, IdEntity, UpdateStatusEntity } from "@/models/commons/CommonModels";
import { useBackStagePersistStore } from "@/store/BackStagePersistStore";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService";

export async function Create(
  params: DiscountActivityCreateEntity
): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {
  const url = "/api/backstage/DiscountActivity/Create";
  const result = await Post<DiscountActivityCreateEntity, object>(url, params);

  return result;
}

export async function GetById(
  params: IdEntity
): Promise<
  AxiosResponse<ApiResponseEntity<DiscountActivityDetailEntity>, any>
> {
  const url = "/api/backstage/DiscountActivity/GetById";
  const result = await Post<IdEntity, DiscountActivityDetailEntity>(
    url,
    params
  );

  return result;
}

export async function Update(
  params: DiscountActivityDetailEntity
): Promise<AxiosResponse<ApiResponseEntity<IdEntity>, any>> {
  const url = "/api/backstage/DiscountActivity/Update";
  const result = await Post<DiscountActivityDetailEntity, IdEntity>(
    url,
    params
  );

  return result;
}

export async function GetTargetList(
  params: SearchCriteriaEntity<FuzzySearchEntity>
): Promise<
  AxiosResponse<ApiResponseEntity<SearchResultEntity<GenericListEntity>>, any>
> {
  const url = "/api/backstage/DiscountActivity/GetTargetList";
  const result = await Post<
    SearchCriteriaEntity<FuzzySearchEntity>,
    SearchResultEntity<GenericListEntity>
  >(url, params);

  return result;
}

export async function GetCandidateList(
  params: SearchCriteriaEntity<FuzzySearchEntity>
): Promise<
  AxiosResponse<ApiResponseEntity<SearchResultEntity<GenericListEntity>>, any>
> {
  const url = "/api/backstage/DiscountActivity/GetCandidateList";
  const result = await Post<
    SearchCriteriaEntity<FuzzySearchEntity>,
    SearchResultEntity<GenericListEntity>
  >(url, params);

  return result;
}

export async function Search(
  params: SearchCriteriaEntity<DiscountActivitySearchEntity>
): Promise<
  AxiosResponse<
    ApiResponseEntity<PaginationEntity<DiscountActivityListEntity>>,
    any
  >
> {
  const url = "/api/backstage/DiscountActivity/Search";
  const result = await Post<
    SearchCriteriaEntity<DiscountActivitySearchEntity>,
    PaginationEntity<DiscountActivityListEntity>
  >(url, params);

  return result;
}

export async function UpdateStatus(
  params: UpdateStatusEntity
): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {
  const url = "/api/backstage/DiscountActivity/UpdateStatus";
  const result = await Post<UpdateStatusEntity, object>(url, params);

  return result;
}

export async function Delete(
  params: IdEntity
): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {
  const url = "/api/backstage/DiscountActivity/Delete";
  const result = await Post<IdEntity, object>(url, params);

  return result;
}

export async function Clone(
  params: IdEntity
): Promise<
  AxiosResponse<ApiResponseEntity<DiscountActivityDetailEntity>, any>
> {
  const url = "/api/backstage/DiscountActivity/Clone";
  const result = await Post<IdEntity, DiscountActivityDetailEntity>(
    url,
    params
  );

  return result;
}

export async function ExportDiscountActivityAsExcel(
  params: ExcelExportRequestEntity<SearchCriteriaEntity<DiscountActivitySearchEntity>>
): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const url = "/api/backstage/DiscountActivity/ExportDiscountActivityAsExcel";

  const store = useBackStagePersistStore();
  const token = store.jwtToken;
  const config: any = {
    withCredential: true,
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  };

  const result = await Post<ExcelExportRequestEntity<SearchCriteriaEntity<DiscountActivitySearchEntity>>, any>(
    url, 
    params,
    config);

  return result;
}
