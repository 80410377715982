
import Editor from '@tinymce/tinymce-vue';
import { 
  UploadEditorImage
} from "../../services/backstages/SpuService";
import { FailAndNeedHandle } from "../../services/commons/CommonService";

export default {
  name: "TextEditor",
  components: {
    Editor
  },
  data() {
    return {
      editorApiKey: 'a4fepiqpdpir1402x78mcgyvblw1qi5923d8pc0tdcnvqg28',
    }
  },
  methods: {
    async UploadImage(blobInfo: any) : Promise<string> {
      let formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());

      let result = await UploadEditorImage(formData)

      if (FailAndNeedHandle(result)) {
        console.error(`Status Code: ${result.status}, ${result.data.errorCode}, ${result.data.errorMessage}`)
        return Promise.reject(
          {
            message: '請洽系統管理員',
            remove: true
          })
      }
      
      return Promise.resolve(result.data.data.fullFilePath)
    }
  }
};
