<template>
  <el-input 
    class="el-currency-input"
    ref="inputRef" 
    :modelValue="formattedValue" 
    :disabled="disabled"/>
</template>

<style scoped>
  .el-currency-input >>> input {
    text-align: v-bind(textAlign);
  }
</style>

<script>
import { useCurrencyInput } from "vue-currency-input";
import { watch } from "vue";

export default {
  name: "ElCurrencyInput",
  props: {
    modelValue: Number,
    options: Object,
    /**
     * left => 置左
     * right => 置右
     * center => 置中
     */
    textAlign: String,
  },
  setup(props) {
    const { inputRef, formattedValue, setValue } = useCurrencyInput(props.options);
    
    watch(
      () => props.modelValue,
      (value) => {
        setValue(value);
      }
    );

    return { inputRef, formattedValue };
  },
};
</script>
