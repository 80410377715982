import { ApiResponseEntity } from "@/models/ApiModels";
import { LineAuthCallbackResponseEntity } from "@/models/stores/LineAuthModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService"

const prefix = "/api/store"

export async function LineRedirect(): Promise<AxiosResponse<ApiResponseEntity<string>, any>> {

  const url = prefix + "/LineAuth/Redirect";

  const result = await Post<any, string>(url, null!);

  return result;
}

export async function LineCallback(authToken: string, state :string): Promise<AxiosResponse<ApiResponseEntity<LineAuthCallbackResponseEntity>, any>> {

  const url = prefix + "/LineAuth/Callback";

  const result = await Post<any, LineAuthCallbackResponseEntity>(url, { authToken: authToken, state: state });

  return result;
}