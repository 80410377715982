import { useStorePersistStore } from "../../store/StorePersistStore";

export function GetAsset(path: string): string {
  const dir = require.context("../../assets");

  const store = useStorePersistStore();

  return dir("./" + store.internalBizId + path);
}

export function GetLogo(): string {
  const dir = require.context("../../assets");

  const store = useStorePersistStore();

  const relativePath = "./".concat(
    store.internalBizId.toString(),
    "/store/",
    store.currentBranchId.toString(),
    "/store_logo.svg"
  );

  return dir(relativePath);
}

export function GetStyle(): string {
  const store = useStorePersistStore();
  return "../../assets/" + store.internalBizId.toString() + "/styles/theme.css";
}
