import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor")!

  return (_openBlock(), _createBlock(_component_Editor, {
    style: {"width":"100%"},
    ref: "tinymce",
    apiKey: $data.editorApiKey,
    init: { 
      height: 500,
      menubar: 'edit insert view format table tools',
      images_upload_handler: $options.UploadImage,
      plugins: 'lists link preview image table code wordcount quickbars',
      toolbar: 'undo redo | code | fontfamily fontsize forecolor backcolor bold italic | link quickimage | lineheight alignleft aligncenter alignright alignjustify outdent indent | bullist numlist',
      line_height_formats: '0.2 0.4 0.6 0.8 1 1.2 1.4 1.6 1.8 2',
      quickbars_insert_toolbar: false,
      language: 'zh_TW',
    }
  }, null, 8, ["apiKey", "init"]))
}