
import { defineComponent } from "vue";
import { debounce } from "vue-debounce";
import { ElNotification, ElMessageBox } from "element-plus";
import moment from "moment";
import { DefinitionSearchEntity } from "../../models/commons/DefinitionModels";
import { GetInternalUserDropdownList } from "../../services/backstages/InternalUserService";
import {
  ExternalBusinessSearchEntity,
  ExternalBusinessListResponseEntity,
} from "../../models/backstages/ExternalBusinessModel";
import {
  ExternalUserSearchEntity
} from "../../models/backstages/ExternalUserModels";
import { GetDefinitionDropdownList } from "../../services/DefinitionService";
import {
  SearchExternalBusiness,
  DeleteExternalBusiness,
  ExportExtBusinessAsExcel,
  GetExternalBusinessDropdownList
} from "../../services/backstages/ExternalBusinessService";
import {
  ExportExtUserAsExcel,
} from "../../services/backstages/ExternalUserService";
import {
  ExcelExportRequestEntity,
  ExcelImportResponseEntity,
} from "../../models/commons/CommonModels";
import {
  FailAndNeedHandle,
  GetAuthorizationHeaderByRoute,
} from "../../services/commons/CommonService";
import FileImportDialog from "../../components/commons/FileImportDialog.vue";

export default defineComponent({
  name: "ExternalBusinessListView",
  components: {
    FileImportDialog
  },
  data() {
    return {
      importStep: 1,
      isImportDryRun: true,
      isOpenImportFile: false,
      isOpenImportExtUserFile: false,
      importFileList: [] as any,
      
      isExternalBusinessDataLoading: false,
      isSpuStatusChanged: false,
      isOpenFilter: false,
      isOpenMenu: false,
      externalBusinessSearchEntity: {
        take: 10,
        skip: 0,
        externalBusinessId: null!,
        externalBusinessName: "",
        internalUserId: null!,
        internalUserName: "",
        outerSysCode: "",
        orderCondition: {
          name: "Id",
          isAscending: false,
        },
      } as ExternalBusinessSearchEntity,
      tableData: [] as Array<ExternalBusinessListResponseEntity>,
      importResultData: {
        result: [],
        totalCount: 0,
        status: "",
        isDryRun: true,
      } as ExcelImportResponseEntity,
      /**
       * 下拉清單
       */
      externalBusinessDropdownList: [] as any,
      orderStatusDropdownList: [] as any,
      internalUserDropdownList: [] as any,

      /**
       * Pagination vars
       */
      currentPage: 1,
      totalPage: 0,
      currentCount: 0,
      totalCount: 0,
      pageSize: 10,
      pageSizes: [10, 25, 50, 100],
    };
  },
  created() {
    this.ClearData();
    this.LoadData();
  },
  computed: {
    filterBtnText(): string {
      let text = "篩選";

      let filterCount = 0;
      if (this.externalBusinessSearchEntity.externalBusinessName != "") {
        filterCount++;
      }

      if (this.externalBusinessSearchEntity.internalUserId != undefined) {
        filterCount++;
      }

      if (this.externalBusinessSearchEntity.outerSysCode != "") {
        filterCount++;
      }

      if (filterCount != 0) {
        text = text + "(" + filterCount + ")";
      }

      return text;
    },
  },
  methods: {
    ClearConditionFilterData() {
      this.externalBusinessSearchEntity = {
        take: 10,
        skip: 0,
        externalBusinessId: null!,
        externalBusinessName: "",
        internalUserId: null!,
        internalUserName: "",
        outerSysCode: "",
        orderCondition: {
          name: "Id",
          isAscending: false,
        },
      };
    },
    ClearImportDialog() {
      this.importStep = 1;
      this.isImportDryRun = true;
      this.importResultData = {} as any;
      this.importFileList = [] as any;
    },
    ClearData() {
      this.ClearImportDialog();
      this.ClearConditionFilterData();
    },
    LoadData() {
      this.LoadDropdownList();
      this.SearchExternalBusinessData();
    },
    LoadDropdownList() {
      let searchEntity: DefinitionSearchEntity = {
        tableName: "orders",
        columnName: "status",
      };

      GetDefinitionDropdownList(searchEntity)
        .then((result) => {
          this.orderStatusDropdownList = result.data.data;
        })
        .catch((err) => {
          ElNotification({
            title: "客戶狀態清單載入失敗",
            message: err,
            duration: 3000,
            type: "error",
          });
        });

      this.LoadExternalBusinessDropdownList("");
      this.LoadInternalUserDropdownList();
    },
    LoadExternalBusinessDropdownList(query: string) {
      debounce(() => {
        GetExternalBusinessDropdownList(query)
          .then((result) => {
            if (FailAndNeedHandle(result)) {
              ElNotification({
                title: "客戶清單載入失敗",
                message: result.data.errorMessage,
                duration: 3000,
                type: "error",
              });
            } else {
              this.externalBusinessDropdownList = result.data.data;
            }
          })
          .catch(() => {
            console.error("Load LoadExternalBusinessDropdownList error");
          });
      }, "400ms")();
    },
    LoadInternalUserDropdownList() {
      GetInternalUserDropdownList()
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "負責業務清單載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            this.internalUserDropdownList = result.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    ApplyConditionFilter() {
      this.isOpenFilter = false;
      this.SearchExternalBusinessData();
    },
    ClearConditionFilter() {
      this.ClearConditionFilterData();
    },
    SearchExternalBusinessData() {
      this.isExternalBusinessDataLoading = true;

      this.externalBusinessSearchEntity.skip =
        this.pageSize * (this.currentPage - 1);
      this.externalBusinessSearchEntity.take = this.pageSize;

      SearchExternalBusiness(this.externalBusinessSearchEntity)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "客戶載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let paginationResult = result.data.data;
            this.tableData = paginationResult.data;
            this.currentCount = paginationResult.currentCount;
            this.totalCount = paginationResult.totalCount;
            this.totalPage = paginationResult.totalPage;
          }
        })
        .catch(() => {
          ElNotification({
            title: "客戶載入失敗",
            message: "客戶載入失敗",
            duration: 3000,
            type: "error",
          });
        })
        .finally(() => {
          this.isExternalBusinessDataLoading = false;
        });
    },
    OpenUrl(url: string) {
      window.open(url, "_blank");
    },
    OpenFilter() {
      this.isOpenFilter = true;
    },
    OpenMenu() {
      this.isOpenMenu = true;
    },
    DateFormatter(row: any, column: any) {
      let d = new Date();

      if (column.property == "createdDateTime") {
        if (row.createdDateTime == null) return "-";
        d = new Date(row.createdDateTime);
      } else if (column.property == "updatedDateTime") {
        if (row.updatedDateTime == null) return "-";
        d = new Date(row.updatedDateTime);
      }

      let mm = d.getMonth() + 1;
      let dd = d.getDate();
      return `${d.getFullYear()}/${(mm > 9 ? "" : "0") + mm}/${
        (dd > 9 ? "" : "0") + dd
      }`;
    },
    HandleSizeChange(val: number) {
      this.pageSize = val;
      this.SearchExternalBusinessData();
    },
    HandleCurrentChange(val: number) {
      this.currentPage = val;
      this.SearchExternalBusinessData();
    },
    EditExternalBusinessAction(index: any, row: any) {
      this.OpenUrl("Edit/" + row.id);
    },
    DeleteExternalBusinessAction(index: any, row: any) {
      let id = row.id;

      ElMessageBox.confirm(`是否要刪除客戶 id: ${id}？`, "Warning", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeleteExternalBusiness(id)
            .then(() => {
              ElNotification({
                title: `刪除客戶`,
                message: `客戶 Id:${id} 刪除成功`,
                duration: 3000,
                type: "success",
              });

              this.LoadData();
            })
            .catch(() => {
              ElNotification({
                title: `刪除客戶`,
                message: `客戶 Id:${id} 刪除失敗，請確認！`,
                duration: 3000,
                type: "error",
              });
            });
        })
        .catch(() => {});
    },
    HandleSelectItemClear(elementName: any, element: any) {
      element[elementName] = undefined!;
    },
    HandleSortChange(column: any) {
      if (column.column !== null) {
        this.externalBusinessSearchEntity.orderCondition.name =
          this.CapitalizeFirstLetter(column.prop);
        this.externalBusinessSearchEntity.orderCondition.isAscending =
          column.order == "ascending";
      } else {
        this.externalBusinessSearchEntity.orderCondition.name = "";
        this.externalBusinessSearchEntity.orderCondition.isAscending = true;
      }

      this.SearchExternalBusinessData();
    },
    /**
     * 將英文的首字母轉換成大寫
     * @param str 要轉換的字串
     * @returns 首字母大寫
     */
    CapitalizeFirstLetter(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    /**
     * 根據搜尋條件，輸出客戶資料為 Excel
     */
    ExportExtBusinessAsExcel() {
      let fileName = `客戶匯出_${moment().format("yyyy_MM_DD_hh_mm_ss")}.xlsx`;
      let entity: ExcelExportRequestEntity<ExternalBusinessSearchEntity> = {
        searchCriteria: this.externalBusinessSearchEntity,
        fileName: fileName,
      };

      ExportExtBusinessAsExcel(entity).then((result) => {
        if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "客戶匯出失敗",
            message: result.data.errorMessage,
            duration: 3000,
            type: "error",
          });
        } else {
          let data: any = result.data;
          let blob = new Blob([data]);
          var el = document.createElement("a");
          el.href = URL.createObjectURL(blob);
          el.download = fileName;
          el.click();
        }
      });
    },
    HandleImportSuccess(response: any) {
      this.importResultData = response.data;

      if (
        this.importResultData.isDryRun ||
        this.importResultData.status == "Error"
      ) {
        this.importStep = 2;
      } else {
        this.importStep = 3;
        this.LoadData();
      }
    },
    HandleImportError() {
      ElNotification({
        title: "檔案上傳失敗",
        message: "請通知系統人員",
        duration: 3000,
        type: "error",
      });
    },
    HandleBeforeImport(file: any) {
      let extensionReg = /(\.xls|\.xlsx)$/;
      if (extensionReg.test(file.name) == false) {
        ElNotification({
          title: "檔案上傳失敗",
          message: "副檔名須為 .xls 或 .xlsx",
          duration: 3000,
          type: "error",
        });

        return false;
      }
    },
    ConfirmImport() {
      this.isImportDryRun = false;
      // Set status to 'ready' so that the file can be uploaded again after triggering submit
      this.importFileList[0].status = "ready";
      (this.$refs as any).importFileRef.submit();
    },
    /**
     * 根據搜尋條件，輸出客戶資料為 Excel
     */
     ExportExtUserAsExcel() {
      let fileName = `客戶帳號匯出_${moment().format("yyyy_MM_DD_hh_mm_ss")}.xlsx`;
      let entity: ExcelExportRequestEntity<ExternalUserSearchEntity> = {
        searchCriteria: {
          externalBusinessName: this.externalBusinessSearchEntity.externalBusinessName,
          internalUserId: this.externalBusinessSearchEntity.internalUserId,
          outerSysCode: this.externalBusinessSearchEntity.outerSysCode,
          orderCondition: this.externalBusinessSearchEntity.orderCondition
        } as ExternalUserSearchEntity,
        fileName: fileName
      };
      /**
       * 根據搜尋條件，輸出使用者帳號為 Excel
       */
      ExportExtUserAsExcel(entity).then((result) => {
        if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "客戶帳號匯出失敗",
            message: result.data.errorMessage,
            duration: 3000,
            type: "error",
          });
        } else {
          let data: any = result.data;
          let blob = new Blob([data]);
          var el = document.createElement("a");
          el.href = URL.createObjectURL(blob);
          el.download = fileName;
          el.click();
        }
      });
    },
    GetHeaderByRoute(params: string) {
      return GetAuthorizationHeaderByRoute(params);
    },
    OpenFileImport() {
      this.isOpenImportExtUserFile = true;
    }
  },
});
