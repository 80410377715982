import { GenericKVPair } from "../CommonModels";

export enum IssueTypeEnum {
    Store = 0,
    BackStage = 1,
    Admin = 2
}

export enum ExceptionCodeEnum {
    NotFound = "NotFound",
    UnAuthorized = "UnAuthorized",
    Forbidden = "Forbidden",
    ClaimsChange = "ClaimsChange",
    InValidData = "InValidData",
    InternalServerError = "InternalServerError",
    UploadFailed = "UploadFailed",
    ExportFailed = "ExportFailed",
    DataDuplicates = "DataDuplicates",
    InvalidOperation = "InvalidOperation",
}

export const BooleanEnum: GenericKVPair[] =
    [{ key: true, value: "是" },
    { key: false, value: "否" }]