
import { defineComponent } from "vue";
import { debounce } from "vue-debounce";
import { ElNotification, ElMessageBox } from "element-plus";
import { Picture as IconPicture } from "@element-plus/icons-vue";
import ElCurrencyInput from "../../components/commons/ElCurrencyInput.vue";
import { v4 as uuidv4 } from "uuid";
import {
  OrderCreateEntity,
  OrderSlaveResponseEntity,
  OrderSlaveCreateEntity,
  OrderListResponseEntity,
} from "../../models/backstages/OrderModels";
import { DefinitionSearchEntity } from "../../models/commons/DefinitionModels";
import {
  CreateOrder,
  GetOrderById,
  GetOrderSlaveListByOrderId,
  ConfirmShipped
} from "../../services/backstages/OrderService";
import {
  GetSkuById,
  GetSkuDropdownList,
} from "../../services/backstages/SkuService";
import {
  GetExternalBusinessById,
  GetExternalBusinessDropdownList,
} from "../../services/backstages/ExternalBusinessService";
import { GetInternalUserDropdownList } from "../../services/backstages/InternalUserService";
import { GetExternalUserDropdownList } from "../../services/backstages/ExternalUserService";
import { GetDefinitionDropdownList } from "../../services/DefinitionService";
import { FailAndNeedHandle } from "../../services/commons/CommonService";
import { IdEntity } from "@/models/commons/CommonModels";
import { TransformNumber } from "../../commons/FormatHelper";

export default defineComponent({
  name: "OrderInfoView",
  components: {
    ElCurrencyInput,
    IconPicture,
  },
  props: ["mode"],
  data() {
    return {
      title: (this.mode == "Create" ? "新增" : "編輯") + "訂單",

      loadings: {
        order: false,
        orderSlaves: false,
        createOrder: false,
        createOrderSlave: false,
        externalBusinessDropdownList: false,
        externalUserDropdownList: false,
        internalUserDropdownList: false,
      },

      uploadSpuImageList: [] as any,
      fullSpuImageList: [] as any,

      uploadSkuImageList: [] as any,
      fullSkuImageList: [] as any,

      labelPosition: "right",
      id: +this.$route.params.id || null!,

      /**
       * Order
       */
      currentOrderSlaveRowUuid: "",
      currentOrderSlaveRowIndex: 0,
      orderCreateEntity: {} as OrderCreateEntity,
      orderSlaveMode: "Create",
      orderSlaveId: 0,
      orderSlaveCreateEntity: {} as OrderSlaveCreateEntity,
      orderSlaveList: [] as OrderSlaveResponseEntity[],
      orderSlaveCreateList: [] as OrderSlaveCreateEntity[],
      orderSlaveFormVisible: false,

      /**
       * 商品分類
       */
      productCategoryDropdownList: [] as any,

      /**
       * 圖片預覽
       */
      dialogImageUrl: "",
      dialogVisible: false,

      /**
       * 下拉清單
       */
      externalBusinessDropdownList: [] as any,
      orderStatusDropdownList: [] as any,
      skuDropdownList: [] as any,
      internalUserDropdownList: [] as any,
      externalUserDropdownList: [] as any,

      /**
       * Validation rules
       */
      orderValidationRules: {
        status: [
          { required: true, message: "請選擇訂單狀態", trigger: "change" },
        ],
        externalBusinessId: [
          { required: true, message: "請選擇客戶", trigger: "change" },
        ],
        orderDateTime: [
          {
            type: "date",
            required: true,
            message: "請選擇日期",
            trigger: "change",
          },
        ],
        externalUserId: [
          { required: true, message: "請選擇訂購人", trigger: "change" },
        ],
        internalUserId: [
          { required: true, message: "請選擇業務", trigger: "change" },
        ],
        shippingAddress: [
          { required: true, message: "請輸入出貨地址", trigger: "blur" },
        ],
      },
      amountValidationRules: {
        allowance: [
          { required: true, message: "請輸入税前折讓金額", trigger: "blur" },
          {
            type: "number",
            message: "請輸入數字",
            trigger: "blur",
            transform: TransformNumber,
          },
        ],
        tax: [
          { required: true, message: "請輸入營業稅額(%)", trigger: "blur" },
          {
            type: "number",
            min: 0,
            message: "數字應大於 0",
            trigger: "blur",
            transform: TransformNumber,
          },
        ],
        adjustment: [
          { required: true, message: "請輸入稅後調整金額", trigger: "blur" },
          {
            type: "number",
            message: "請輸入數字",
            trigger: "blur",
            transform: TransformNumber,
          },
        ],
      },
      orderSlaveValidationRules: {
        // skuId: [
        //   { type: 'number', required: true, message: '請選擇SKU', trigger: 'change', transform: TransformNumber },
        // ],
        salePrice: [{ required: true, message: "請輸入數字", trigger: "blur" }],
        qty: [{ required: true, message: "請輸入數字", trigger: "blur" }],
      },
    };
  },
  created() {
    this.ClearData();
    this.LoadData();
  },
  computed: {
    allLoading() {
      return !Object.values(this.loadings).every((m: boolean) => m == false);
    },
    calcSubTotalAmount() {
      let subTotalAmount = 0;
      this.orderSlaveCreateList.forEach((element) => {
        subTotalAmount += element.salePrice * element.qty;
      });
      return subTotalAmount;
    },
    calcTotalAmount() {
      let totalAmount = this.calcSubTotalAmount + this.orderCreateEntity.totalDiscount;

      return Math.round(totalAmount);
    },
    saveBtnLoading() {
      return this.loadings.createOrder || this.loadings.createOrderSlave;
    },
    isCreateMode() {
      return this.mode == "Create";
    },
    isCreateOrderSlaveMode() {
      return this.orderSlaveMode == "Create";
    },
    orderSlaveDialogTitle() {
      return this.orderSlaveMode ? "新增項目" : "編輯項目";
    },
    orderSlaveDialogButtonText() {
      return this.orderSlaveMode ? "新增" : "更新";
    },
    isAllowShipped() {
      const allowStatus = ['Create', 'WaitForShipping'];

      return allowStatus.indexOf(this.orderCreateEntity.status) < 0 ? true : false;
    }

  },
  methods: {
    ClearData() {
      this.ClearOrderData();
      this.ClearOrderSlaveData();
    },
    LoadData() {
      this.LoadDropdownList();

      switch (this.mode) {
        case "Create":
          break;
        case "Edit":
          this.LoadOrderData(this.id);
          this.LoadOrderSlaveList(this.id);
          break;
        default:
          console.error("Unknown mode!");
      }
    },
    LoadDropdownList() {
      let searchEntity: DefinitionSearchEntity = {
        tableName: "orders",
        columnName: "status",
      };

      GetDefinitionDropdownList(searchEntity)
        .then((result) => {
          this.orderStatusDropdownList = result.data.data;
        })
        .catch((err) => {
          ElNotification({
            title: "訂單狀態清單載入失敗",
            message: err,
            duration: 3000,
            type: "error",
          });
        });

      this.LoadExternalBusinessDropdownList("");
      this.LoadInternalUserDropdownList();
    },
    async LoadOrderData(orderId: number) {
      this.loadings.order = true;
      await GetOrderById(orderId)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "商品載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let order: OrderListResponseEntity = result.data.data;

            this.orderCreateEntity.code = order.code;
            this.orderCreateEntity.externalBusinessId =
              order.externalBusinessId;
            this.orderCreateEntity.externalBusinessName =
              order.externalBusinessName;
            this.orderCreateEntity.externalBusinessPhoneNo =
              order.externalBusinessPhone;
            this.orderCreateEntity.externalUserId = order.externalUserId;
            this.orderCreateEntity.externalUserName = order.externalUserName;
            this.orderCreateEntity.internalUserId = order.salesPersonUserId;
            this.orderCreateEntity.internalUserName = order.salesPersonUserName;
            this.orderCreateEntity.shippingAddress = order.shippingAddress;
            this.orderCreateEntity.status = order.status;
            this.orderCreateEntity.orderDateTime = order.orderDateTime;
            this.orderCreateEntity.finishDateTime = order.finishDateTime;
            this.orderCreateEntity.note = order.note;
            this.orderCreateEntity.allowance = order.allowance;
            this.orderCreateEntity.tax = order.tax;
            this.orderCreateEntity.adjustment = order.adjustment;
            this.orderCreateEntity.totalAmount = order.totalPrice;
            this.orderCreateEntity.totalDiscount = order.totalDiscount;

            //// 載入訂購人清單
            GetExternalUserDropdownList(
              this.orderCreateEntity.externalBusinessId
            )
              .then((result) => {
                if (FailAndNeedHandle(result)) {
                  ElNotification({
                    title: "訂購人清單載入失敗",
                    message: result.data.errorMessage,
                    duration: 3000,
                    type: "error",
                  });
                } else {
                  this.externalUserDropdownList = result.data.data;
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadings.order = false;
        });
    },
    async LoadOrderSlaveList(orderId: number) {
      this.loadings.orderSlaves = true;
      await GetOrderSlaveListByOrderId(orderId)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "訂單子單載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let list = [] as OrderSlaveCreateEntity[];

            result.data.data.forEach((element) => {
              let skuImageUrl: any = element.skuImageLinks[0];
              let entity: OrderSlaveCreateEntity = {
                uuid: "",
                productId: null!,
                productName: element.productName,
                skuId: element.skuId,
                skuImageUrl: skuImageUrl,
                skuCode: element.skuCode,
                skuName: element.skuName,
                unitPrice: element.unitPrice,
                salePrice: element.salePrice,
                qty: element.qty,
              };

              list.push(entity);
            });

            this.orderSlaveCreateList = list;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadings.orderSlaves = false;
        });
    },
    CancelSave() {
      ElMessageBox.confirm("是否要取消存檔？", "Warning", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push({ name: "OrderList" });
        })
        .catch(() => { });
    },
    OpenCreateOrderSlaveWindow() {
      this.orderSlaveMode = "Create";
      this.orderSlaveFormVisible = true;
      this.LoadSkuDropdownList("");
      this.ClearOrderSlaveData();
    },
    OpenUpdateOrderSlaveWindow() {
      this.orderSlaveMode = "Update";
      this.orderSlaveFormVisible = true;
      this.LoadSkuDropdownList("");
      this.ClearOrderSlaveData();
    },
    ValidateFields(): boolean {
      if (
        this.orderCreateEntity.orderSlaves == null ||
        this.orderCreateEntity.orderSlaves.length == 0
      ) {
        this.ShowErrorMsg("無子單資料，請新增子單");
        return false;
      }

      return true;
    },
    ShowErrorMsg(message: string) {
      ElNotification({
        title: "訂單新增失敗",
        message: message,
        duration: 3000,
        type: "error",
      });
    },
    async FormValidation(refs: any) {
      let isValid = false;
      await refs.validate((valid: any) => {
        isValid = valid;
      });

      return isValid;
    },
    /**
     * 建立訂單
     */
    CreateOrder() {
      this.orderCreateEntity.subTotalAmount = this.calcSubTotalAmount;
      this.orderCreateEntity.totalAmount = this.calcTotalAmount;
      this.orderCreateEntity.orderSlaves = this.orderSlaveCreateList;

      if (
        !this.FormValidation(this.$refs.orderFormRef) ||
        !this.ValidateFields()
      ) {
        ElNotification({
          title: "新增訂單",
          message: "尚有必填欄位未填寫，請確認!",
          duration: 3000,
          type: "error",
        });
        return;
      }

      this.loadings.createOrder = true;
      CreateOrder(this.orderCreateEntity)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "訂單新增失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            ElNotification({
              title: "訂單新增",
              message: "訂單新增成功",
              duration: 3000,
              type: "success",
            });
            let createdOrderId: any = result.data;
            this.$router.push({
              name: "OrderEdit",
              params: { id: createdOrderId },
            });
          }
        })
        .catch((err) => {
          ElNotification({
            title: "新增",
            message: `訂單新增失敗!`,
            duration: 3000,
            type: "error",
          });

          console.error("err", err);
        })
        .finally(() => {
          this.loadings.createOrder = false;
        });
    },
    CreateOrderSlaveInfo() {
      if (!this.FormValidation(this.$refs.orderSlaveFormRef)) {
        return;
      }

      this.loadings.createOrderSlave = true;
      this.orderSlaveCreateEntity.uuid = uuidv4();
      this.orderSlaveCreateList.push(this.orderSlaveCreateEntity);

      this.orderSlaveFormVisible = false;
      this.loadings.createOrderSlave = false;
      this.ClearOrderSlaveData();
    },
    UpdateOrderSlaveInfo() {
      if (!this.FormValidation(this.$refs.orderSlaveFormRef)) {
        return;
      }

      let orderSlave = this.orderSlaveCreateList.at(
        this.currentOrderSlaveRowIndex
      ) as OrderSlaveCreateEntity;
      orderSlave.skuId = this.orderSlaveCreateEntity.skuId;
      orderSlave.skuName = this.orderSlaveCreateEntity.skuName;
      orderSlave.productId = this.orderSlaveCreateEntity.productId;
      orderSlave.unitPrice = this.orderSlaveCreateEntity.unitPrice;
      orderSlave.salePrice = this.orderSlaveCreateEntity.salePrice;
      orderSlave.qty = this.orderSlaveCreateEntity.qty;
      orderSlave.skuImageUrl = this.orderSlaveCreateEntity.skuImageUrl;

      this.ClearOrderSlaveData();
      this.orderSlaveFormVisible = false;
    },
    ClearOrderSlaveData() {
      this.orderSlaveCreateEntity = {
        uuid: "",
        productId: null!,
        productName: "",
        skuImageUrl: "",
        skuId: null!,
        skuCode: "",
        skuName: "",
        unitPrice: 0,
        salePrice: 0,
        qty: 0,
      };
      this.currentOrderSlaveRowIndex = 0;
    },
    ClearBusinessData() {
      this.orderCreateEntity.externalBusinessPhoneNo = "";
      this.orderCreateEntity.externalUserId = null!;
      this.orderCreateEntity.externalUserName = "";
      this.orderCreateEntity.internalUserId = null!;
      this.orderCreateEntity.internalUserName = "";
      this.orderCreateEntity.shippingAddress = "";
    },
    ClearOrderData() {
      this.orderCreateEntity = {
        code: "",
        externalBusinessId: null!,
        externalBusinessName: "",
        externalBusinessPhoneNo: "",
        externalUserId: null!,
        externalUserName: "",
        internalUserId: null!,
        internalUserName: "",
        shippingAddress: "",
        status: "",
        orderDateTime: null!,
        finishDateTime: null!,
        note: "",
        subTotalAmount: 0,
        allowance: 0,
        tax: 0,
        adjustment: 0,
        totalAmount: 0,
        totalDiscount: 0,
        orderSlaves: null!,
      };
    },
    HandleOrderSlaveEdit(index: any, row: any) {
      this.OpenUpdateOrderSlaveWindow();
      this.currentOrderSlaveRowIndex = index;
      this.orderSlaveCreateEntity.skuId = row.skuId;
      this.orderSlaveCreateEntity.skuCode = row.outerSysCode;
      this.orderSlaveCreateEntity.skuName = row.skuName;
      this.orderSlaveCreateEntity.unitPrice = row.unitPrice;
      this.orderSlaveCreateEntity.salePrice = row.salePrice;
      this.orderSlaveCreateEntity.qty = row.qty;
      this.orderSlaveCreateEntity.skuImageUrl = row.skuImageUrl;
    },
    HandleOrderSlaveRemove(index: any) {
      this.orderSlaveCreateList.splice(index, 1);
    },
    HandleSkuDropdownChange(skuId: any) {
      GetSkuById(skuId)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "Sku 載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let orderSlaveEntity = result.data.data;

            this.orderSlaveCreateEntity.skuCode = orderSlaveEntity.outerSysCode;
            this.orderSlaveCreateEntity.skuName = orderSlaveEntity.name;
            this.orderSlaveCreateEntity.unitPrice = orderSlaveEntity.price;
            this.orderSlaveCreateEntity.skuImageUrl =
              orderSlaveEntity.imageLinks[0];
          }
        })
        .catch(() => { });
    },
    LoadExternalBusinessDropdownList(query: string) {
      debounce(() => {
        this.loadings.externalBusinessDropdownList = true;
        GetExternalBusinessDropdownList(query)
          .then((result) => {
            if (FailAndNeedHandle(result)) {
              ElNotification({
                title: "客戶清單載入失敗",
                message: result.data.errorMessage,
                duration: 3000,
                type: "error",
              });
            } else {
              this.externalBusinessDropdownList = result.data.data;
            }
          })
          .catch(() => {
            console.error("Load LoadExternalBusinessDropdownList error");
          })
          .finally(() => {
            this.loadings.externalBusinessDropdownList = false;
          });
      }, "400ms")();
    },
    LoadInternalUserDropdownList() {
      this.loadings.internalUserDropdownList = true;
      GetInternalUserDropdownList()
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "負責業務清單載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            this.internalUserDropdownList = result.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadings.internalUserDropdownList = false;
        });
    },
    LoadSkuDropdownList(query: string) {
      debounce(() => {
        GetSkuDropdownList(query)
          .then((result) => {
            if (FailAndNeedHandle(result)) {
              ElNotification({
                title: "SKU 清單載入失敗",
                message: result.data.errorMessage,
                duration: 3000,
                type: "error",
              });
            } else {
              this.skuDropdownList = result.data.data;
            }
          })
          .catch(() => {
            console.log("LoadSkuDropdownList error");
          });
      }, "200ms")();
    },
    HandleBusinessDropdownListChange() {
      GetExternalBusinessById(this.orderCreateEntity.externalBusinessId).then(
        (result) => {
          if (FailAndNeedHandle(result)) {
            console.error(result.data.errorMessage);
          } else {
            let extBusiness = result.data.data;
            this.orderCreateEntity.externalBusinessPhoneNo =
              extBusiness.cellPhone;
            this.orderCreateEntity.internalUserId = extBusiness.internalUserId;
            this.orderCreateEntity.shippingAddress =
              extBusiness.shippingAddress;

            //// 載入訂購人清單
            GetExternalUserDropdownList(extBusiness.id)
              .then((result) => {
                if (FailAndNeedHandle(result)) {
                  ElNotification({
                    title: "訂購人清單載入失敗",
                    message: result.data.errorMessage,
                    duration: 3000,
                    type: "error",
                  });
                } else {
                  this.externalUserDropdownList = result.data.data;
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        }
      );
    },

    async ConfirmShipped() {
      this.loadings.createOrder = true;

      const req = { id: this.id } as IdEntity;
      const res = await ConfirmShipped(req);

      this.loadings.createOrder = false;

      if (FailAndNeedHandle(res)) {
        ElNotification({
          title: "出貨確認失敗",
          message: res.data.errorMessage,
          duration: 3000,
          type: "error",
        });
      } else {
        ElNotification({
          title: "出貨確認",
          message: "出貨確認成功!",
          duration: 3000,
          type: "success",
        });

        // reload data
        setTimeout(() => {
          this.LoadData();
        }, 500);
      }
    }
  }
});
