import moment from 'moment';

export function FormatNumber(value: any, decimals = 0, thousandsSeparator = ','): string {
    let result = parseFloat(value).toFixed(decimals).toString()
    if (thousandsSeparator) {
        result = result.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)
    }
    return result
}

export function FormatDate(d: Date, format: string): string {
    if (d == null || d == undefined) {
        return '';
    }
    
    return moment(d).format(format);
}