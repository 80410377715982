
import { defineComponent } from "vue";
import { debounce } from "vue-debounce";
import { ElNotification } from "element-plus";
import {
  PointHistoryCreateEntity,
  PointHistoryUpdateEntity,
  PointHistoryDetailEntity,
} from "../../models/backstages/PointHistoryModels";
import {
  OrderListResponseEntity,
} from "../../models/backstages/OrderModels";
import {
  SourceTypeEnum,
} from "../../models/backstages/enums/PointHistoryEnums";
import {
  GetPointHistoryById,
  CreatePointHistory,
  UpdatePointHistory
} from "../../services/backstages/PointHistoryService";
import { 
  GetExternalBusinessDropdownList
} from "../../services/backstages/ExternalBusinessService";
import {
  GetExternalUserDropdownList
} from "../../services/backstages/ExternalUserService"
import { GetDefinitionDropdownListByTableAndColumnName } from "../../services/DefinitionService";
import { FailAndNeedHandle } from "../../services/commons/CommonService";
import { FormatDate } from "../../store/commons/FormatHelper"
import OrderListPicker from "./components/OrderListPicker.vue";
import { TransformNumber } from "../../commons/FormatHelper";

export default defineComponent({
  name: "PointHistoryInfoView",
  components: { OrderListPicker },
  props: ["mode"],
  data() {
    return {
      title: (this.mode == "Create" ? "新增" : "編輯") + "點數",

      /**
       * Loading
       */
      loading: false,
      pointHistoryLoaded: true,
      isProductImageUploading: false,
      isSkuImageUploading: false,

      /**
       * Dialog
       */
      isOrderDialogVisible: false,

      labelPosition: "right",
      id: +this.$route.params.id || null!,

      /**
       * 點數歷程
       */
      pointHistoryMode: "Create",
      skuFormVisible: false,
      pointHistoryDetailEntity: {
        id: null!,
        externalBusinessId: null!,
        externalUserId: null!,
        amount: 0,
        type: "",
        typeDesc: "",
        sourceType: "",
        sourceTypeDesc: "",
        sourceId: null!,
        sourceCode: "",
        memo: "",
        createdDateTime: null!,
        createdUser: ""
      } as PointHistoryDetailEntity,
      pointHistoryCreateEntity: {
        externalBusinessId: null!,
        externalUserId: null!,
        amount: 0,
        type: "",
        sourceType: "",
        sourceId: null!,
        sourceCode: "",
        memo: ""
      } as PointHistoryCreateEntity,
      pointHistoryUpdateEntity: {
        id: null!,
        externalBusinessId: null!,
        externalUserId: null!,
        amount: 0,
        type: "",
        sourceType: "",
        sourceId: null!,
        sourceCode: "",
        memo: ""
      } as PointHistoryUpdateEntity,

      /**
       * 下拉清單
       */
      externalBusinessDropdownList: [] as any,
      externalUserDropdownList: [] as any,
      sourceTypeDropdownList: [] as any,
      typeDropdownList: [] as any,

      /**
       * Validation rules
       */
      pointHistoryValidationRules: {
        externalBusinessId: [
          { required: true, message: "請選擇客戶", trigger: "change" }
        ],
        externalUserId: [
          { required: true, message: "請選擇使用者", trigger: "change" }
        ],
        type: [
          { required: true, message: "請選擇類型", trigger: "change" },
        ],
        sourceType: [
          { required: true, message: "請選擇類型", trigger: "change" },
        ],
        sourceCode: [
          { required: true, message: "請輸入單據", trigger: "blur" },
        ],
        amount: [
          { required: true, message: "請輸入數字 1~999999", trigger: "blur" },
          {
            type: "integer",
            min: 1,
            max: 999999,
            message: "請輸入數字 1~999999",
            trigger: "blur",
            transform: TransformNumber,
          },
        ],
      },
    };
  },
  created() {
    this.ClearData();
    this.LoadData();
  },
  computed: {
    saveBtnLoading() {
      return (
        this.pointHistoryLoaded == false || this.loading
      );
    },
    isCreateMode() {
      return this.mode == "Create";
    },
    isSkuCreateMode() {
      return this.pointHistoryMode == "Create";
    },
    skuDialogTitle() {
      return this.isSkuCreateMode ? "新增點數歷程" : "編輯點數歷程";
    },
    skuDialogButtonText() {
      return this.isSkuCreateMode ? "新增" : "更新";
    },
    /**
     * 「來源」為訂單相關 (成立訂單/取消訂單)
     */
    isSourceTypeOrder() {
      return this.pointHistoryDetailEntity.sourceType == SourceTypeEnum.CreateOrder 
          || this.pointHistoryDetailEntity.sourceType == SourceTypeEnum.CancelOrder;
    }
  },
  methods: {
    FormatDate: FormatDate,
    OpenOrderDialog() {
      this.isOrderDialogVisible = true;
    },
    /**
     * 清空資料
     */
    ClearData() {
      this.ClearPointHistoryData();
    },
    /**
     * 載入資料
     */
    async LoadData() {
      this.LoadDropdownList();

      switch (this.mode) {
        case "Create":
          break;

        case "Edit":
          await this.LoadPointHistoryData(this.id);
          await this.LoadExternalUserDropdownList(this.pointHistoryDetailEntity.externalBusinessId);
          break;

        default:
      }
    },
    LoadDropdownList() {
      this.LoadExternalBusinessDropdownList('');

      GetDefinitionDropdownListByTableAndColumnName('point_history', 'type')
        .then((result: any) => {
          this.typeDropdownList = result.data.data;
        })
        .catch((err: any) => {
          ElNotification({
            title: "類型清單載入失敗",
            message: err,
            duration: 3000,
            type: "error",
          });
        });

      GetDefinitionDropdownListByTableAndColumnName('point_history', 'source_type')
        .then((result: any) => {
          this.sourceTypeDropdownList = result.data.data;
        })
        .catch((err: any) => {
          ElNotification({
            title: "來源清單載入失敗",
            message: err,
            duration: 3000,
            type: "error",
          });
        });
    },
    /**
     * 載入客戶 (髮廊) 清單
     */
    async LoadExternalBusinessDropdownList(query: string) {
      debounce(() => {
        GetExternalBusinessDropdownList(query)
          .then((result) => {
            if (FailAndNeedHandle(result)) {
              ElNotification({
                title: "客戶清單載入失敗",
                message: result.data.errorMessage,
                duration: 3000,
                type: "error",
              });
            } else {
              this.externalBusinessDropdownList = result.data.data;
            }
          })
          .catch(() => {
            console.error("Load LoadExternalBusinessDropdownList error");
          });
      }, "400ms")();
    },
    async ClearAndReloadExternalUserDropdownList() {
      this.pointHistoryDetailEntity.externalUserId = null!;

      if (this.pointHistoryDetailEntity.externalBusinessId != null
      && this.pointHistoryDetailEntity.externalBusinessId != undefined
      && this.pointHistoryDetailEntity.externalBusinessId != '' as any) {
        this.LoadExternalUserDropdownList(this.pointHistoryDetailEntity.externalBusinessId);
      }
    },
    async LoadExternalUserDropdownList(extBusinessId: number) {
      GetExternalUserDropdownList(extBusinessId)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
              ElNotification({
                title: "使用者載入失敗",
                message: result.data.errorMessage,
                duration: 3000,
                type: "error",
              });
            } else {
              this.externalUserDropdownList = result.data.data;
              
              // auto select first item when it has only one
              if (this.externalUserDropdownList.length == 1) {
                this.pointHistoryDetailEntity.externalUserId = this.externalUserDropdownList[0].value;
              }
            }
        })
        .catch(() => {
          console.error("Load LoadExternalUserDropdownList error");
        });
    },
    /**
     * 載入點數歷程資料
     */
    async LoadPointHistoryData(id: number) {
      this.pointHistoryLoaded = false;
      await GetPointHistoryById(id)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "商品載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            this.pointHistoryDetailEntity = result.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.pointHistoryLoaded = true;
        });
    },
    /**
     * 建立點數歷程資料
     */
    async CreatePointHistory() {
      if (!(await this.PointHistoryFormValidation())) {
        return;
      }

      this.pointHistoryCreateEntity = {
        externalBusinessId: this.pointHistoryDetailEntity.externalBusinessId,
        externalUserId: this.pointHistoryDetailEntity.externalUserId,
        type: this.pointHistoryDetailEntity.type,
        sourceType: this.pointHistoryDetailEntity.sourceType,
        sourceId: this.pointHistoryDetailEntity.sourceType == SourceTypeEnum.Manual
                  ? 0
                  : this.pointHistoryDetailEntity.sourceId,
        sourceCode: this.pointHistoryDetailEntity.sourceType == SourceTypeEnum.Manual
                    ? ''
                    : this.pointHistoryDetailEntity.sourceCode,
        amount: this.pointHistoryDetailEntity.amount,
        memo: this.pointHistoryDetailEntity.memo
      }

      this.loading = true;
      
      CreatePointHistory(this.pointHistoryCreateEntity)
        .then((result) => {
          if (result.status != 200) {
            ElNotification({
              title: "新增",
              message: "點數歷程新增失敗!",
              duration: 3000,
              type: "error",
            });
          }
          else {
            ElNotification({
              title: "新增",
              message: "點數歷程新增成功!",
              duration: 3000,
              type: "success",
            });
            
            this.$router.push({
              name: "PointHistoryEdit",
              params: { id: result.data + '' },
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "新增",
            message: "點數歷程新增失敗!",
            duration: 3000,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 驗證商品表單欄位
     */
    async PointHistoryFormValidation() {
      let basicInfoRefs: any = this.$refs.pointHistoryBasicInfoFormRef;
      let pointInfoRefs: any = this.$refs.pointHistoryPointInfoFormRef;
      let isBasicInfoValid = false;
      let isPointInfoValid = false;

      await basicInfoRefs.validate((valid: any) => {
        isBasicInfoValid = valid;
      });

      await pointInfoRefs.validate((valid: any) => {
        isPointInfoValid = valid;
      });

      return isBasicInfoValid && isPointInfoValid;
    },
    /**
     * 更新商品 & SKU
     */
    async UpdatePointHistory() {
      if (!(await this.PointHistoryFormValidation())) {
        return;
      }

      this.loading = true;

      this.pointHistoryUpdateEntity = {
        id: this.id, 
        externalBusinessId: this.pointHistoryDetailEntity.externalBusinessId,
        externalUserId: this.pointHistoryDetailEntity.externalUserId,
        amount: this.pointHistoryDetailEntity.amount,
        type: this.pointHistoryDetailEntity.type,
        sourceType: this.pointHistoryDetailEntity.sourceType,
        sourceId: this.pointHistoryDetailEntity.sourceType == SourceTypeEnum.Manual
                  ? 0
                  : this.pointHistoryDetailEntity.sourceId,
        sourceCode: this.pointHistoryDetailEntity.sourceType == SourceTypeEnum.Manual
                    ? ''
                    : this.pointHistoryDetailEntity.sourceCode,
        memo: this.pointHistoryDetailEntity.memo,
      }

      await UpdatePointHistory(this.pointHistoryUpdateEntity)
        .then((result) => {
          if (result.status != 200) {
            ElNotification({
              title: "點數歷程更新失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            ElNotification({
              title: "更新",
              message: "點數歷程更新成功!",
              duration: 3000,
              type: "success",
            });
          }
        })
        .catch((err) => {
          ElNotification({
            title: "點數歷程更新失敗",
            message: err,
            duration: 3000,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 清空點數歷程資訊
     */
    ClearPointHistoryData() {
      this.pointHistoryDetailEntity = {
        id: this.id,
        externalBusinessId: null!,
        externalUserId: null!,
        amount: 0,
        type: "",
        typeDesc: "",
        sourceType: "",
        sourceTypeDesc: "",
        sourceId: null!,
        sourceCode: "",
        memo: "",
        createdDateTime: null!,
        createdUser: ""
      };
    },
    HandleOrderListPickerConfirm(selectedOrder: OrderListResponseEntity) {
      this.isOrderDialogVisible = false;
      this.pointHistoryDetailEntity.sourceId = selectedOrder.id;
      this.pointHistoryDetailEntity.sourceCode = selectedOrder.code;
    }
  },
  setup() {
    return {};
  },
});
