import { ApiResponseEntity, SearchCriteriaEntity, SearchResultEntity } from "@/models/ApiModels";
import { IdEntity } from "@/models/commons/CommonModels";
import { FuzzySearchProductListEntity, ProductSearchEntity, StoreProductDetailEntity, StoreProductFuzzySearchEntity, StoreProductListEntity } from "@/models/stores/ProductModels";

import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService"

const prefix = "/api/store"

export async function GetStoreProductList(params: SearchCriteriaEntity<ProductSearchEntity>): Promise<AxiosResponse<ApiResponseEntity<SearchResultEntity<StoreProductListEntity>>, any>> {

    const url = prefix + "/Product/GetStoreProductList";

    const result = await Post<SearchCriteriaEntity<ProductSearchEntity>, SearchResultEntity<StoreProductListEntity>>(url, params);

    return result;
}

export async function GetStoreProductDetailById(params: IdEntity): Promise<AxiosResponse<ApiResponseEntity<StoreProductDetailEntity>, any>> {

    const url = prefix + "/Product/GetStoreProductDetailById";

    const result = await Post<IdEntity, StoreProductDetailEntity>(url, params);

    return result;
}


export async function GetStoreProductByFuzzySearch(params: SearchCriteriaEntity<StoreProductFuzzySearchEntity>): Promise<AxiosResponse<ApiResponseEntity<SearchResultEntity<FuzzySearchProductListEntity>>, any>> {

    const url = prefix + "/Product/GetStoreProductByFuzzySearch";

    const result = await Post<SearchCriteriaEntity<StoreProductFuzzySearchEntity>, SearchResultEntity<FuzzySearchProductListEntity>>(url, params);

    return result;
}


