
import { defineComponent } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  InstantCalcPoint
} from "../../services/backstages/PointHistoryService";

export default defineComponent({
  name: "PointToolView",
  components: { },
  data() {
    return {
      title: "點數設定",

      /**
       * Loading
       */
      loading: false,

      labelPosition: "right",
      id: +this.$route.params.id || null!,
      
      /**
       * 驗收使用。驗收完畢後應移除
       */
      isOpenPointTool: false,
      calcOrderDateTimeStart: null!,
      calcOrderDateTimeEnd: null!,

    };
  },
  created() {
  },
  methods: {
    InstantCalcPoint(startDateTime: Date) {
      if (startDateTime == null || startDateTime == undefined) {
        ElMessage({
          type: 'error',
          message: '日期錯誤，請確認！',
        })
        return;
      }
      ElMessageBox.confirm(
        '確定要計算訂單點數？',
        'Warning',
        {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          InstantCalcPoint(startDateTime)
          .then(() => {
            ElMessage({
              type: 'success',
              message: '計算完成，請稍候刷新頁面',
            })

            this.isOpenPointTool = false
          })
          .catch(() => {
            ElMessage({
              type: 'error',
              message: '計算失敗，請聯絡管理員',
            })
          });
        })
        .catch(() => {})
    },
  },
  setup() {
    return {};
  },
});
