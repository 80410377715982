
import { defineComponent } from "vue";
import { debounce } from "vue-debounce";
import { ElNotification, ElMessageBox } from "element-plus";
import {
  SearchPointHistory,
  DeletePointHistory,
} from "../../services/backstages/PointHistoryService";
import {
  PointHistorySearchEntity,
  PointHistoryListEntity
} from "../../models/backstages/PointHistoryModels";
import { DefinitionSearchEntity } from "../../models/commons/DefinitionModels";
import { 
  GetExternalBusinessDropdownList
} from "../../services/backstages/ExternalBusinessService";
import { GetDefinitionDropdownList } from "../../services/DefinitionService";
import { FailAndNeedHandle } from "../../services/commons/CommonService";

export default defineComponent({
  name: "PointHistoryListView",
  components: {
  },
  data() {
    return {
      isPointHistoryDataLoading: false,
      isSpuStatusChanged: false,
      isOpenFilter: false,
      isOpenOrder: false,
      pointHistorySearchEntity: {
        take: 10,
        skip: 0,
        pointStart: null!,
        pointEnd: null!,
        sourceType: "",
        sourceCode: "",
        externalBusinessId: null!,
        externalUserId: null!,
        externalUserName: "",
        orderCondition: {
          name: "Id",
          isAscending: false,
        },
      } as PointHistorySearchEntity,
      filterEntity: {
        launchDateStart: new Date(),
        launchDateEnd: new Date(),
        highestSellingPrice: undefined,
        lowestSellingPrice: undefined,
      },
      tableData: [] as Array<PointHistoryListEntity>,

      /**
       * 下拉清單
       */
      externalBusinessDropdownList: [] as any,
      sourceTypeDropdownList: [] as any,

      /**
       * Pagination vars
       */
      currentPage: 1,
      totalPage: 0,
      currentCount: 0,
      totalCount: 0,
      pageSize: 10,
      pageSizes: [10, 25, 50, 100],

      /**
       * 驗收使用。驗收完畢後應移除
       */
      isOpenPointTool: false,
      calcOrderDateTimeStart: null!,
      calcOrderDateTimeEnd: null!,
    };
  },
  created() {
    this.ClearData();
    this.LoadData();
  },
  computed: {
    filterBtnText(): string {
      let text = "篩選";

      let filterCount = 0;
     
      if (
        this.pointHistorySearchEntity.pointStart != undefined ||
        this.pointHistorySearchEntity.pointEnd != undefined
      ) {
        filterCount++;
      }

      if (this.pointHistorySearchEntity.sourceType != "") {
        filterCount++;
      }

      if (this.pointHistorySearchEntity.sourceCode != "") {
        filterCount++;
      }

      if (
        this.pointHistorySearchEntity.externalBusinessId != undefined
      ) {
        filterCount++;
      }

      if (
        this.pointHistorySearchEntity.externalUserName != ""
      ) {
        filterCount++;
      }

      if (filterCount != 0) {
        text = text + "(" + filterCount + ")";
      }

      return text;
    },
  },
  methods: {
    ClearConditionFilterData() {
      this.pointHistorySearchEntity = {
        take: 10,
        skip: 0,
        pointStart: null!,
        pointEnd: null!,
        sourceType: "",
        sourceCode: "",
        externalBusinessId: null!,
        externalUserId: null!,
        externalUserName: "",
        orderCondition: {
          name: "Id",
          isAscending: false,
        },
      };
    },
    ClearData() {
      this.ClearConditionFilterData();
    },
    LoadData() {
      this.SearchPointHistoryData();
      this.LoadDropdownList();
    },
    LoadDropdownList() {
      this.LoadExternalBusinessDropdownList("");

      let searchEntity: DefinitionSearchEntity = {
        tableName: "point_history",
        columnName: "source_type",
      };

      GetDefinitionDropdownList(searchEntity)
        .then((result) => {
          this.sourceTypeDropdownList = result.data.data;
        })
        .catch((err) => {
          ElNotification({
            title: "來源類型清單載入失敗",
            message: err,
            duration: 3000,
            type: "error",
          });
        });
    },
    LoadExternalBusinessDropdownList(query: string) {
      debounce(() => {
        GetExternalBusinessDropdownList(query)
          .then((result) => {
            if (FailAndNeedHandle(result)) {
              ElNotification({
                title: "客戶清單載入失敗",
                message: result.data.errorMessage,
                duration: 3000,
                type: "error",
              });
            } else {
              this.externalBusinessDropdownList = result.data.data;
            }
          })
          .catch(() => {
            console.error("Load LoadExternalBusinessDropdownList error");
          });
      }, "400ms")();
    },
    ApplyConditionFilter() {
      this.isOpenFilter = false;
      this.SearchPointHistoryData();
    },
    ClearConditionFilter() {
      this.ClearConditionFilterData();
    },
    SearchPointHistoryData() {
      this.isPointHistoryDataLoading = true;

      this.pointHistorySearchEntity.skip = this.pageSize * (this.currentPage - 1);
      this.pointHistorySearchEntity.take = this.pageSize;

      SearchPointHistory(this.pointHistorySearchEntity)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "點數歷程載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let paginationResult = result.data.data;
            this.tableData = paginationResult.data;
            this.currentCount = paginationResult.currentCount;
            this.totalCount = paginationResult.totalCount;
            this.totalPage = paginationResult.totalPage;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isPointHistoryDataLoading = false;
        });
    },
    OpenRoute(name: string) {
      let route = this.$router.resolve({ name: name });
      window.open(route.href, "_blank");
    },
    OpenUrl(url: string) {
      window.open(url, "_blank");
    },
    OpenFilter() {
      this.isOpenFilter = true;
    },
    OpenOrder() {
      this.isOpenOrder = true;
    },
    HandleSizeChange(val: number) {
      this.pageSize = val;
      this.SearchPointHistoryData();
    },
    HandleCurrentChange(val: number) {
      this.currentPage = val;
      this.SearchPointHistoryData();
    },
    DateFormatter(row: any, column: any) {
      let d = new Date();

      if (column.property == "createdDateTime") {
        d = new Date(row.createdDateTime);
      } else {
        d = new Date(row.updatedDateTime);
      }

      let mm = d.getMonth() + 1;
      let dd = d.getDate();
      let hh = d.getHours();
      let m = d.getMinutes();
      return `${d.getFullYear()}/${(mm > 9 ? "" : "0") + mm}/${
        (dd > 9 ? "" : "0") + dd
      } ${(hh > 9 ? "" : "0") + hh}:${(m > 9 ? "" : "0") + m}`;
    },
    EditPoinHistoryAction(index: any, row: any) {
      this.OpenUrl("Edit/" + row.id);
    },
    DeletePointHistoryAction(index: any, row: any) {
      let id = row.id;

      ElMessageBox.confirm(
        `是否要刪除點數歷程 id: ${id}？ <br>
         <b style='color:red;'>會影響使用者使用點數的額度</b>`,
        "警告",
        {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning",
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          DeletePointHistory(id)
            .then(() => {
              ElNotification({
                title: `刪除點數歷程`,
                message: `點數歷程 Id:${id} 刪除成功`,
                duration: 3000,
                type: "success",
              });

              //// reload spu data
              this.SearchPointHistoryData();
            })
            .catch(() => {
              ElNotification({
                title: `刪除點數歷程`,
                message: `點數歷程 Id:${id} 刪除失敗，請確認！`,
                duration: 3000,
                type: "error",
              });
            });
        })
        .catch(() => {});
    },

    HandleSortChange(column: any) {
      if (column.column !== null) {
        this.pointHistorySearchEntity.orderCondition.name = this.CapitalizeFirstLetter(
          column.prop
        );
        this.pointHistorySearchEntity.orderCondition.isAscending =
          column.order == "ascending";
      } else {
        this.pointHistorySearchEntity.orderCondition.name = "";
        this.pointHistorySearchEntity.orderCondition.isAscending = true;
      }

      this.SearchPointHistoryData();
    },
    /**
     * 將英文的首字母轉換成大寫
     * @param str 要轉換的字串
     * @returns 首字母大寫
     */
    CapitalizeFirstLetter(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    HandleSelectItemClear(elementName: any, element: any) {
      element[elementName] = undefined!;
    },
  },
});
