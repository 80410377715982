
import { defineComponent } from "vue";
import {
  OrderSearchEntity,
  OrderSearchTemplateEntity,
  OrderDetailEntity,
  OrderListEntity,
  OrderSlaveDetailEntity,
} from "../../models/stores/OrderModels";
import { OrderStatusType } from "../../models/stores/enums/OrderEnums";
import VueCurrencyInput from "../../components/commons/VueCurrencyInput.vue";
import {
  CodeValuePairEntity,
  IdEntity,
} from "../../models/commons/CommonModels";
import {
  CancelOrder,
  GetById,
  Search,
} from "../../services/stores/OrderService";
import { format } from "date-fns";
import { ElLoading } from "element-plus";
import { Toast } from "vant";
import { SearchCriteriaEntity } from "../../models/ApiModels";
import { ReproduceByOrderId } from "../../services/stores/ShoppingCartService";
import { Icon } from "@iconify/vue";
import { isNaN } from "lodash-es";
import { useStorePersistStore } from "../../store/StorePersistStore";
import { mapStores } from "pinia";
import _ from "lodash";
import { FailAndNeedHandle } from "../../services/commons/CommonService";
import { TypeEnum } from "@/models/stores/enums/DiscountActivityEnums";
import { GetAsset } from "../../services/stores/AssetsHelper"

export default defineComponent({
  name: "OrderSearchView",
  components: { VueCurrencyInput, Icon },
  data() {
    return {
      isSearch: false,
      isDisabledSearch: false,
      isInit: false,
      pageSize: 20,
      currentPage: 0,
      isOpenMenu: false,
      isOpenFilter: false,
      isOpenOrder: false,
      isOpenOrderDetail: false,
      isShowSummaryDetail: false,
      viewMode: "list",
      orderList: [] as Array<OrderListEntity>,
      orderStatusTypeList: OrderStatusType,
      filterEntity: {
        status: "",
        lowestPrice: undefined,
        highestPrice: undefined,
        orderDateTimeStart: undefined,
        orderDateTimeEnd: undefined,
        shippingDateTimeStart: undefined,
        shippingDateTimeEnd: undefined,
        finishedDateTimeStart: undefined,
        finishedDateTimeEnd: undefined,
        orderCode: "",
        skuCode: "",
        orderBy: "OrderDateTimeDesc",
      } as OrderSearchEntity,

      filterTemplateEntity: {
        status: "",
        lowestPrice: undefined,
        highestPrice: undefined,
        orderDateTimeStart: undefined,
        orderDateTimeEnd: undefined,
        shippingDateTimeStart: undefined,
        shippingDateTimeEnd: undefined,
        finishedDateTimeStart: undefined,
        finishedDateTimeEnd: undefined,
        orderCode: "",
        skuCode: "",
      } as OrderSearchTemplateEntity,

      orderTemplateEntity: "OrderDateTimeDesc",

      //data for order detail
      orderDetail: {} as OrderDetailEntity,
      showPicker: false,

      isFreeze: false,

      shoppingCartPointItemCollapseArr: ['point'],

      giftItemCollapseArr: ['gift'],

    };
  },

  methods: {
    //取得訂單明細並開啟訂單明細選單
    async GetDetailAndOpenDrawer(id: number) {
      const loading = ElLoading.service({
        lock: true,
        text: "讀取中",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const idEntity = { id: id } as IdEntity;

      const res = await GetById(idEntity);

      loading.close();

      if (FailAndNeedHandle(res)) {
        Toast({
          message: "取得訂單明細失敗",
          icon: "warning",
          duration: 500,
          className: "vant-toast-custom",
        });
      } else {
        this.orderDetail = res.data.data;
        this.isOpenOrderDetail = true;
      }
    },

    //關閉訂單明細並回覆預設值
    CloseAndResetOrderDetail() {
      this.orderDetail = {
        id: 0,
        status: "",
        orderPeopleName: "",
        shippingAddress: "",
        orderDateTime: undefined,
        shippingDateTime: undefined,
        finishedDateTime: undefined,
        memo: "",
        totalPrice: "",
        allowance: "",
        taxRate: 0,
        adjustment: "",
        items: [] as Array<OrderSlaveDetailEntity>,
      } as OrderDetailEntity;

      this.isOpenOrderDetail = false;
    },

    //訂單查詢
    async Search(): Promise<void> {
      if (this.isInit == false) {
        return;
      }

      const loading = ElLoading.service({
        lock: true,
        text: "讀取中",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.isDisabledSearch = true;

      const res = await Search({
        condition: this.filterEntity,
        skip: this.pageSize * this.currentPage,
        take: this.pageSize,
      } as SearchCriteriaEntity<OrderSearchEntity>);

      loading.close();

      if (FailAndNeedHandle(res)) {
        Toast({
          message: "取得列表失敗",
          icon: "warning",
          duration: 500,
          className: "vant-toast-custom",
        });

        this.isDisabledSearch = false;
      }
      else {
        const ids = new Set(this.orderList.map((m) => m.id));

        this.orderList = [
          ...this.orderList,
          ...res.data.data.list.filter((m) => ids.has(m.id) == false),
        ];

        if (res.data.data.list.length == this.pageSize) {
          this.currentPage++;
        }

        this.isDisabledSearch = res.data.data.totalCount == this.orderList.length ? true : false;

        this.isSearch = true;
      }
    },

    //清空查詢條件
    ResetCondition(): void {
      this.filterTemplateEntity.status = "";
      this.filterTemplateEntity.lowestPrice = undefined;
      this.filterTemplateEntity.highestPrice = undefined;
      this.filterTemplateEntity.orderDateTimeStart = undefined;
      this.filterTemplateEntity.orderDateTimeEnd = undefined;
      this.filterTemplateEntity.shippingDateTimeStart = undefined;
      this.filterTemplateEntity.shippingDateTimeEnd = undefined;
      this.filterTemplateEntity.finishedDateTimeStart = undefined;
      this.filterTemplateEntity.finishedDateTimeEnd = undefined;
      this.filterTemplateEntity.orderCode = "";
    },

    //依篩選查詢
    async ApplyFilterAndSearch() {
      this.filterEntity.status = this.filterTemplateEntity.status;
      this.filterEntity.lowestPrice = this.filterTemplateEntity.lowestPrice;
      this.filterEntity.highestPrice = this.filterTemplateEntity.highestPrice;
      this.filterEntity.orderDateTimeStart =
        this.filterTemplateEntity.orderDateTimeStart;
      this.filterEntity.orderDateTimeEnd =
        this.filterTemplateEntity.orderDateTimeEnd;
      this.filterEntity.shippingDateTimeStart =
        this.filterTemplateEntity.shippingDateTimeStart;
      this.filterEntity.shippingDateTimeEnd =
        this.filterTemplateEntity.shippingDateTimeEnd;
      this.filterEntity.finishedDateTimeStart =
        this.filterTemplateEntity.finishedDateTimeStart;
      this.filterEntity.finishedDateTimeEnd =
        this.filterTemplateEntity.finishedDateTimeEnd;
      this.filterEntity.orderCode = this.filterTemplateEntity.orderCode;

      this.ResetSearchCriteriaAndResult();

      await this.Search();
      this.isOpenFilter = false;
    },

    //選擇排序並查詢
    async ApplyOrderByAndSearch() {
      this.filterEntity.orderBy = this.orderTemplateEntity;
      this.ResetSearchCriteriaAndResult();
      await this.Search();
      this.isOpenOrder = false;
    },

    //Reset 篩選條件
    ResetTemplateAndCloseFilter() {
      this.filterTemplateEntity.status = "";
      this.filterTemplateEntity.lowestPrice = undefined;
      this.filterTemplateEntity.highestPrice = undefined;
      this.filterTemplateEntity.orderDateTimeStart = undefined;
      this.filterTemplateEntity.orderDateTimeEnd = undefined;
      this.filterTemplateEntity.shippingDateTimeStart = undefined;
      this.filterTemplateEntity.shippingDateTimeEnd = undefined;
      this.filterTemplateEntity.finishedDateTimeStart = undefined;
      this.filterTemplateEntity.finishedDateTimeEnd = undefined;
      this.filterTemplateEntity.orderCode = "";

      this.isOpenFilter = false;
    },

    //Reset 排序條件
    ResetTemplateAndCloseOrder() {
      this.orderTemplateEntity = "OrderDateTimeDesc";
      this.isOpenOrder = false;
    },

    //開啟排序選單
    OpenOrder() {
      this.orderTemplateEntity = this.filterEntity.orderBy;
      this.isOpenOrder = true;
    },

    async CancelOrder(id: number) {
      const loading = ElLoading.service({
        lock: true,
        text: "取消訂單",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const result = await CancelOrder({ id: id } as IdEntity);

      loading.close();

      if (result.status == 400 && result.data.status == "Error") {
        Toast({
          message: result.data.errorMessage,
          icon: "fail",
          duration: 2000,
          className: "vant-toast-custom",
        });
      } else if (FailAndNeedHandle(result)) {
        Toast({
          message: "取消失敗",
          icon: "fail",
          duration: 1000,
          className: "vant-toast-custom",
        });
      } else {
        Toast({
          message: "取消成功",
          icon: "success",
          duration: 1000,
          className: "vant-toast-custom",
        });
      }
      this.Search();
    },

    //開啟篩選選單
    OpenFilter() {
      this.filterTemplateEntity.status = this.filterEntity.status;
      this.filterTemplateEntity.lowestPrice = this.filterEntity.lowestPrice;
      this.filterTemplateEntity.highestPrice = this.filterEntity.highestPrice;
      this.filterTemplateEntity.orderDateTimeStart =
        this.filterEntity.orderDateTimeStart;
      this.filterTemplateEntity.orderDateTimeEnd =
        this.filterEntity.orderDateTimeEnd;
      this.filterTemplateEntity.shippingDateTimeStart =
        this.filterEntity.shippingDateTimeStart;
      this.filterTemplateEntity.shippingDateTimeEnd =
        this.filterEntity.shippingDateTimeEnd;
      this.filterTemplateEntity.finishedDateTimeStart =
        this.filterEntity.finishedDateTimeStart;
      this.filterTemplateEntity.finishedDateTimeEnd =
        this.filterEntity.finishedDateTimeEnd;
      this.filterTemplateEntity.orderCode = this.filterEntity.orderCode;

      this.isOpenFilter = true;
    },

    IsAllowedCancel(item: OrderListEntity): boolean {
      const now = new Date();

      if (item.status != "Create") {
        return true;
      }

      if (item.orderDateTime == undefined) {
        return true;
      }

      return (now.getTime() - item.orderDateTime.getTime()) / (1000 * 60) > 30
        ? true
        : false;
    },

    onConfirm(status: CodeValuePairEntity) {
      this.filterTemplateEntity.status = status.key;
      this.showPicker = false;
    },

    OrderStatusDesc(status: string) {
      const result = this.orderStatusTypeList.find((m) => m.key == status);
      return result == undefined ? "" : result.value;
    },

    DateTimeFormat(date: Date) {
      if (date == undefined || date == null) {
        return "";
      }

      return format(date, "yyyy/MM/dd");
    },

    TransPrice(value: string) {
      if (value == "****") {
        return value;
      }

      if (isNaN(value)) {
        return "";
      }

      var formatter = new Intl.NumberFormat("zh-Hant", {
        maximumFractionDigits: 2,
      });

      return formatter.format(parseInt(value));
    },

    ResetSearchCriteriaAndResult() {
      this.orderList = [];
      this.currentPage = 0;
    },

    async Reproduce(val: number) {
      const loading = ElLoading.service({
        lock: true,
        text: "複製品項中",
        background: "rgba(0, 0, 0, 0.7)",
      });

      var result = await ReproduceByOrderId({ id: val } as IdEntity);

      loading.close();

      if (FailAndNeedHandle(result)) {
        console.log("error");
      } else {
        this.storePersistStore.setCartQty(result.data.data.quantity);
      }
    },

    GetTypeTagColor(type: string) {
      switch (type) {
        case TypeEnum.DiscountWhenFillPriceCondition:
          return "#FF0000";
        case TypeEnum.DiscountWhenFillQtyCondition:
          return "#F55E3C";
        case TypeEnum.FreeGiftWhenFillQtyCondition:
          return "#FF8F8F";
        case TypeEnum.NthSpecialPrice:
          return "#00A7CC";
        case TypeEnum.FreeShippingWhenFillPrice:
          return "#8FCE00";
        default:
          return "#FFFFFF";
      }
    },
    /**
     * 回傳當前使用者是否有權限操作點數功能
     */
    IsUserAllowPointOperation(): boolean {
      return this.storePersistStore.isAllowPointOperation();
    },
    DoGetAsset(path: string): string {
      return GetAsset(path);
    }
  },

  computed: {
    ...mapStores(useStorePersistStore),

    orderWording(): string {
      if (
        this.filterEntity.orderBy === "OrderDateTime" ||
        this.filterEntity.orderBy === "OrderDateTimeDesc"
      ) {
        return "排序(訂購日期)";
      }

      if (
        this.filterEntity.orderBy === "ShippingDateTime" ||
        this.filterEntity.orderBy === "ShippingDateTimeDesc"
      ) {
        return "排序(出貨日期)";
      }

      if (
        this.filterEntity.orderBy === "FinishedDateTime" ||
        this.filterEntity.orderBy === "FinishedDateTimeDesc"
      ) {
        return "排序(結單日期)";
      }

      if (
        this.filterEntity.orderBy === "TotalPrice" ||
        this.filterEntity.orderBy === "TotalPriceDesc"
      ) {
        return "排序(總金額)";
      }

      return "排序";
    },

    filterWording(): string {
      let result = "篩選";

      let filterCount = 0;
      if (
        this.filterEntity.lowestPrice != undefined ||
        this.filterEntity.highestPrice != undefined
      ) {
        filterCount++;
      }

      if (
        this.filterEntity.orderDateTimeStart != undefined ||
        this.filterEntity.orderDateTimeEnd != undefined
      ) {
        filterCount++;
      }

      if (
        this.filterEntity.shippingDateTimeStart != undefined ||
        this.filterEntity.shippingDateTimeEnd != undefined
      ) {
        filterCount++;
      }

      if (
        this.filterEntity.finishedDateTimeStart != undefined ||
        this.filterEntity.finishedDateTimeEnd != undefined
      ) {
        filterCount++;
      }

      if (this.filterEntity.status != "") {
        filterCount++;
      }

      if (this.filterEntity.orderCode != "") {
        filterCount++;
      }

      if (filterCount != 0) {
        result = result + "(" + filterCount + ")";
      }

      return result;
    },

    formatter(): string {
      const result = this.orderStatusTypeList.find(
        (m) => m.key == this.filterTemplateEntity.status
      );

      return result == undefined ? "" : result.value;
    },

    cartPointItemList(): OrderSlaveDetailEntity[] {
      if (this.orderDetail.items == null) {
        return [];
      }

      let result = _.chain(this.orderDetail.items)
        .filter("isUsingPoint")
        .orderBy("productId")
        .value();

      return result;
    },

    giftItemList(): OrderSlaveDetailEntity[] {
      if (this.orderDetail.items == null) {
        return [];
      }

      let result = _.chain(this.orderDetail.items)
        .filter("isGift")
        .orderBy("productId")
        .value();

      return result;
    }
  },

  async mounted() {
    this.isInit = true;
    this.Search();
  },
  watch: {
    isFreeze() {
      if (this.isFreeze) {
        (document.querySelector("body") as any).classList.add("freeze");
        (document.querySelector("html") as any).classList.add("freeze-html");
        (document.querySelector(".el-main") as any).style.overflow = "hidden";
      } else {
        (document.querySelector("body") as any).classList.remove("freeze");
        (document.querySelector("html") as any).classList.remove("freeze-html");
        (document.querySelector(".el-main") as any).style.overflow = "auto";
      }
    },
  },
});
