import { ApiResponseEntity, SearchCriteriaEntity, SearchResultEntity } from "@/models/ApiModels";
import { OrderSearchEntity } from "@/models/stores/OrderModels";
import { IdEntity } from "@/models/commons/CommonModels";
import { OrderDetailEntity, OrderListEntity } from "@/models/stores/OrderModels";
import { ShoppingCartEntity, ShoppingCartQtyEntity } from "@/models/stores/ShoppingCartModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService"

const prefix = "/api/store"

export async function CreateOrder(params: ShoppingCartEntity): Promise<AxiosResponse<ApiResponseEntity<ShoppingCartQtyEntity>, any>> {

    const url = prefix + "/Order/CreateOrder";

    const result = await Post<ShoppingCartEntity, ShoppingCartQtyEntity>(url, params);

    return result;
}

export async function Search(params: SearchCriteriaEntity<OrderSearchEntity>): Promise<AxiosResponse<ApiResponseEntity<SearchResultEntity<OrderListEntity>>, any>> {

    const url = prefix + "/Order/Search";

    const result = await Post<SearchCriteriaEntity<OrderSearchEntity>, SearchResultEntity<OrderListEntity>>(url, params);

    return result;
}

export async function GetById(params: IdEntity): Promise<AxiosResponse<ApiResponseEntity<OrderDetailEntity>, any>> {

    const url = prefix + "/Order/GetById";

    const result = await Post<IdEntity, OrderDetailEntity>(url, params);

    return result;
}

export async function CancelOrder(params: IdEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

    const url = prefix + "/Order/CancelOrder";

    const result = await Post<IdEntity, object>(url, params);

    return result;
}
