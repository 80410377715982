<template>
   <div class="tip-box">
    <div class="label">
      <img class="icon" :src="icon"> 
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.tip-box {
  background-color: var(--el-color-primary-light-3);
  color: var(--el-color-primary);
  height: 100%;
  padding: 5px 0;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.label {
  padding: 0 5px;
}

.icon {
  width: 20px;
}
</style>

<script>


export default {
  name: "TipBox",
  props: ['icon'],
  setup() {
    return { };
  },
};
</script>
