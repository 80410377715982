
import { defineComponent } from "vue";
import { ElNotification, ElMessageBox } from "element-plus";
import ElCurrencyInput from "../../components/commons/ElCurrencyInput.vue";
import RegexPattern from "../../commons/RegexPattern";
import { TransformNumber } from "../../commons/FormatHelper";
import { v4 as uuidv4 } from "uuid";
import {
  ExternalBusinessCreateEntity,
  ExternalBusinessUpdateEntity,
  ExternalBusinessAndUserCreateEntity,
ExternalBusinessAndUserUpdateEntity
} from "../../models/backstages/ExternalBusinessModel";
import {
  ExternalUserCreateEntity,
  ExternalUserUpdateEntity,
  ExternalUserResponseEntity,
  ExternalUserCheckDuplicateEntity,
  UnbindLineUserRequestEntity
} from "../../models/backstages/ExternalUserModels";
import { DefinitionSearchEntity } from "../../models/commons/DefinitionModels";
import {
  GetExternalBusinessById,
  CreateExternalBusinessAndUser,
  UpdateExternalBusinessAndUser,
} from "../../services/backstages/ExternalBusinessService";
import {
  GetListByExternalBusinessId,
  CheckExternalUserAccountDuplicates,
  UnbindLineUser
} from "../../services/backstages/ExternalUserService";
import { GetInternalUserDropdownList } from "../../services/backstages/InternalUserService";
import { GetDefinitionDropdownList } from "../../services/DefinitionService";
import { GetExternalRoleDropdownList } from "../../services/backstages/ExternalRoleService";
import { FailAndNeedHandle } from "../../services/commons/CommonService";
import { FormatDate } from "../../store/commons/FormatHelper"

export default defineComponent({
  name: "ExternalBusinessInfoView",
  components: {
    ElCurrencyInput,
  },
  props: ["mode"],
  data() {
    return {
      title: (this.mode == "Create" ? "新增" : "編輯") + "客戶",
      status: "Active",
      loadings: {
        externalBusiness: false,
        externalUsers: false,
        createExternalBusiness: false,
        createExternalUser: false,
        externalBusinessDropdownList: false,
        externalUserDropdownList: false,
        internalUserDropdownList: false,
        externalRoleDropdownList: false,
      },

      labelPosition: "right",
      id: +this.$route.params.id || null!,

      /**
       * External Business
       */
      externalBusinessCreateEntity: {
        defaultSkuDiscountPercentage: 1,
        priceConvertPointPercentage: 0,
        pointAnnualIncreaseStartDate: null,
        pointAnnualIncreasePercentage: 0,
        pointAnnualIncreasePercentageMax: 0
      } as ExternalBusinessCreateEntity,

      externalBusinessUpdateEntity: {} as ExternalBusinessUpdateEntity,

      /**
       * External User
       */
      externalUserMode: "Create",
      externalUserCreateEntity: {} as ExternalUserCreateEntity,
      externalUserCreateList: [] as ExternalUserCreateEntity[],
      externalUserResponseList: [] as ExternalUserResponseEntity[],
      externalUserUpdateList: [] as ExternalUserUpdateEntity[],

      currentExternalUserId: 0,
      currentExternalUserPointBalance: 0,
      currentExternalUserRowUuid: "",
      currentExternalUserRowIndex: 0,

      externalUserFormVisible: false,
      unbindLineUserBtnDisabled: false,

      /**
       * Point settings
       */
      isUseAnnualPointSetting: false,

      /**
       * Dropdown list
       */
      externalBusinessStatusDropdownList: [] as any,
      externalUserStatusDropdownList: [] as any,
      internalUserDropdownList: [] as any,
      externalRoleDropdownList: [] as any,

      /**
       * Validation rules
       */
      externalBusinessValidationRules: {
        status: [{ required: true, message: "請選擇狀態", trigger: "change" }],
        name: [{ required: true, message: "請輸入客戶名稱", trigger: "blur" }],
        shortName: [{ message: "請輸入客戶簡稱", trigger: "blur" }],
        cellPhone: [
          {
            message: "電話格式錯誤",
            trigger: "blur",
            pattern: RegexPattern.phone,
          },
        ],
        internalUserId: [
          { required: true, message: "請選擇負責業務", trigger: "change" },
        ],
        shippingAddress: [
          { required: true, message: "請輸入出貨地址", trigger: "blur" },
        ],
        outerSysCode: [{ message: "請輸入外部系統代碼", trigger: "blur" }],
      },
      externalBizSettingValidationRules: {
        priceConvertPointPercentage: [{ required: true, message: "請輸入數字", trigger: "blur" }],
        pointAnnualIncreasePercentage: [
          { required: true, message: "請輸入數字", trigger: "blur" },
          { type: "integer",
            min: 0,
            max: 100,
            message: "請輸入數字 0~100",
            trigger: "blur",
            transform: TransformNumber }],
        pointAnnualIncreasePercentageMax: [
          { required: true, message: "請輸入數字", trigger: "blur" },
          { type: "integer",
            min: 0,
            max: 200,
            message: "請輸入數字 0~200",
            trigger: "blur",
            transform: TransformNumber }],
      },
      externalUserValidationRules: {
        name: [
          { required: true, message: "請輸入聯絡人名稱", trigger: "blur" },
        ],
        email: [{ type: "email", message: "請輸入 Email", trigger: "blur" }],
        phone: [
          {
            message: "電話格式錯誤",
            trigger: "blur",
            pattern: RegexPattern.phone,
          },
        ],
        account: [{ required: true, message: "請輸入帳號", trigger: "blur" }],
        password: [
          {
            message: "請輸入 6 ~ 20 字元大小寫英數字以及特殊符號 ~`!@#$%^&*+=-", 
            trigger: "blur",
            pattern: RegexPattern.password
          }],
        status: [{ required: true, message: "請選擇狀態", trigger: "change" }],
        roleId: [{ required: true, message: "請選擇權限", trigger: "change" }],
      }
    };
  },
  created() {
    this.LoadData();
  },
  computed: {
    allLoading() {
      return !Object.values(this.loadings).every((m: boolean) => m == false);
    },
    isCreateMode() {
      return this.mode == "Create";
    },
    saveBtnLoading() {
      return (
        this.loadings.createExternalBusiness || this.loadings.createExternalUser
      );
    },
    isCreateExternalUserMode() {
      return this.externalUserMode == "Create";
    },
    externalUserDialogTitle() {
      return this.externalUserMode ? "新增使用者" : "編輯使用者";
    },
  },
  methods: {
    FormatDate: FormatDate,
    ClearData() {
      this.ClearExternalBusinessData();
      this.ClearExternalUserData();
      
      this.isUseAnnualPointSetting = false;
    },
    LoadData() {
      this.ClearData();
      this.LoadDropdownList();

      switch (this.mode) {
        case "Create":
          break;
        case "Edit":
          this.LoadExternalBusinessData(this.id);
          this.LoadExternalUserList(this.id);
          break;
        default:
          console.error("Unknown mode!");
      }
    },
    LoadDropdownList() {
      let searchEntity: DefinitionSearchEntity = {
        tableName: "external_business",
        columnName: "status",
      };

      GetDefinitionDropdownList(searchEntity)
        .then((result) => {
          this.externalBusinessStatusDropdownList = result.data.data;
        })
        .catch((err) => {
          ElNotification({
            title: "客戶狀態清單載入失敗",
            message: err,
            duration: 3000,
            type: "error",
          });
        });

      // externalUserStatusDropdownList
      searchEntity = {
        tableName: "external_user",
        columnName: "status",
      };

      GetDefinitionDropdownList(searchEntity)
        .then((result) => {
          this.externalUserStatusDropdownList = result.data.data;
        })
        .catch((err) => {
          ElNotification({
            title: "帳號狀態清單載入失敗",
            message: err,
            duration: 3000,
            type: "error",
          });
        });

      this.LoadInternalUserDropdownList();
      this.LoadExternalRoleDropdownList();
    },
    async LoadExternalBusinessData(externalBusinessId: number) {
      this.loadings.externalBusiness = true;

      GetExternalBusinessById(externalBusinessId)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElMessageBox.alert('查無客戶資料', '編輯客戶', { confirmButtonText: '確認' })
          } else {
            let extBusiness = result.data.data;
            this.externalBusinessCreateEntity.outerSysCode =
              extBusiness.outerSysCode;
            this.externalBusinessCreateEntity.name = extBusiness.name;
            this.externalBusinessCreateEntity.shortName = extBusiness.shortName;
            this.externalBusinessCreateEntity.cellPhone = extBusiness.cellPhone;
            this.externalBusinessCreateEntity.internalUserId =
              extBusiness.internalUserId;
            this.externalBusinessCreateEntity.shippingAddress =
              extBusiness.shippingAddress;
            this.externalBusinessCreateEntity.note = extBusiness.note;
            this.externalBusinessCreateEntity.status = extBusiness.status;
            this.externalBusinessCreateEntity.firstTimeOfTheDayLowerLimit =
              extBusiness.firstTimeOfTheDayLowerLimit;
            this.externalBusinessCreateEntity.defaultSkuDiscountPercentage =
              extBusiness.defaultSkuDiscountPercentage;
            this.externalBusinessCreateEntity.priceConvertPointPercentage =
              extBusiness.priceConvertPointPercentage;
            this.externalBusinessCreateEntity.firstOrderDate =
              extBusiness.firstOrderDate;

            if (extBusiness.pointAnnualIncreaseStartDate != null 
              || (extBusiness.pointAnnualIncreasePercentage != null && extBusiness.pointAnnualIncreasePercentage > 0)
              || (extBusiness.pointAnnualIncreasePercentageMax != null && extBusiness.pointAnnualIncreasePercentageMax > 0))
              {
                this.isUseAnnualPointSetting = true;
                this.externalBusinessCreateEntity.pointAnnualIncreaseStartDate =
                  extBusiness.pointAnnualIncreaseStartDate;
                this.externalBusinessCreateEntity.pointAnnualIncreasePercentage =
                  extBusiness.pointAnnualIncreasePercentage;
                this.externalBusinessCreateEntity.pointAnnualIncreasePercentageMax =
                  extBusiness.pointAnnualIncreasePercentageMax;
              }

            this.loadings.externalBusiness = false;
          }
        })
        .catch((err) => {
          console.error(err);
        })
    },
    /**
     * 載入客戶使用者帳號清單
     */
    async LoadExternalUserList(externalBusinessId: number) {
      this.loadings.externalUsers = true;
      await GetListByExternalBusinessId(externalBusinessId)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "客戶使用者帳號載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            this.externalUserResponseList = result.data.data;
            this.externalUserResponseList.forEach((m) => (m.uuid = uuidv4()));
          }
        })
        .catch((err) => {
          throw new Error(err);
        })
        .finally(() => {
          this.loadings.externalUsers = false;
        });
    },
    OpenCreateExternalUserWindow() {
      this.externalUserMode = "Create";
      this.externalUserFormVisible = true;
      this.ClearExternalUserData();
    },
    OpenUpdateExternalUserWindow() {
      this.externalUserMode = "Update";
      this.externalUserFormVisible = true;
      this.ClearExternalUserData();
    },
    ShowErrorMsg(message: string) {
      ElNotification({
        title: "客戶新增失敗",
        message: message,
        duration: 3000,
        type: "error",
      });
    },
    async FormValidation(refs: any) {
      return refs.validate((valid: any) => {
        if (valid == false) {
          return valid;
        }
      });
    },
    /**
     * 建立客戶 & 帳號資料
     */
    async CreateExternalBusinessAndUser() {
      if (!await this.CheckFormData()) {
        return;
      }

      this.loadings.createExternalBusiness = true;

      if (this.isUseAnnualPointSetting == false) {
        this.externalBusinessCreateEntity.pointAnnualIncreaseStartDate = null;
        this.externalBusinessCreateEntity.pointAnnualIncreasePercentage = null;
        this.externalBusinessCreateEntity.pointAnnualIncreasePercentageMax = null;
      }

      let extBizAndUserCreateEntity = {
        externalBusiness: this.externalBusinessCreateEntity,
        externalUsers: this.externalUserResponseList.map((element) => {
              return {
                externalBusinessId: 0, // Initial
                account: element.account,
                password: element.password,
                name: element.name,
                email: element.email,
                status: element.status,
                phone: element.phone,
                roleId: element.roleId,
              } as ExternalUserCreateEntity;
            })
      } as ExternalBusinessAndUserCreateEntity

      let result = await CreateExternalBusinessAndUser(extBizAndUserCreateEntity)

      if (FailAndNeedHandle(result)) {
        ElNotification({
          title: "新增失敗",
          message: "新增客戶資料失敗",
          duration: 3000,
          type: "error",
        });
      } else {
        ElNotification({
          title: "新增成功",
          message: "新增客戶資料成功",
          duration: 3000,
          type: "success",
        });

        let createdExtBusinessId = result.data.data;
        
        // redirect to edit page
        this.$router.push({
          name: "ExternalBusinessEdit",
          params: { id: createdExtBusinessId },
        });
      }

      this.loadings.createExternalBusiness = false;
    },
    /**
     * 資料檢核
     * true => pass
     * false => not pass
     */
    async CheckFormData() {
      let isExternalBizFormValid, isExternalBizSettingFormValid = false;
      
      await this.FormValidation(this.$refs.externalBusinessFormRef).then(
        (result) => {
          isExternalBizFormValid = result;
        }
      );

      await this.FormValidation(this.$refs.externalBusinessSettingFormRef).then(
        (result) => {
          isExternalBizSettingFormValid = result;
        }
      );

      if (!isExternalBizFormValid || !isExternalBizSettingFormValid) {
        ElNotification({
          title: "新增/編輯客戶",
          message: "尚有必填欄位未填寫，請確認!",
          duration: 3000,
          type: "error",
        });
        return false;
      }
      
      // 目前回饋比例 (%) 不得大於 回饋最大比例 (%)
      if (this.isUseAnnualPointSetting == true
      && this.externalBusinessCreateEntity.pointAnnualIncreasePercentageMax != null 
      && this.externalBusinessCreateEntity.priceConvertPointPercentage > this.externalBusinessCreateEntity.pointAnnualIncreasePercentageMax) {
        ElNotification({
          title: "點數設定",
          message: "目前回饋比例 (%) 不得大於 回饋最大比例 (%)",
          duration: 3000,
          type: "error",
        });
        return false;
      }

      if (this.externalUserResponseList == undefined || this.externalUserResponseList.length == 0) {
        ElNotification({
          title: "使用者帳號",
          message: "請設定至少 1 組帳號",
          duration: 3000,
          type: "error",
        });
        return false;
      }

      return true;
    },
    /**
     * 更新客戶 & 帳號資料
     */
    async UpdateExternalBusinessAndUser() {
      if (!await this.CheckFormData()) {
        return;
      }

      this.loadings.createExternalBusiness = true;

      //// Prepare external business data to create
      this.externalBusinessUpdateEntity.id = this.id;
      this.externalBusinessUpdateEntity.outerSysCode =
        this.externalBusinessCreateEntity.outerSysCode;
      this.externalBusinessUpdateEntity.name =
        this.externalBusinessCreateEntity.name;
      this.externalBusinessUpdateEntity.shortName =
        this.externalBusinessCreateEntity.shortName;
      this.externalBusinessUpdateEntity.cellPhone =
        this.externalBusinessCreateEntity.cellPhone;
      this.externalBusinessUpdateEntity.internalUserId =
        this.externalBusinessCreateEntity.internalUserId;
      this.externalBusinessUpdateEntity.shippingAddress =
        this.externalBusinessCreateEntity.shippingAddress;
      this.externalBusinessUpdateEntity.note =
        this.externalBusinessCreateEntity.note;
      this.externalBusinessUpdateEntity.status =
        this.externalBusinessCreateEntity.status;
      this.externalBusinessUpdateEntity.firstTimeOfTheDayLowerLimit =
        this.externalBusinessCreateEntity.firstTimeOfTheDayLowerLimit;
      this.externalBusinessUpdateEntity.defaultSkuDiscountPercentage =
        this.externalBusinessCreateEntity.defaultSkuDiscountPercentage;
      this.externalBusinessUpdateEntity.priceConvertPointPercentage =
        this.externalBusinessCreateEntity.priceConvertPointPercentage;

      if (this.isUseAnnualPointSetting == false) {
        this.externalBusinessCreateEntity.pointAnnualIncreaseStartDate = null;
        this.externalBusinessCreateEntity.pointAnnualIncreasePercentage = null;
        this.externalBusinessCreateEntity.pointAnnualIncreasePercentageMax = null;
      }
      else
      {
        this.externalBusinessUpdateEntity.pointAnnualIncreaseStartDate =
          this.externalBusinessCreateEntity.pointAnnualIncreaseStartDate;
        this.externalBusinessUpdateEntity.pointAnnualIncreasePercentage =
          this.externalBusinessCreateEntity.pointAnnualIncreasePercentage;
        this.externalBusinessUpdateEntity.pointAnnualIncreasePercentageMax =
          this.externalBusinessCreateEntity.pointAnnualIncreasePercentageMax;
      }

      this.externalUserCreateList = this.externalUserResponseList
        .filter((element) => element.id == 0 || element.id == null)
        .map((element) => {
          return {
            externalBusinessId: this.id,
            account: element.account,
            password: element.password,
            name: element.name || "",
            email: element.email || "",
            status: element.status,
            phone: element.phone || "",
            roleId: element.roleId,
          } as ExternalUserCreateEntity;
        });
        
      this.externalUserUpdateList = this.externalUserResponseList
        .filter((element) => element.id != 0)
        .map((element) => {
          return {
            id: element.id,
            account: element.account,
            password: element.password,
            name: element.name || "",
            email: element.email || "",
            status: element.status,
            phone: element.phone || "",
            roleId: element.roleId,
          } as ExternalUserUpdateEntity;
        });

      let entity = {
        externalBusiness: this.externalBusinessUpdateEntity,
        newExternalUsers: this.externalUserCreateList,
        oldExternalUsers: this.externalUserUpdateList
      } as ExternalBusinessAndUserUpdateEntity;

      let result = await UpdateExternalBusinessAndUser(entity);

      if (FailAndNeedHandle(result)) {
        ElNotification({
          title: "更新失敗",
          message: "更新客戶資料失敗",
          duration: 3000,
          type: "error",
        });
      } else {
        ElNotification({
          title: "更新成功",
          message: "更新客戶資料成功",
          duration: 3000,
          type: "success",
        });

        this.LoadData();
      }

      this.loadings.createExternalBusiness = false;
    },
    async CreateExternalUserInfo() {
      let isValid = false;
      await this.FormValidation(this.$refs.externalUserFormRef).then(
        (result) => {
          isValid = result;
        }
      );

      if (!isValid) {
        return;
      }

      if (await this.CheckAccountDuplicates()) {
        ElNotification({
          title: "帳號建立",
          message: "帳號重複，請確認",
          duration: 3000,
          type: "error",
        });
        return;
      }

      this.loadings.createExternalUser = true;
      let externalUser: ExternalUserResponseEntity = {
        uuid: uuidv4(),
        id: 0,
        internalBusinessId: null!,
        firstLoginDatetime: null!,
        externalBusinessId: this.externalUserCreateEntity.externalBusinessId,
        name: this.externalUserCreateEntity.name,
        email: this.externalUserCreateEntity.email,
        phone: this.externalUserCreateEntity.phone,
        account: this.externalUserCreateEntity.account,
        password: this.externalUserCreateEntity.password,
        status: this.externalUserCreateEntity.status,
        roleId: this.externalUserCreateEntity.roleId,
        accountType: this.externalUserCreateEntity.accountType,
        lineUserId: '',
        pointBalance: 0
      };

      this.externalUserResponseList.push(externalUser);

      this.externalUserFormVisible = false;
      this.loadings.createExternalUser = false;
      this.ClearExternalUserData();
    },
    async UpdateExternalUserInfo() {
      let isValid = false;
      await this.FormValidation(this.$refs.externalUserFormRef).then(
        (result) => {
          isValid = result;
        }
      );

      if (!isValid) {
        return;
      }

      if (await this.CheckAccountDuplicates()) {
        ElNotification({
          title: "帳號建立",
          message: "帳號重複，請確認",
          duration: 3000,
          type: "error",
        });
        return;
      }

      let externalUser = this.externalUserResponseList.at(
        this.currentExternalUserRowIndex
      ) as ExternalUserResponseEntity;
      externalUser.id = this.currentExternalUserId;
      externalUser.externalBusinessId =
        this.externalUserCreateEntity.externalBusinessId;
      externalUser.name = this.externalUserCreateEntity.name;
      externalUser.email = this.externalUserCreateEntity.email;
      externalUser.phone = this.externalUserCreateEntity.phone;
      externalUser.account = this.externalUserCreateEntity.account;
      externalUser.password = this.externalUserCreateEntity.password;
      externalUser.status = this.externalUserCreateEntity.status;
      externalUser.roleId = this.externalUserCreateEntity.roleId;

      this.ClearExternalUserData();
      this.externalUserFormVisible = false;
    },
    async CheckAccountDuplicates() {
      if (
        this.externalUserResponseList.some(
          (m) =>
            m.account == this.externalUserCreateEntity.account &&
            m.uuid != this.currentExternalUserRowUuid
        )
      ) {
        return true;
      }

      let entity = {
        externalUserId: this.currentExternalUserId,
        account: this.externalUserCreateEntity.account,
      } as ExternalUserCheckDuplicateEntity;

      let result = await CheckExternalUserAccountDuplicates(entity);

      if (result.status != 200) {
        ElNotification({
          title: "帳號檢查",
          message: "帳號檢查錯誤，請洽系統管理員",
          duration: 3000,
          type: "error",
        });

        throw new Error();
      } else if (result.data.data == true) {
        return true;
      }

      return false;
    },
    ClearExternalUserData() {
      this.externalUserCreateEntity = {
        externalBusinessId: null!,
        account: "",
        password: "",
        name: "",
        email: "",
        status: "",
        phone: "",
        roleId: undefined,
        accountType: "",
        lineUserId: ""
      };
      this.currentExternalUserRowIndex = 0;
      this.currentExternalUserRowUuid = "";
      this.currentExternalUserId = 0;
      this.currentExternalUserPointBalance = 0;
    },
    ClearExternalBusinessData() {
      this.externalBusinessCreateEntity = {
        outerSysCode: "",
        name: "",
        shortName: "",
        cellPhone: "",
        internalUserId: null!,
        shippingAddress: "",
        note: "",
        status: "",
        firstOrderDate: null,
        firstTimeOfTheDayLowerLimit: 3000,
        defaultSkuDiscountPercentage: 1,
        priceConvertPointPercentage: 0,
        pointAnnualIncreaseStartDate: null,
        pointAnnualIncreasePercentage: 0,
        pointAnnualIncreasePercentageMax: 0
      };
    },
    HandleUserEdit(index: any, row: any) {
      this.OpenUpdateExternalUserWindow();

      this.currentExternalUserRowIndex = index;
      this.currentExternalUserRowUuid = row.uuid;
      this.currentExternalUserId = row.id;
      this.currentExternalUserPointBalance = row.pointBalance;
      this.externalUserCreateEntity.name = row.name;
      this.externalUserCreateEntity.email = row.email;
      this.externalUserCreateEntity.phone = row.phone;
      this.externalUserCreateEntity.account = row.account;
      this.externalUserCreateEntity.password = row.password;
      this.externalUserCreateEntity.roleId = row.roleId;
      this.externalUserCreateEntity.status = row.status.toString();
      this.externalUserCreateEntity.lineUserId = row.lineUserId;
      this.unbindLineUserBtnDisabled = false;
    },
    LoadInternalUserDropdownList() {
      this.loadings.internalUserDropdownList = true;
      GetInternalUserDropdownList()
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "負責業務清單載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            this.internalUserDropdownList = result.data.data;
          }
        })
        .catch((err: any) => {
          console.error(err);
        })
        .finally(() => {
          this.loadings.internalUserDropdownList = false;
        });
    },
    LoadExternalRoleDropdownList() {
      this.loadings.externalRoleDropdownList = true;
      GetExternalRoleDropdownList()
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "角色選單載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            this.externalRoleDropdownList = result.data.data;
          }
        })
        .catch((err: any) => {
          console.error(err);
        })
        .finally(() => {
          this.loadings.externalRoleDropdownList = false;
        });
    },

    OpenExtBizSkuSettingList() {
      const link = this.$router.resolve({
        path: "/BackStage/ExternalBizSkuSetting/List/" + this.id,
      });
      window.open(link.href);
    },
    DoUnbindLineUser(extUserId: number) {
      let request = {
        externalBusinessId: this.id,
        externalUserId: extUserId
      } as UnbindLineUserRequestEntity
      
      UnbindLineUser(request)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "LINE 解除綁定失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          }
          else {
            ElNotification({
              title: "LINE 解除綁定",
              message: "LINE 解除綁定成功",
              duration: 3000,
              type: "success",
            });

            this.unbindLineUserBtnDisabled = true;
          }
        })
        .catch((err: any) => {
          console.error(err);
        })
    },
    HandleUnbindLineUser(extUserId: number) {
      ElMessageBox.confirm(
        '確定要解除 LINE 綁定嗎？',
        '',
        {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          this.DoUnbindLineUser(extUserId)
        })
    }
  },
});
