export enum SourceTypeEnum {
    Activity = "Activity",
    Contract = "Contract"
}

export enum TypeEnum {
    DiscountWhenFillPriceCondition = "DiscountWhenFillPriceCondition",
    DiscountWhenFillQtyCondition = "DiscountWhenFillQtyCondition",
    FreeGiftWhenFillQtyCondition = "FreeGiftWhenFillQtyCondition",
    NthSpecialPrice = "NthSpecialPrice",
    FreeShippingWhenFillPrice = "FreeShippingWhenFillPrice"
}