import { ApiResponseEntity } from "@/models/ApiModels";
import { AxiosResponse } from "axios";
import { DropdownEntity } from "@/models/commons/CommonModels";
import { 
  ExternalUserResponseEntity,
  ExternalUserCreateEntity,
  ExternalUserUpdateEntity,
  ExternalUserCheckDuplicateEntity,
  UnbindLineUserRequestEntity
} from "@/models/backstages/ExternalUserModels";
import {
  ExternalUserSearchEntity
} from "../../models/backstages/ExternalUserModels";
import { ExcelExportRequestEntity } from "@/models/commons/CommonModels";
import { useBackStagePersistStore } from "@/store/BackStagePersistStore";
import { Post } from "../commons/CommonService"

/** 
 * 取得外部帳號下拉清單
 * @param externalBuinessId 客戶序號
 * @returns 外部帳號下拉清單
 * */ 
export async function GetExternalUserDropdownList(externalBuinessId: number): Promise<AxiosResponse<ApiResponseEntity<Array<DropdownEntity>>, any>> {
  const url = "/api/backstage/ExternalUser/GetExternalUserDropdownList/" + externalBuinessId;
  const result = await Post<object, Array<DropdownEntity>>(url, null!);

  return result;
}

/**
 * 取得外部帳號清單
 * @param externalBuinessId 客戶序號
 * @returns 外部帳號清單
 */
export async function GetListByExternalBusinessId(externalBuinessId: number): Promise<AxiosResponse<ApiResponseEntity<Array<ExternalUserResponseEntity>>, any>> {
  const url = "/api/backstage/ExternalUser/GetListByExternalBusinessId/" + externalBuinessId;
  const result = await Post<object, Array<ExternalUserResponseEntity>>(url, null!);

  return result;
}

/** 
 * 批次建立外部帳號
 * */ 
 export function BatchCreateExternalUser(list: Array<ExternalUserCreateEntity>): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
  const url = "/api/backstage/ExternalUser/BatchCreate";
  const result = Post<Array<ExternalUserCreateEntity>, any>(url, list);

  return result;
}

/** 
 * 批次更新外部帳號
 * */ 
 export function BatchUpdateExternalUser(list: Array<ExternalUserUpdateEntity>): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
  const url = "/api/backstage/ExternalUser/BatchUpdate";
  const result = Post<Array<ExternalUserUpdateEntity>, any>(url, list);

  return result;
}

/**
 * 檢查帳號是否重複
 * @param entity ExternalUserCheckDuplicateEntity
 * @returns True => 有重複; False => 沒重複
 */
export function CheckExternalUserAccountDuplicates(entity: ExternalUserCheckDuplicateEntity): Promise<AxiosResponse<ApiResponseEntity<boolean>, any>> {
  const url = "/api/backstage/ExternalUser/CheckAccountDuplicates";
  const result = Post<ExternalUserCheckDuplicateEntity, boolean>(url, entity);

  return result;
}

/**
 * 根據查詢條件，輸出客戶帳號資料為 Excel Stream
 * @param entity ExcelExportRequestEntity<ExternalUserSearchEntity>
 * @returns Stream
 */
export async function ExportExtUserAsExcel(entity: ExcelExportRequestEntity<ExternalUserSearchEntity>): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const store = useBackStagePersistStore();
  const token = store.jwtToken;
  const config: any = { withCredential: true, responseType: 'blob', headers: { 'Authorization': `Bearer ${token}` } };
  const url = "/api/backstage/ExternalUser/ExportExternalUserAsExcel";
  const result = await Post<object, any>(url, entity, config);

  return result;
}

export async function UnbindLineUser(entity : UnbindLineUserRequestEntity): Promise<AxiosResponse<ApiResponseEntity<null>>> {

  const url = "/api/backstage/ExternalUser/UnbindLineUser";
  const result = await Post<UnbindLineUserRequestEntity, null>(url, entity);

  return result;
}