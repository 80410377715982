import { ApiResponseEntity } from "@/models/ApiModels";
import { AxiosResponse } from "axios";
import { DropdownEntity } from "@/models/commons/CommonModels";
import { Post } from "../commons/CommonService"

/** 
 * 取得 內部用戶 下拉清單
 * */
export async function GetInternalUserDropdownList(): Promise<AxiosResponse<ApiResponseEntity<Array<DropdownEntity>>, any>> {
  const url = "/api/store/InternalUser/GetInternalUserDropdownList";
  const result = await Post<object, Array<DropdownEntity>>(url, null!);

  return result;
}