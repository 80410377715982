
import { defineComponent } from "vue";
import {
  SearchPointHistory,
  IsDailyPointRewardEnabled,
  ClaimDailyPointReward,
} from "../../services/stores/PointHistoryService";
import { Toast } from "vant";
import {
  PointHistoryListEntity,
  PointHistorySearchEntity
} from "../../models/stores/PointHistoryModels";
import { ElLoading } from "element-plus";
import { format, addMonths } from "date-fns";
import { Icon } from "@iconify/vue";
import { Present } from "@element-plus/icons-vue"
import _ from "lodash";
import { FormatNumber } from "../../store/commons/FormatHelper"
import { FailAndNeedHandle } from "@/services/commons/CommonService";

export default defineComponent({
  name: "PointHistoryView",
  components: {
    Icon
  },
  data() {
    return {
      presentIcon: Present,
      loading: false,
      pointBalance: 0,
      pointHistoryList: [] as Array<PointHistoryListEntity>,

      isDailyPointRewardEnabled: false,

      numberFormat: {
        separator: ',',
        precision: 0,
        masked: false,
      },
      dateFilters: [
        { label: '近一個月', value: 'FromLastMonth' },
        { label: '近三個月', value: 'FromLastThreeMonths' },
        { label: '近半年', value: 'FromLastSixMonths' },
      ],
      sortOptions: [
        { label: '由新到舊', value: 'NewToOld' },
        { label: '由舊到新', value: 'OldToNew' },
      ],
      dateFilter: 'FromLastMonth',
      sortOption: 'NewToOld'
    };
  },

  methods: {
    TransNumber: FormatNumber,
    TransDate(d: Date) {
      return format(d, "yyyy/MM/dd");
    },
    async LoadDailyPointReward() {
      let result = await IsDailyPointRewardEnabled()

      if (FailAndNeedHandle(result)) {
        Toast({
          message: '領取點數狀態失敗',
          icon: "fail",
          duration: 2000
        });

      } else {
        this.isDailyPointRewardEnabled = result.data.data;
      }
    },
    async LoadData() {

      this.LoadDailyPointReward()

      const loading = ElLoading.service({
        lock: true,
        text: "",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let searchEntity = {
        createdAfter: addMonths(new Date(), -6),
      } as PointHistorySearchEntity;

      var res = await SearchPointHistory(searchEntity);

      if (FailAndNeedHandle(res)) {
        Toast({
          message: res.data.errorMessage || '點數歷程載入失敗',
          icon: "fail",
          duration: 2000
        });
      } else {
        this.pointBalance = res.data.data.pointBalance;
        this.pointHistoryList = res.data.data.pointHistoryList;
      }

      loading.close();
    },

    async ClaimDailyPointReward() {
      this.loading = true;

      let result = await ClaimDailyPointReward();

      this.loading = false;

      if (FailAndNeedHandle(result)) {
        Toast({
          message: result.data.errorMessage || '領取點數獎勵失敗',
          icon: "fail",
          duration: 3000,
          className: 'vant-toast-custom-wide',
        })

      } else {
        Toast({
          message: '每日獎勵點數領取成功',
          icon: "success",
          duration: 2000
        })

        this.LoadData();
      }
    }
  },

  computed: {
    filterResult(): Array<PointHistoryListEntity> {
      let result = this.pointHistoryList;
      let now = new Date();
      // date filter
      switch (this.dateFilter) {
        case 'FromLastMonth':
          result = _.filter(result, function (m: any) {
            return m.date > addMonths(now, -1);
          });
          break;
        case 'FromLastThreeMonths':
          result = _.filter(result, function (m: any) {
            return m.date > addMonths(now, -3);
          });
          break;
        case 'FromLastSixMonths':
          result = _.filter(result, function (m: any) {
            return m.date > addMonths(now, -6);
          });
          break;
      }

      // sort option
      switch (this.sortOption) {
        case 'NewToOld':
          result = _.orderBy(result, ['date'], ['desc']);
          break;
        case 'OldToNew':
          result = _.orderBy(result, ['date'], ['asc']);
          break;
      }

      return result;
    }
  },
  async mounted() {
    this.LoadData();
  }
});
