import { defineStore } from 'pinia'
import { ref } from "vue";

export const useAdminPersistStore = defineStore('adminPersistStore', () => {
    const jwtToken = ref('');

    const setJWT = (params: string) => {
        jwtToken.value = params;
    }

    const isLogin = (): boolean => {
        return jwtToken.value === '' ? false : true
    }

    return { isLogin, jwtToken, setJWT }
}, { persist: true });
