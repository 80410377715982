<template>
    <el-button class="line-btn" size="large" type="primary">
      LINE 登入/註冊
    </el-button>
</template>

<style scoped>
line,
polyline {
  fill: none;
  stroke: #00c300;
  stroke-width: 6.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.line-btn {
  background-color: var(--line-primary-color);
  color: #FFFFFF;
  border-color: transparent;
}

.line-btn:hover {
  background-color: rgb(from var(--line-primary-color) r g b / 0.9);
  color: #FFFFFF;
  border-color: transparent;
}

.line-btn:active, .line-btn:focus {
  background-color: rgb(from var(--line-primary-color) r g b / 0.7);
  color: #FFFFFF;
  border-color: transparent;
}

.line-btn:disabled {
  background-color: #FFFFFF;
  color: rgb(from #1E1E1E r g b / 0.2);
  border-color: rgb(from #E5E5E5 r g b / 0.6);
}

.el-button.is-disabled {
  background-color: #FFFFFF !important;
  color: rgb(from #1E1E1E r g b / 0.2) !important;
  border-color: rgb(from #E5E5E5 r g b / 0.6) !important;
}
</style>

<script>
export default {
  name: "LineButton",
  setup() {
    return { };
  }
};
</script>
