import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, null, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_el_header, { class: "navbar" }, {
        default: _withCtx(() => [
          _createVNode(_component_Navbar, {
            onToggleSideBar: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSideBar()))
          })
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.isShowLayoutContent()]
      ]),
      _createVNode(_component_el_container, { class: "container-wrapper" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_el_aside, {
            class: "sidebar",
            style: _normalizeStyle({ display: _ctx.isShowSideBar ? 'block' : 'none' })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Sidebar)
            ]),
            _: 1
          }, 8, ["style"]), [
            [_vShow, this.isShowLayoutContent()]
          ]),
          _createVNode(_component_el_main, { class: "main-wrapper" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}