
import { defineComponent } from "vue";
import {
  ExternalUserInfoEntity,
  ExternalUserResetPasswordEntity,
  ExternalUserUpdateEntity
} from "../../models/stores/ExternalUserModels";
import {
  Update,
  GetUserInfo,
  ExternalUserResetPassword,
} from "../../services/stores/ExternalUserService";
import { useStorePersistStore } from "../../store/StorePersistStore";
import { ElLoading, ElMessageBox, ElNotification } from "element-plus";
import { Toast } from "vant";
import { mapStores } from "pinia";
import { FailAndNeedHandle } from "../../services/commons/CommonService";
import { GetInternalUserDropdownList } from "../../services/stores/InternalUserService";

export default defineComponent({
  name: "UserInfoView",
  data() {
    return {
      form: {} as ExternalUserInfoEntity,
      resetPasswordBtnLoading: false,

      resetPasswordEntity: {
        account: "",
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      } as ExternalUserResetPasswordEntity,

      resetPasswordDialogFormVisible: false,

      /**
       * Dropdown list
       */
      internalUserDropdownList: [] as any,
    };
  },

  methods: {
    async Update() {
      let updateEntity = {
        id: this.form.id,
        account: this.form.account,
        name: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        shippingAddress: this.form.shippingAddress,
      } as ExternalUserUpdateEntity;

      const resp = await Update(updateEntity);

      if (FailAndNeedHandle(resp)) {
        Toast({
          message: "更新失敗" + resp.data.errorMessage,
          icon: "fail",
          duration: 500,
          className: "vant-toast-custom",
        });
      } else {
        Toast({
          message: "更新成功",
          icon: "success",
          duration: 500,
          className: "vant-toast-custom",
        });
      }
    },

    LoadInternalUserDropdownList() {
      GetInternalUserDropdownList()
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "負責業務清單載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            this.internalUserDropdownList = result.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    ResetPassword() {
      if (
        this.resetPasswordEntity.oldPassword === null ||
        this.resetPasswordEntity.oldPassword === ""
      ) {
        ElMessageBox.alert("舊密碼不得為空", "重設密碼", {
          confirmButtonText: "確定",
        });
        return;
      } else if (
        this.resetPasswordEntity.newPassword === null ||
        this.resetPasswordEntity.newPassword === ""
      ) {
        ElMessageBox.alert("新密碼不得為空", "重設密碼", {
          customClass: "login-el-message",
          confirmButtonText: "確定",
        });
        return;
      } else if (
        this.resetPasswordEntity.confirmNewPassword === null ||
        this.resetPasswordEntity.confirmNewPassword === ""
      ) {
        ElMessageBox.alert("確認密碼不得為空", "重設密碼", {
          customClass: "login-el-message",
          confirmButtonText: "確定",
        });
        return;
      }

      this.resetPasswordEntity.account = this.form.account;
      this.resetPasswordBtnLoading = true;

      ExternalUserResetPassword(this.resetPasswordEntity)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElMessageBox.alert(
              result.data.errorMessage, 
              "重設密碼", {
              customClass: "login-el-message",
              confirmButtonText: "確定",
            });
          } else {
            ElMessageBox.alert(
              "重設密碼成功", 
              "重設密碼", {
                customClass: "login-el-message",
                confirmButtonText: "確定",
            });
          }
        })
        .catch(() => {
          Toast({
            message: "系統錯誤，請重新嘗試",
            icon: "fail",
            duration: 500,
            className: "vant-toast-custom",
            teleport: ".reset-pwd-drawer",
          });
        })
        .finally(() => {
          this.resetPasswordBtnLoading = false;
        });
    },
  },
  computed: {
    ...mapStores(useStorePersistStore),
  },
  async mounted() {
    const loading = ElLoading.service({
      lock: true,
      text: "",
      background: "rgba(0, 0, 0, 0.7)",
    });

    this.LoadInternalUserDropdownList();

    var res = await GetUserInfo();
    loading.close();

    this.form = res.data.data;
  },
  watch: {},
});
