
import { Icon } from "@iconify/vue";
import { defineComponent } from "vue";
import Router from "../../../router/index";
import { InternalUserLogout } from "../../../services/backstages/InternalUserService";
import { mapStores } from "pinia";
import { useBackStagePersistStore } from "../../../store/BackStagePersistStore";
import { FailAndNeedHandle } from "../../../services/commons/CommonService";
import { GetAsset } from "../../../services/backstages/AssetsHelper";
import { SetMsgRead, SyncNotificationData } from "../../../services/backstages/NotificationService";
import { format, parseISO } from "date-fns";

export default defineComponent({
  name: "Navbar",
  components: {
    Icon,
  },
  data() {
    return {
      fullscreenLoading: false,
      PollingData: setInterval(() => this.DoSyncNotificationData(), 30000),
    };
  },
  methods: {
    async Logout() {
      InternalUserLogout().then((result) => {
        if (FailAndNeedHandle(result)) {
          throw new Error("logout fail");
        } else {
          this.backStagePersistStore.resetPermission();
          Router.push({ name: "BackStageLogin" });
        }
      });
    },
    ToggleSideBar() {
      this.$emit("toggle-side-bar");
    },
    DoGetAsset(path: string): string {
      return GetAsset(path);
    },
    async DoSyncNotificationData(): Promise<void> {
      if (this.backStagePersistStore.isLogin() == false) {
        return;
      }

      const res = await SyncNotificationData();

      if (res.status == 200) {
        this.backStagePersistStore.setNotifyList(res.data.data);
      }
    },
    async SetMsgRead(): Promise<void> {
      const res = await SetMsgRead();

      if (res.status == 200) {
        this.backStagePersistStore.setMsgRead();
      }
    },
    async NotifyEvent(): Promise<void> {
      if (document.visibilityState === "hidden") {
        clearInterval(this.PollingData);
      } else {
        await this.DoSyncNotificationData();

        this.PollingData = setInterval(() => this.DoSyncNotificationData(), 30000);
      }
    },
    TransDateTime(d: Date) {
      if (typeof d == "string") {
        return format(parseISO(d), "yyyy/MM/dd HH:mm:ss");
      }

      return format(d, "yyyy/MM/dd HH:mm:ss");
    },
    TransNotifyTypeDesc(type: string) {
      if (type == 'NewOrder') {
        return "新訂單通知";
      }

      return "";
    }
  },

  computed: {
    ...mapStores(useBackStagePersistStore),
  },

  async mounted() {
    document.addEventListener("notifyEvent", this.NotifyEvent, false);
  }
});
