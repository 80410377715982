
import { defineComponent, Ref, ref } from "vue";
import Draggable from "vuedraggable";
import DragIcon from "@iconify/icons-mdi/drag-variant";
import EditIcon from "@iconify/icons-mdi/square-edit-outline";
import DeleteIcon from "@iconify/icons-mdi/delete-forever-outline";
import { Icon, addIcon } from "@iconify/vue";
import HamIcon from "../../components/commons/icons/HamIcon.vue";
import { GetDefinitionDropdownList } from "../../services/DefinitionService";
import {
  CreateProductCategory,
  GetHashTagList,
  GetProductCategoryUpdateEntityById,
  GetProductCategoryList,
  UpdateProductCategory,
  DeleteProductCategory,
  CreateHashTag,
  UpdateHashTag,
  GetHashTagUpdateEntityById,
  DeleteHashTag,
  BatchUpdateProductCategorySort,
  BatchUpdateHashTagSort,
  GetBranchDropdownList,
GetInternalBusinessShippingProfileList,
GetInternalBusinessShippingProfileById,
UpdateInternalBusinessShippingProfile,
BatchUpdateShippingProfileSort
} from "../../services/backstages/VariableSettingService";
import {
  ProductCategoryUpdateEntity,
  ProductCategoryListEntity,
  HashTagListEntity,
  HashTagUpdateEntity,
  SubProductCategoryUpdateEntity,
  SubProductCategoryCreateEntity,
  ProductCategoryCreateEntity,
  HashTagCreateEntity,
InternalBusinessShippingProfileInfoEntity,
InternalBusinessShippingProfileUpdateEntity,
} from "../../models/backstages/VariableSettingModels";

import { DropdownEntity, IdEntity } from "../../models/commons/CommonModels";
import { ElMessageBox, ElNotification } from "element-plus";
import { FailAndNeedHandle } from "../../services/commons/CommonService";
import { DefinitionSearchEntity } from "@/models/commons/DefinitionModels";
import { TransformNumber } from "@/commons/FormatHelper";

addIcon("drag-icon", DragIcon);
addIcon("edit-icon", EditIcon);
addIcon("delete-icon", DeleteIcon);

export default defineComponent({
  name: "VariableSettingView",
  components: {
    Draggable,
    Icon,
    HamIcon,
  },
  data() {
    return {
      //清單data
      categoryList: [] as Array<ProductCategoryListEntity>,
      hashTagList: [] as Array<HashTagListEntity>,
      branchDropDownList: [] as Array<DropdownEntity>,
      shippingProfileList: [] as Array<InternalBusinessShippingProfileInfoEntity>,
      shippingProfileStatusDropdownList: ref([] as Array<DropdownEntity>),

      //新增相關data
      createCategoryDialog: false,
      createHashTagDialog: false,

      createCategoryEntity: {
        name: "",
        branchId: null!,
        subCategoryList: [] as Array<SubProductCategoryCreateEntity>,
      } as ProductCategoryCreateEntity,

      createHashTagEntity: {
        name: "",
      } as HashTagCreateEntity,

      //更新相關data
      updateCategoryDialog: false,
      updateHashTagDialog: false,
      updateShippingProfileDialog: false,

      updateCategoryEntity: {
        id: 0,
        name: "",
        branchId: null!,
        subCategoryList: [] as Array<SubProductCategoryUpdateEntity>,
      } as ProductCategoryUpdateEntity,

      updateHashTagEntity: {
        id: 0,
        name: "",
      } as HashTagUpdateEntity,

      updateShippingProfileEntity: {
        id: 0,
        displayName: '',
        shippingFee: 0,
        status: ''
      } as InternalBusinessShippingProfileUpdateEntity,

      currentShippingProfile: {
        id: 0,
        shippingMethod: '',
        shippingMethodDesc: '',
        displayName: '',
        shippingFee: 0,
        status: ''
      } as InternalBusinessShippingProfileInfoEntity,

      /**
       * Validation rules
       */
      categoryValidationRules: {
        name: [{ required: true, message: "請輸入分類名稱", trigger: "blur" }],
        branchId: [{ required: true, message: "請選擇分館", trigger: "change" }],
      },
      shippingProfileValidationRules: {
        status: [{ required: true, message: "請選擇狀態", trigger: "change" }],
        displayName: [
          { required: true, message: "請輸入運費顯示名稱", trigger: "blur" },
          { max: 30, message: "長度限制為 30 字元", trigger: "blur" },],
        shippingFee: [
          { required: true, message: "請輸入運費", trigger: "blur" },
          { type: "integer",
            min: 0,
            max: 100000,
            message: "請輸入數字 0~100,000",
            trigger: "blur",
            transform: TransformNumber
          }],
      },
    };
  },
  methods: {
    //新增相關方法
    AppendCreateSubCategory(): void {
      this.createCategoryEntity.subCategoryList.push({
        name: "",
        sort: 0,
      });
    },

    DeleteCreateSubCategory(index: number): void {
      this.createCategoryEntity.subCategoryList.splice(index, 1);
    },
    async FormValidation(refs: any) {
      return refs.validate((valid: any) => {
        if (valid == false) {
          return valid;
        }
      });
    },
    async CreateProductCategory() {
      let isValid = false;
      await this.FormValidation(this.$refs.categoryFormRef).then(
        (result) => {
          isValid = result;
        }
      );
      
      if (isValid == false) {
        return;
      }

      const result = await CreateProductCategory(this.createCategoryEntity);

      if (FailAndNeedHandle(result)) {
        ElNotification({
          title: "新增失敗",
          message: result.data.errorMessage,
          duration: 1000,
          type: "error",
        });
      } else {
        ElNotification({
          title: "新增成功",
          message: "",
          duration: 1000,
          type: "success",
        });
        this.createCategoryDialog = false;

        this.createCategoryEntity = {
          name: "",
          subCategoryList: [] as SubProductCategoryCreateEntity[],
        } as ProductCategoryCreateEntity;
      }
    },

    CancelCreateProductCategory(): void {
      this.createCategoryDialog = false;

      this.createCategoryEntity = {
        name: "",
        subCategoryList: [] as SubProductCategoryCreateEntity[],
      } as ProductCategoryCreateEntity;
    },

    //更新相關方法
    AppendUpdateSubCategory(): void {
      this.updateCategoryEntity.subCategoryList.push({
        id: 0,
        name: "",
        sort: 0,
      });
    },

    DeleteUpdateSubCategory(index: number): void {
      this.updateCategoryEntity.subCategoryList.splice(index, 1);
    },

    async UpdateProductCategory() {
      const result = await UpdateProductCategory(this.updateCategoryEntity);

      if (FailAndNeedHandle(result)) {
        ElNotification({
          title: "更新失敗",
          message: result.data.errorMessage,
          duration: 1000,
          type: "error",
        });
      } else {
        ElNotification({
          title: "更新成功",
          message: "",
          duration: 1000,
          type: "success",
        });

        this.updateCategoryDialog = false;

        this.updateCategoryEntity = {
          id: 0,
          name: "",
          subCategoryList: [] as SubProductCategoryUpdateEntity[],
        } as ProductCategoryUpdateEntity;
      }
    },

    CancelUpdateProductCategory() {
      this.updateCategoryDialog = false;

      this.updateCategoryEntity = {
        id: 0,
        name: "",
        subCategoryList: [] as SubProductCategoryUpdateEntity[],
      } as ProductCategoryUpdateEntity;
    },

    async InitialUpdateProductCategoryDialog(id: number) {
      this.LoadBranchDropdownList();
      const result = await GetProductCategoryUpdateEntityById({
        id: id,
      } as IdEntity);

      if (FailAndNeedHandle(result)) {
        ElNotification({
          title: "取得資料異常",
          message: result.data.errorMessage,
          duration: 1000,
          type: "error",
        });
      } else {
        this.updateCategoryEntity = result.data.data;
        this.updateCategoryDialog = true;
      }
    },
    OpenCreateCategoryDialog(): void {
      this.LoadBranchDropdownList();
      this.createCategoryDialog = true
    },

    async LoadBranchDropdownList() {
      const result = await GetBranchDropdownList();
      if (FailAndNeedHandle(result)) {
        ElNotification({
          title: "取得分館資料異常",
          message: result.data.errorMessage,
          duration: 1000,
          type: "error",
        });
      } else {
        this.branchDropDownList = result.data.data;
      }
    },

    //刪除相關方法
    async DeleteProductCategory(id: number): Promise<void> {
      ElMessageBox.confirm("確定要刪除?", "Warning", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(async () => {
        var result = await DeleteProductCategory({ id: id } as IdEntity);

        if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "刪除失敗",
            message: result.data.errorMessage,
            duration: 1000,
            type: "error",
          });
        } else {
          ElNotification({
            title: "刪除成功",
            message: "",
            duration: 1000,
            type: "success",
            onClose: () => this.RefreshTable(),
          });
        }
      });
    },

    //HashTag
    async CreateHashTag() {
      const result = await CreateHashTag(this.createHashTagEntity);

      if (FailAndNeedHandle(result)) {
        ElNotification({
          title: "新增失敗",
          message: result.data.errorMessage,
          duration: 1000,
          type: "error",
        });
      } else {
        ElNotification({
          title: "新增成功",
          message: "",
          duration: 1000,
          type: "success",
        });

        this.createHashTagEntity = { name: "" } as HashTagCreateEntity;
        this.createHashTagDialog = false;
      }
    },

    CancelCreateHashTag(): void {
      this.createHashTagEntity = { name: "" } as HashTagCreateEntity;
      this.createHashTagDialog = false;
    },

    //更新相關方法
    async UpdateHashTag() {
      const result = await UpdateHashTag(this.updateHashTagEntity);

      if (FailAndNeedHandle(result)) {
        ElNotification({
          title: "更新失敗",
          message: result.data.errorMessage,
          duration: 1000,
          type: "error",
        });
      } else {
        ElNotification({
          title: "更新成功",
          message: "",
          duration: 1000,
          type: "success",
        });

        this.updateHashTagEntity = { id: 0, name: "" } as HashTagUpdateEntity;
        this.updateHashTagDialog = false;
      }
    },

    CancelUpdateHashTag() {
      this.updateHashTagEntity = { id: 0, name: "" } as HashTagUpdateEntity;
      this.updateHashTagDialog = false;
    },

    CancelUpdateShippingProfile() {
      this.updateShippingProfileEntity = {
        id: 0,
        displayName: '',
        shippingFee: 0,
        status: ''
      } as InternalBusinessShippingProfileUpdateEntity;
      this.updateShippingProfileDialog = false;
    },

    async InitialUpdateHashTagDialog(id: number) {
      const result = await GetHashTagUpdateEntityById({
        id: id,
      } as IdEntity);

      if (FailAndNeedHandle(result)) {
        ElNotification({
          title: "取得資料異常",
          message: result.data.errorMessage,
          duration: 1000,
          type: "error",
        });
      } else {
        this.updateHashTagEntity = result.data.data;
        this.updateHashTagDialog = true;
      }
    },

    //刪除相關方法
    async DeleteHashTag(id: number): Promise<void> {
      ElMessageBox.confirm("確定要刪除?", "Warning", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(async () => {
        var result = await DeleteHashTag({ id: id } as IdEntity);

        if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "刪除失敗",
            message: result.data.errorMessage,
            duration: 1000,
            type: "error",
          });
        } else {
          ElNotification({
            title: "刪除成功",
            message: "",
            duration: 1000,
            type: "success",
            onClose: () => this.RefreshTable(),
          });
        }
      });
    },

    async UpdateOrderOfAllSettings() {
      Promise.all([
        BatchUpdateProductCategorySort(this.categoryList).then(function (
          result
        ) {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "更新商品分類排序失敗",
              message: result.data.errorMessage,
              duration: 2000,
              type: "error",
            });
          } else {
            ElNotification({
              title: "更新商品排序成功",
              message: "",
              duration: 2000,
              type: "success",
            });
          }
        }),
        BatchUpdateHashTagSort(this.hashTagList).then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "更新商品標籤排序失敗",
              message: result.data.errorMessage,
              duration: 2000,
              type: "error",
            });
          } else {
            ElNotification({
              title: "更新商品標籤排序成功",
              message: "",
              duration: 2000,
              type: "success",
            });
          }
        }),
        BatchUpdateShippingProfileSort(this.shippingProfileList).then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "更新運送設定排序失敗",
              message: result.data.errorMessage,
              duration: 2000,
              type: "error",
            });
          } else {
            ElNotification({
              title: "更新運送設定排序成功",
              message: "",
              duration: 2000,
              type: "success",
            });
          }
        }),
      ]);
    },

    async InitialUpdateShippingProfileDialog(id: number) {
      this.LoadDropdownList();
      const result = await GetInternalBusinessShippingProfileById({
        id: id,
      } as IdEntity);

      if (FailAndNeedHandle(result)) {
        ElNotification({
          title: "取得運費資料異常",
          message: result.data.errorMessage,
          duration: 1000,
          type: "error",
        });
      } else {
        let infoEntity = result.data.data

        this.currentShippingProfile.id = infoEntity.id
        this.currentShippingProfile.displayName = infoEntity.displayName
        this.currentShippingProfile.shippingFee = infoEntity.shippingFee
        this.currentShippingProfile.shippingMethod = infoEntity.shippingMethod
        this.currentShippingProfile.shippingMethodDesc = infoEntity.shippingMethodDesc
        this.currentShippingProfile.status = infoEntity.status

        this.updateShippingProfileDialog = true;
      }
    },

    async UpdateShippingProfile() {

      let isValid = false;

      await this.FormValidation(this.$refs.shippingProfileFormRef).then(
        (result) => {
          isValid = result;
        }
      );
      
      if (isValid == false) {
        return;
      }

      this.updateShippingProfileEntity.id = this.currentShippingProfile.id
      this.updateShippingProfileEntity.displayName = this.currentShippingProfile.displayName
      this.updateShippingProfileEntity.shippingFee = this.currentShippingProfile.shippingFee
      this.updateShippingProfileEntity.status = this.currentShippingProfile.status

      let result = await UpdateInternalBusinessShippingProfile(this.updateShippingProfileEntity)
      if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "更新運送設定失敗",
            message: result.data.errorMessage,
            duration: 1000,
            type: "error",
          });
      } else {
        ElNotification({
          title: "更新運送設定成功",
          message: "",
          duration: 1000,
          type: "success",
        });

        this.updateShippingProfileDialog = false;
      }
    },

    async GetDefinitionAndSetValue(
      payload: DefinitionSearchEntity,
      ref: Ref<Array<DropdownEntity>>) {
      const res = await GetDefinitionDropdownList(payload);

      if (FailAndNeedHandle(res)) {
        ElNotification({
          title: "取得資料失敗",
          message: res.data.errorMessage,
          duration: 3000,
          type: "error",
        });
      } else {
        ref.value = res.data.data;
      }
    },

    async LoadDropdownList() {
      let payload = {
        tableName: "internal_business_shipping_profile",
        columnName: "status",
      } as DefinitionSearchEntity

      const res = await GetDefinitionDropdownList(payload);

      if (FailAndNeedHandle(res)) {
        ElNotification({
          title: "取得資料失敗",
          message: res.data.errorMessage,
          duration: 3000,
          type: "error",
        });
      } else {
        this.shippingProfileStatusDropdownList = res.data.data;
      }
    },

    RefreshTable(): void {
      GetProductCategoryList().then((result) => {
        this.categoryList = result.data.data;
      });

      GetHashTagList().then((result) => {
        this.hashTagList = result.data.data;
      });

      GetInternalBusinessShippingProfileList().then((result) => {
        this.shippingProfileList = result.data.data;
      });
    },
  },

  async mounted() {
    this.RefreshTable();
  },
});
