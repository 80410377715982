
import { defineComponent } from "vue";
import Sortable from "sortablejs";

export default defineComponent({
    name: "ElTableDraggable",
    props: ["dragTarget"],
    methods: {
      initialize() {
        const table = this.$el.querySelector('.el-table__body-wrapper tbody')
        // Make sku table sortable
        Sortable.create(table, {
            handle: this.dragTarget,
            animation: 400,
            onEnd: (evt: any) => {
                this.$emit('drag-end', evt)
            }}
        );
      }
    },
    mounted() {
        this.initialize();
    }
});
