import { ApiResponseEntity } from "@/models/ApiModels";
import { AxiosResponse } from "axios";
import { DropdownEntity } from "@/models/commons/CommonModels";
import { Post } from "../commons/CommonService"

const prefix = "/api/store"

/**
 * 取得分館資訊下拉清單
 * @returns 分館資訊下拉清單
 */
export async function GetBranchDropdownList(): Promise<AxiosResponse<ApiResponseEntity<Array<DropdownEntity>>, any>> {

  const url = prefix + "/Branch/GetBranchDropdownList";

  const result = await Post<object, Array<DropdownEntity>>(url, {});

  return result;
}

/**
 * 取得分館顯示開關
 * @returns True: 要顯示分館; False: 不顯示分館
 */
export async function IsShowBranch(): Promise<AxiosResponse<ApiResponseEntity<boolean>, any>> {

  const url = prefix + "/Branch/IsShowBranch";

  const result = await Post<object, boolean>(url, {});

  return result;
}