
import { defineComponent } from "vue";
import { SubMenuEntity } from "../../../models/commons/CommonModels";
import { useBackStagePersistStore } from "../../../store/BackStagePersistStore";

export default defineComponent({
  name: "Sidebar",
  data() {
    return {
      menuData: [] as Array<SubMenuEntity>,
      defaultProps: {
        children: "children",
        label: "label",
      },
      defaultIndex: "",
    };
  },
  methods: {
    LinkTo(url: string) {
      window.open(url, "_self");
    },
  },

  created() {
    const store = useBackStagePersistStore();
    this.menuData = store.menu();
    this.defaultIndex = this.$route.path;
  },
});
