import { LoginEntity, SerilogEntity, SerilogSearchEntity } from "@/models/admins/AdminModels";
import { ApiResponseEntity, SearchCriteriaEntity, SearchResultEntity } from "@/models/ApiModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService";

export async function LogSearch(param: SearchCriteriaEntity<SerilogSearchEntity>): Promise<AxiosResponse<ApiResponseEntity<SearchResultEntity<SerilogEntity>>, any>> {
    const url = "/api/admin/Admin/Search";
    const result = await Post<SearchCriteriaEntity<SerilogSearchEntity>, SearchResultEntity<SerilogEntity>>(url, param);

    return result;
}

export async function Login(param: LoginEntity): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
    const url = "/api/admin/Admin/Login";
    const result = await Post<LoginEntity, any>(url, param);

    return result;
}

export async function Logout(param: any): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
    const url = "/api/admin/Admin/Logout";
    const result = await Post<any, any>(url, param);

    return result;
}