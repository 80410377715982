import { ApiResponseEntity } from "@/models/ApiModels";
import { DropdownEntity, IdEntity } from "@/models/commons/CommonModels";
import { HashTagUpdateEntity, HashTagListEntity, ProductCategoryUpdateEntity, ProductCategoryListEntity, ProductCategoryCreateEntity, HashTagCreateEntity, InternalBusinessShippingProfileUpdateEntity, InternalBusinessShippingProfileInfoEntity } from "@/models/backstages/VariableSettingModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService"

export async function CreateProductCategory(params: ProductCategoryCreateEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

    const url = "/api/backstage/VariableSetting/CreateProductCategory";

    const result = await Post<ProductCategoryCreateEntity, object>(url, params);

    return result;
}

export async function UpdateProductCategory(params: ProductCategoryUpdateEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

    const url = "/api/backstage/VariableSetting/UpdateProductCategory";

    const result = await Post<ProductCategoryUpdateEntity, object>(url, params);

    return result;
}

export async function DeleteProductCategory(params: IdEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

    const url = "/api/backstage/VariableSetting/DeleteProductCategory";

    const result = await Post<IdEntity, object>(url, params);

    return result;
}

export async function BatchUpdateProductCategorySort(params: Array<ProductCategoryListEntity>): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

    const url = "/api/backstage/VariableSetting/BatchUpdateProductCategorySort";

    const result = await Post<Array<ProductCategoryListEntity>, object>(url, params);

    return result;
}

export async function CreateHashTag(params: HashTagCreateEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

    const url = "/api/backstage/VariableSetting/CreateHashTag";

    const result = await Post<HashTagCreateEntity, object>(url, params);

    return result;
}

export async function UpdateHashTag(params: HashTagUpdateEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

    const url = "/api/backstage/VariableSetting/UpdateHashTag";

    const result = await Post<HashTagUpdateEntity, object>(url, params);

    return result;
}

export async function DeleteHashTag(params: IdEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

    const url = "/api/backstage/VariableSetting/DeleteHashTag";

    const result = await Post<IdEntity, object>(url, params);

    return result;
}

export async function BatchUpdateHashTagSort(params: Array<HashTagListEntity>): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

    const url = "/api/backstage/VariableSetting/BatchUpdateHashTagSort";

    const result = await Post<Array<HashTagListEntity>, object>(url, params);

    return result;
}

export async function GetProductCategoryList(): Promise<AxiosResponse<ApiResponseEntity<Array<ProductCategoryListEntity>>, any>> {

    const url = "/api/backstage/VariableSetting/GetProductCategoryList";

    const result = await Post<object, Array<ProductCategoryListEntity>>(url, {});

    return result;
}

export async function GetHashTagList(): Promise<AxiosResponse<ApiResponseEntity<Array<HashTagListEntity>>, any>> {

    const url = "/api/backstage/VariableSetting/GetHashTagList";

    const result = await Post<object, Array<HashTagListEntity>>(url, {});

    return result;
}

export async function GetProductCategoryUpdateEntityById(params: IdEntity): Promise<AxiosResponse<ApiResponseEntity<ProductCategoryUpdateEntity>, any>> {

    const url = "/api/backstage/VariableSetting/GetProductCategoryUpdateEntityById";

    const result = await Post<IdEntity, ProductCategoryUpdateEntity>(url, params);

    return result;
}

export async function GetHashTagUpdateEntityById(params: IdEntity): Promise<AxiosResponse<ApiResponseEntity<HashTagUpdateEntity>, any>> {

    const url = "/api/backstage/VariableSetting/GetHashTagUpdateEntityById";

    const result = await Post<IdEntity, HashTagUpdateEntity>(url, params);

    return result;
}

export async function GetBranchDropdownList(): Promise<AxiosResponse<ApiResponseEntity<Array<DropdownEntity>>, any>> {

    const url = "/api/backstage/VariableSetting/GetBranchDropdownList";

    const result = await Post<null, Array<DropdownEntity>>(url, null!);

    return result;
}

export async function GetInternalBusinessShippingProfileById(params: IdEntity): Promise<AxiosResponse<ApiResponseEntity<InternalBusinessShippingProfileInfoEntity>, any>> {

    const url = "/api/backstage/VariableSetting/GetInternalBusinessShippingProfileById";

    const result = await Post<IdEntity, InternalBusinessShippingProfileInfoEntity>(url, params);

    return result;
}

export async function GetInternalBusinessShippingProfileList(): Promise<AxiosResponse<ApiResponseEntity<Array<InternalBusinessShippingProfileInfoEntity>>, any>> {

    const url = "/api/backstage/VariableSetting/GetInternalBusinessShippingProfileList";

    const result = await Post<null, Array<InternalBusinessShippingProfileInfoEntity>>(url, null!);

    return result;
}

export async function UpdateInternalBusinessShippingProfile(param: InternalBusinessShippingProfileUpdateEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

    const url = "/api/backstage/VariableSetting/UpdateInternalBusinessShippingProfile";

    const result = await Post<InternalBusinessShippingProfileUpdateEntity, object>(url, param);

    return result;
}

export async function GetShippingProfileDropdownList(): Promise<AxiosResponse<ApiResponseEntity<Array<DropdownEntity>>, any>> {

    const url = "/api/backstage/VariableSetting/GetShippingProfileDropdownList";

    const result = await Post<null, Array<DropdownEntity>>(url, null!);

    return result;
}

export async function BatchUpdateShippingProfileSort(params: Array<InternalBusinessShippingProfileInfoEntity>): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

    const url = "/api/backstage/VariableSetting/BatchUpdateShippingProfileSort";

    const result = await Post<Array<InternalBusinessShippingProfileInfoEntity>, object>(url, params);

    return result;
}