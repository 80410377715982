import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_menu, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuData, (menuItem) => {
          return (_openBlock(), _createBlock(_component_el_sub_menu, {
            key: menuItem.id,
            index: menuItem.id
          }, {
            title: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(menuItem.name), 1)
            ]),
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.menuItem, (childItem) => {
                return (_openBlock(), _createBlock(_component_el_menu_item, {
                  key: childItem.id,
                  index: childItem.path,
                  disabled: childItem.disabled,
                  onClick: ($event: any) => (_ctx.LinkTo(childItem.path))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(childItem.name), 1)
                  ]),
                  _: 2
                }, 1032, ["index", "disabled", "onClick"]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["index"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}