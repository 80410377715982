import { ApiResponseEntity } from "@/models/ApiModels";
import { ExternalBusinessSettingEntity, ExternalBusinessSettingSearchEntity } from "@/models/backstages/ExternalBusinessSettingModel";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService";

export async function GetByCode(request: ExternalBusinessSettingSearchEntity): Promise<AxiosResponse<ApiResponseEntity<ExternalBusinessSettingEntity>, any>> {

    const url = "/api/backstage/ExternalBusinessSetting/GetByCode";
    const result = await Post<ExternalBusinessSettingSearchEntity, ExternalBusinessSettingEntity>(url, request);

    return result;
}
