<template>
    <div>
        <div class="horizon-line"></div>
        <div class="horizon-line"></div>
        <div class="horizon-line"></div>
    </div>
</template>

<script>
export default {
    name: "HamIcon"
}
</script>

<style scoped>
.horizon-line {
  width: 20px;
  height: 1px;
  background-color: var(--el-text-color-regular);
  margin: 3px auto;
}
</style>