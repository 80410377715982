
import { defineComponent } from "vue";
import { debounce } from "vue-debounce";
import { ElMessageBox, ElNotification} from "element-plus";
import { DropdownEntity, ExcelImportResponseEntity, OrderByEntity } from "../../models/commons/CommonModels";
import { Export, Search, Delete } from "../../services/backstages/ExternalBizSkuSettingService";
import {
  ExternalBizSkuSettingSearchEntity,
  ExternalBizSkuSettingListEntity,
} from "../../models/backstages/ExternalBizSkuSettingModel";
import { SearchCriteriaEntity } from "../../models/ApiModels";
import {
  GetExternalBusinessById,
  GetExternalBusinessDropdownList
} from "../../services/backstages/ExternalBusinessService";
import { FailAndNeedHandle, GetAuthorizationHeaderByRoute } from "../../services/commons/CommonService";
import { DefinitionSearchEntity } from "@/models/commons/DefinitionModels";
import { GetDefinitionDropdownList } from "@/services/DefinitionService";
import FileImportDialog from "../../components/commons/FileImportDialog.vue";
import moment from "moment";

export default defineComponent({
  name: "ExternalBizSkuSettingListView",
  components: {
    FileImportDialog
  },
  data() {
    return {
      isOrderDataLoading: false,
      isSpuStatusChanged: false,
      isOpenFilter: false,
      searchEntity: {} as ExternalBizSkuSettingSearchEntity,
      tableData: [] as Array<ExternalBizSkuSettingListEntity>,
      calcTypeList: [] as Array<DropdownEntity>,

      isOpenImportFile: false,
      importStep: 1,
      isImportDryRun: true,
      importResultData: {
        result: [],
        totalCount: 0,
        status: "",
        isDryRun: true,
      } as ExcelImportResponseEntity,
      importFileList: [] as any,


      /**
       * 下拉清單
       */
      externalBusinessDropdownList: [] as any,

      /**
       * Pagination vars
       */
      currentPage: 1,
      totalPage: 0,
      currentCount: 0,
      totalCount: 0,
      pageSize: 10,
      pageSizes: [10, 25, 50, 100],
    };
  },
  async created() {
    await this.InitData();
    this.LoadData();
  },
  computed: {
    filterBtnText(): string {
      let text = "篩選";
      let filterCount = 0;
      if (this.searchEntity.externalBizId != undefined) {
        filterCount++;
      }

      if (this.searchEntity.calcType != "") {
        filterCount++;
      }

      if (this.searchEntity.name != "") {
        filterCount++;
      }
      if (this.searchEntity.skuName != "") {
        filterCount++;
      }
      if (this.searchEntity.skuOuterSysCode != "") {
        filterCount++;
      }
      if (this.searchEntity.productOuterSysCode != "") {
        filterCount++;
      }

      if (filterCount != 0) {
        text = text + "(" + filterCount + ")";
      }
      return text;
    },
  },
  methods: {
    ClearConditionFilterData() {
      this.searchEntity = {
        externalBizId: undefined,
        name: "",
        skuName: "",
        skuOuterSysCode: "",
        productOuterSysCode: "",
        calcType: "",
        orderBy: { isAscending: true, name: "" } as OrderByEntity,
      };
    },
    ClearData() {
      this.ClearConditionFilterData();
    },
    async InitData() {
      this.ClearConditionFilterData();

      if (this.$route.params.id != "") {
        const extBizId = parseInt(this.$route.params.id as string);

        const res = await GetExternalBusinessById(extBizId);
        this.externalBusinessDropdownList = [
          { label: res.data.data.name, value: res.data.data.id },
        ];

        this.searchEntity.externalBizId = extBizId;
      }
    },
    LoadData() {
      this.DoSearch();
    },
    LoadExternalBusinessDropdownList(query: string) {
      debounce(() => {
        GetExternalBusinessDropdownList(query)
          .then((result) => {
            if (FailAndNeedHandle(result)) {
              ElNotification({
                title: "客戶清單載入失敗",
                message: result.data.errorMessage,
                duration: 3000,
                type: "error",
              });
            } else {
              this.externalBusinessDropdownList = result.data.data;
            }
          })
          .catch(() => {
            console.error("Load LoadExternalBusinessDropdownList error");
          });
      }, "400ms")();
    },



    ApplyConditionFilter() {
      this.isOpenFilter = false;
      this.DoSearch();
    },
    ClearConditionFilter() {
      this.ClearConditionFilterData();
    },
    DoSearch() {
      this.isOrderDataLoading = true;

      const req = {
        skip: this.pageSize * (this.currentPage - 1),
        take: this.pageSize,
        condition: this.searchEntity,
      } as SearchCriteriaEntity<ExternalBizSkuSettingSearchEntity>;

      Search(req)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "客訂設定載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let paginationResult = result.data.data;
            this.tableData = paginationResult.data;

            this.currentCount = paginationResult.currentCount;
            this.totalCount = paginationResult.totalCount;
            this.totalPage = paginationResult.totalPage;
          }
        })
        .catch(() => {
          ElNotification({
            title: "載入失敗",
            message: "客訂設定載入失敗",
            duration: 3000,
            type: "error",
          });
        })
        .finally(() => {
          this.isOrderDataLoading = false;
        });
    },
    OpenUrl(url: string) {
      window.open(url, "_blank");
    },
    OpenFilter() {
      this.isOpenFilter = true;
    },
    HandleSizeChange(val: number) {
      this.pageSize = val;
      this.DoSearch();
    },
    HandleCurrentChange(val: number) {
      this.currentPage = val;
      this.DoSearch();
    },
    EditAction(index: any, row: any) {
      const link = this.$router.resolve({
        path:
          "/BackStage/ExternalBizSkuSetting/Edit/" +
          row.id +
          "/" +
          row.externalBizId,
      });
      window.open(link.href);
    },
    DeleteAction(index: any, row: any) {
      let id = row.id;
      let extBizName = row.externalBizName;
      let skuName = row.skuName;

      ElMessageBox.confirm(
      `是否要刪除客訂設定<br>客戶名稱: ${extBizName}<br>SKU 名稱: ${skuName}？`,
          "警告",
          {
            confirmButtonText: "確定",
            cancelButtonText: "取消",
            type: "warning",
            dangerouslyUseHTMLString: true,
          }
      )
      .then(() => {
        Delete(id)
            .then((result) => {
              if (FailAndNeedHandle(result)) {
                ElNotification({
                  title: `刪除客訂設定`,
                  message: `客戶名稱: ${extBizName} SKU 名稱: ${skuName} 刪除失敗，請確認！`,
                  duration: 3000,
                  type: "error",
                });
              } else {
                ElNotification({
                  title: `刪除客訂設定`,
                  message: `客戶名稱: ${extBizName} SKU 名稱: ${skuName} 刪除成功`,
                  duration: 3000,
                  type: "success",
                });
                
                this.DoSearch();
              }
            })
            .catch(() => {
              ElNotification({
                title: `刪除客訂設定`,
                message: `客戶名稱: ${extBizName} SKU 名稱: ${skuName} 刪除失敗，請確認！`,
                duration: 3000,
                type: "error",
              });
            });
      })
      .catch((msg) => { console.error(msg) });
    },
    HandleSortChange(column: any) {
      if (column.column !== null) {
        this.searchEntity.orderBy.name = this.CapitalizeFirstLetter(
          column.prop
        );
        this.searchEntity.orderBy.isAscending = column.order == "ascending";
      } else {
        this.searchEntity.orderBy.name = "";
        this.searchEntity.orderBy.isAscending = true;
      }

      this.DoSearch();
    },
    async GetCalcTypeList() {
      var req = { tableName: 'external_biz_sku_settings', columnName: 'calc_type' } as DefinitionSearchEntity;

      var res = await GetDefinitionDropdownList(req);

      this.calcTypeList = res.data.data;
    },

    /**
     * 將英文的首字母轉換成大寫
     * @param str 要轉換的字串
     * @returns 首字母大寫
     */
    CapitalizeFirstLetter(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    ClearImportDialog() {
      this.importStep = 1;
      this.isImportDryRun = true;
      this.importResultData = {} as any;
      this.importFileList = [] as any;
    },
    ConfirmImport() {
      this.isImportDryRun = false;
      // Set status to 'ready' so that the file can be uploaded again after triggering submit
      this.importFileList[0].status = "ready";
      (this.$refs as any).importFileRef.submit();
    },
    HandleImportSuccess(response: any) {
      this.importResultData = response.data;

      if (
        this.importResultData.isDryRun ||
        this.importResultData.status == "Error"
      ) {
        this.importStep = 2;
      } else {
        this.importStep = 3;
        this.LoadData();
      }
    },
    HandleImportError() {
      ElNotification({
        title: "檔案上傳失敗",
        message: "請通知系統人員",
        duration: 3000,
        type: "error",
      });
    },
    HandleBeforeImport(file: any) {
      let extensionReg = /(\.xls|\.xlsx)$/;
      if (extensionReg.test(file.name) == false) {
        ElNotification({
          title: "檔案上傳失敗",
          message: "副檔名須為 .xls 或 .xlsx",
          duration: 3000,
          type: "error",
        });

        return false;
      }
    },

    DoExport() {
      let fileName = `客訂設定_${moment().format("yyyy_MM_DD_hh_mm_ss")}.xlsx`;

      if (this.searchEntity.externalBizId == undefined || this.searchEntity.externalBizId == null) {
        ElNotification({
          title: "客訂設定匯出失敗",
          message: "匯出需指定客戶",
          duration: 3000,
          type: "error",
        });

        return
      }

      Export(this.searchEntity).then((result) => {
        if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "客訂設定匯出失敗",
            message: result.data.errorMessage,
            duration: 3000,
            type: "error",
          });
        } else {
          let data: any = result.data;
          let blob = new Blob([data]);
          var el = document.createElement("a");
          el.href = URL.createObjectURL(blob);
          el.download = fileName;
          el.click();
        }
      });
    },
    GetHeaderByRoute(params: string) {
      return GetAuthorizationHeaderByRoute(params);
    },
    ConvertCalcTypeToDesc(calcType: string) {
      switch (calcType) {
        case "None":
          return "無"
        case "Discount":
          return "指定折數"
        case "FixPrice":
          return "指定價格"
      }
    },
    ConvertPriceToDesc(calcType: string, price: number) {
      switch (calcType) {
        case "FixPrice":
          return price.toString()
        case "Discount":
        case "None":
          return "N/A"
      }
    },
    ConvertDiscountToDesc(calcType: string, discount: number) {
      switch (calcType) {
        case "Discount":
          return discount.toString()
        case "FixPrice":
        case "None":
          return "N/A"
      }
    }
  },

  async mounted() {
    await this.GetCalcTypeList();
  }
});
