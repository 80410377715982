import { ApiResponseEntity } from "@/models/ApiModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService"

/**
 * 取得 reCAPTCHA Enterprise Site Key
 * @returns reCAPTCHA Enterprise Site Key
 */
export async function GetSiteKey() : Promise<AxiosResponse<ApiResponseEntity<string>, any>> {
  const url = "/api/store/Recaptcha/GetSiteKey";

  const result = await Post<object, string>(url, null!);
  
  return result;
}

/**
 * 載入 reCAPTCHA Enterprise 元件
 * @param siteKey Site Key
 */
export function LoadRecaptchaEnterpriseScript(siteKey: string): void {
  const script = document.createElement("script");
    script.setAttribute(
      "src",
      `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`
    );
    script.setAttribute("async", "true");
    script.setAttribute("defer", "true");
    document.head.appendChild(script);
}

export function IsRecaptchaEnterpriseLoaded(): boolean {
  console.warn('IsRecaptchaEnterpriseLoaded:', (window as any).__google_recaptcha_client);
  return (window as any).__google_recaptcha_client;
}

export async function RequestForRecaptchaToken(siteKey: string, action: string) : Promise<string> {
  if ((window as any).grecaptcha == null) {
    console.error('尚未載入 reCAPTCHA 元件');
  }

  return (window as any).grecaptcha.enterprise.execute(siteKey, {action: action});
}