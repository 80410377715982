import { ApiResponseEntity, PaginationEntity } from "@/models/ApiModels";
import { HashTagListEntity } from "@/models/backstages/HashTagModels";
import {
  SpuSearchEntity,
  SpuListRequestEntity,
  SpuListResponseEntity,
  SpuUpdateStatusEntity,
  SpuCreateEntity,
  SpuUpdateEntity,
  SpuOuterSysCodeDuplicationRequestEntity,
} from "@/models/backstages/SpuModels";
import {
  DropdownEntity,
  FileUploadResultEntity,
  ExcelExportRequestEntity,
  ExcelImportResponseEntity,
} from "@/models/commons/CommonModels";
import { ProductCategoryListEntity } from "@/models/backstages/VariableSettingModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService";
import { useBackStagePersistStore } from "../../store/BackStagePersistStore";

/**
 * 條件搜尋 product 清單
 * */
export async function SearchSpu(
  request: SpuSearchEntity
): Promise<
  AxiosResponse<ApiResponseEntity<PaginationEntity<SpuListResponseEntity>>, any>
> {
  const url = "/api/backstage/Spu/Search";
  const result = await Post<object, PaginationEntity<SpuListResponseEntity>>(
    url,
    request
  );

  return result;
}

/**
 * 取得 product 清單
 * */
export async function GetSpuList(
  request: SpuListRequestEntity
): Promise<
  AxiosResponse<ApiResponseEntity<Array<SpuListResponseEntity>>, any>
> {
  const url = "/api/backstage/Spu/GetList";
  const result = await Post<object, Array<SpuListResponseEntity>>(url, request);

  return result;
}

/**
 * 根據 product id 取得 product 資訊
 * */
export async function GetSpuById(
  id: number
): Promise<AxiosResponse<ApiResponseEntity<SpuListResponseEntity>, any>> {
  const url = "/api/backstage/Spu/GetById/" + id;
  const result = await Post<object, SpuListResponseEntity>(url, null!);

  return result;
}

/**
 * 根據 商品名稱 模糊搜尋取得 商品資料下拉清單
 * */
export async function GetSpuDropdownList(
  name: string
): Promise<AxiosResponse<ApiResponseEntity<Array<DropdownEntity>>, any>> {
  const url = "/api/backstage/Spu/GetSpuDropdownList?name=" + name;
  const result = await Post<object, Array<DropdownEntity>>(url, null!);

  return result;
}

/**
 * 根據 product id 移除 product 資訊
 * */
export async function DeleteSpuById(
  id: number
): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
  const url = "/api/backstage/Spu/Delete/" + id;
  const result = await Post<object, any>(url, null!);

  return result;
}

/**
 * 更新 product 狀態
 * */
export async function UpdateStatus(
  entity: SpuUpdateStatusEntity
): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
  const url = "/api/backstage/Spu/UpdateStatus";
  const result = await Post<SpuUpdateStatusEntity, any>(url, entity);

  return result;
}

/**
 * 建立 product
 * */
export async function CreateSpu(
  entity: SpuCreateEntity
): Promise<AxiosResponse<ApiResponseEntity<number>, any>> {
  const url = "/api/backstage/Spu/Create";
  const result = await Post<SpuCreateEntity, number>(url, entity);

  return result;
}

/**
 * 更新 product
 * */
export async function UpdateSpu(
  entity: SpuUpdateEntity
): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
  const url = "/api/backstage/Spu/Update";
  const result = await Post<SpuUpdateEntity, any>(url, entity);

  return result;
}

/**
 * 檢查 OuterSysCode 是否重複
 * @param entity 
 * @returns 
 */
export async function CheckOuterSysCodeDuplication(entity: SpuOuterSysCodeDuplicationRequestEntity) 
  : Promise<AxiosResponse<ApiResponseEntity<boolean>, any>> {
  const url = "/api/backstage/Spu/CheckOuterSysCodeDuplication";
  const result = await Post<SpuOuterSysCodeDuplicationRequestEntity, boolean>(url, entity);

  return result;
}

/**
 * 上傳商品圖片
 * @param data
 * @returns
 */
export async function UploadSpuImage(
  data: any
): Promise<AxiosResponse<ApiResponseEntity<FileUploadResultEntity>, any>> {
  const url = "/api/backstage/Spu/Upload";
  const result = await Post<object, FileUploadResultEntity>(url, data);

  return result;
}

/**
 * 上傳商品描述文字編輯器內的圖片
 * @param data
 * @returns
 */
export async function UploadEditorImage(
  data: any
): Promise<AxiosResponse<ApiResponseEntity<FileUploadResultEntity>, any>> {
  const url = "/api/backstage/Spu/UploadEditorImage";
  const result = await Post<object, FileUploadResultEntity>(url, data);

  return result;
}

/**
 * 取得商品分類下單清單
 * @returns
 */
export async function GetProductCategoryDropdownList(): Promise<
  AxiosResponse<
    ApiResponseEntity<Map<string, Array<ProductCategoryListEntity>>>,
    any
  >
> {
  const url = "/api/backstage/ProductCategory/GetProductCategoryDropdownList";
  const result = await Post<
    object,
    Map<string, Array<ProductCategoryListEntity>>
  >(url, null!);

  return result;
}

/**
 * 取得標籤分類下單清單
 * @returns HashTag
 */
export async function GetHashTagDropdownList(): Promise<
  AxiosResponse<ApiResponseEntity<Array<HashTagListEntity>>, any>
> {
  const url = "/api/backstage/HashTag/GetHashTagDropdownList";
  const result = await Post<object, Array<HashTagListEntity>>(url, null!);

  return result;
}

/**
 * 根據查詢條件，輸出資料為 Excel Stream
 * @param entity ExcelExportRequestEntity<SpuSearchEntity>
 * @returns Stream
 */
export async function ExportSpuAsExcel(
  entity: ExcelExportRequestEntity<SpuSearchEntity>
): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const store = useBackStagePersistStore();
  const token = store.jwtToken;

  const config: any = {
    withCredential: true,
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  };

  const url = "/api/backstage/Spu/ExportSpuAsExcel";
  const result = await Post<object, any>(url, entity, config);

  return result;
}

/**
 * 匯入Excel檔並進行更新
 * @returns Stream
 */
export async function ImportAndBatchUpdate(): Promise<
  AxiosResponse<ApiResponseEntity<ExcelImportResponseEntity>>
> {
  const url = "/api/backstage/Spu/ImportAndBatchUpdate";
  const result = await Post<object, any>(url, {});

  return result;
}
