
import { defineComponent } from "vue";
import { ElNotification } from "element-plus";
import { ExternalUserResponseEntity } from "../../models/backstages/ExternalUserModels";
import {
  InternalUserCreateEntity,
  InternalUserUpdateEntity,
} from "../../models/backstages/InternalUserModels";
import {
  CreateInternalUser,
  UpdateInternalUser,
  GetInternalUserById,
  GetInternalUserStatusDropdownList
} from "../../services/backstages/InternalUserService";
import { FailAndNeedHandle } from "../../services/commons/CommonService";
import { DropdownEntity } from "../../models/commons/CommonModels";
import { GetInternalRoleDropdownList } from "../../services/backstages/InternalRoleService";
import RegexPattern from "../../commons/RegexPattern";

export default defineComponent({ 
  name: "InternalUserInfoView",
  components: {},
  props: ["mode"],
  data() {
    return {
      title: (this.mode == "Create" ? "新增" : "編輯") + "使用者",
      status: "Active",
      loadings: {
        internalUser: false,
        createInternalUser: false,
        updateInternalUser: false,
      },

      labelPosition: "right",
      id: +this.$route.params.id || null!,

      /**
       * Internal User
       */
      internalUserMode: "Create",
      internalUserFormData: {
        name: "",
        email: "",
        account: "",
        password: "",
        roleId: 0,
        status: "Active"
      },
      internalUserCreateEntity: {} as InternalUserCreateEntity,
      internalUserResponseEntity: {} as ExternalUserResponseEntity,
      internalUserUpdateEntity: {} as InternalUserUpdateEntity,

      currentExternalUserId: 0,
      currentExternalUserRowUuid: "",
      currentExternalUserRowIndex: 0,

      internalUserFormVisible: false,

      /**
       * 下拉清單
       */
      internalRoleList: [] as Array<DropdownEntity>,
      internalUserStatusDropdownList: [] as Array<DropdownEntity>,

      /**
       * Validation rules
       */
      internalUserValidationRules: {
        name: [
          { required: true, message: "請輸入使用者名稱", trigger: "blur" },
        ],
        email: [{ type: "email", message: "請輸入 Email", trigger: "blur" }],
        account: [
          { required: true, message: "請輸入帳號", trigger: "blur" },
          { pattern: RegexPattern.account, message: "請輸入 3 ~ 50 字元大小寫英數字", trigger: "blur" }
        ],
        password: [
          { message: "請輸入密碼", trigger: "blur" },
          { pattern: RegexPattern.password, message: "請輸入 6 ~ 20 字元大小寫英數字以及特殊符號 ~`!@#$%^&*+=-", trigger: "blur" }
        ],
        roleId: [{ required: true, message: "請選擇角色", trigger: "change" }],
        status: [{ required: true, message: "請選擇狀態", trigger: "change" }],
      },
    };
  },
  async created() {
    await this.LoadData();
  },
  computed: {
    allLoading() {
      return !Object.values(this.loadings).every((m: boolean) => m == false);
    },
    isCreateMode() {
      return this.mode == "Create";
    },
    saveBtnLoading() {
      return (
        this.loadings.createInternalUser || this.loadings.updateInternalUser
      );
    },
    isCreateExternalUserMode() {
      return this.internalUserMode == "Create";
    },
  },
  methods: {
    ClearData() {
      this.ClearInternalUserData();
    },
    async LoadDropDownList() {
      this.LoadInternalRoleDropdownList();
      this.LoadInternalUserStatusDropdownList();
    },
    async LoadInternalRoleDropdownList() {
      const res = await GetInternalRoleDropdownList();

      if (FailAndNeedHandle(res)) {
        ElNotification({
          title: "角色選單載入失敗",
          message: res.data.errorMessage,
          duration: 3000,
          type: "error",
        });
      } else {
        this.internalRoleList = res.data.data;
        // Set first roleId as default value
        this.internalUserFormData.roleId = res.data.data[0]?.value;
      }
    },
    async LoadInternalUserStatusDropdownList() {
      const res = await GetInternalUserStatusDropdownList();

      if (FailAndNeedHandle(res)) {
        ElNotification({
          title: "狀態選單載入失敗",
          message: res.data.errorMessage,
          duration: 3000,
          type: "error",
        });
      } else {
        this.internalUserStatusDropdownList = res.data.data;
      }
    },
    async LoadData() {
      this.ClearData();
      await this.LoadDropDownList();
      switch (this.mode) {
        case "Create":
          break;
        case "Edit":
          this.LoadInternalUserData(this.id);
          break;
        default:
          console.error("Unknown mode!");
      }
    },
    async FormValidation(refs: any) {
      return refs.validate((valid: any) => {
        if (valid == false) {
          return valid;
        }
      });
    },
    async LoadInternalUserData(id: number) {
      this.loadings.internalUser = true;

      const res = await GetInternalUserById(id);

      this.loadings.internalUser = false;

      if (FailAndNeedHandle(res)) {
        ElNotification({
          title: "取得使用者失敗",
          message: res.data.errorMessage,
          duration: 3000,
          type: "error",
        });
      } else {
        const entity = res.data.data;
        this.internalUserFormData.account = entity.account;
        this.internalUserFormData.name = entity.name;
        this.internalUserFormData.email = entity.email;
        this.internalUserFormData.roleId = entity.roleId;
        this.internalUserFormData.status = entity.status;
      }
    },
    /**
     * 建立使用者
     */
    async CreateInternalUser() {
      let isValid = false;
      await this.FormValidation(this.$refs.internalUserFormRef).then(
        (result) => {
          isValid = result;
        }
      );

      if (!isValid) {
        ElNotification({
          title: "新增帳號",
          message: "尚有必填欄位未填寫，請確認!",
          duration: 3000,
          type: "error",
        });
        return;
      } else if (
        this.internalUserFormData.password == "" ||
        this.internalUserFormData.password == null
      ) {
        ElNotification({
          title: "新增帳號",
          message: "請填入密碼!",
          duration: 3000,
          type: "error",
        });
        return;
      }

      this.internalUserCreateEntity.name = this.internalUserFormData.name
      this.internalUserCreateEntity.password = this.internalUserFormData.password
      this.internalUserCreateEntity.account = this.internalUserFormData.account
      this.internalUserCreateEntity.email = this.internalUserFormData.email
      this.internalUserCreateEntity.roleId = this.internalUserFormData.roleId

      this.loadings.createInternalUser = true;

      await CreateInternalUser(this.internalUserCreateEntity)
        .then((result: any) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "帳號新增失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            ElNotification({
              title: "新增帳號",
              message: "新增帳號成功",
              duration: 3000,
              type: "success",
            });

            // redirect to edit page
            this.$router.push({
              name: "InternalUserEdit",
              params: { id: result.data.data },
            });
          }
        })
        .catch((err) => {
          ElNotification({
            title: "帳號新增失敗",
            message: `帳號新增失敗!`,
            duration: 3000,
            type: "error",
          });

          throw new Error(err);
        })
        .finally(() => {
          this.loadings.createInternalUser = false;
        });
    },
    /**
     * 更新使用者
     */
    async UpdateInternalUser() {
      let isValid = false;
      await this.FormValidation(this.$refs.internalUserFormRef).then(
        (result) => {
          isValid = result;
        }
      );

      if (!isValid) {
        ElNotification({
          title: "更新客戶",
          message: "尚有必填欄位未填寫，請確認!",
          duration: 3000,
          type: "error",
        });
        return;
      }

      this.internalUserUpdateEntity.id = this.id;
      this.internalUserUpdateEntity.name = this.internalUserFormData.name;
      this.internalUserUpdateEntity.email = this.internalUserFormData.email;
      this.internalUserUpdateEntity.password = this.internalUserFormData.password;
      this.internalUserUpdateEntity.roleId = this.internalUserFormData.roleId;
      this.internalUserUpdateEntity.status = this.internalUserFormData.status;
      
      this.loadings.updateInternalUser = true;

      await UpdateInternalUser(this.internalUserUpdateEntity)
        .then((result: any) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "帳號更新失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            ElNotification({
              title: "更新帳號",
              message: "更新帳號成功",
              duration: 3000,
              type: "success",
            });

            this.LoadData();
          }
        })
        .catch((err) => {
          ElNotification({
            title: "帳號更新失敗",
            message: `帳號更新失敗!`,
            duration: 3000,
            type: "error",
          });

          throw new Error(err);
        })
        .finally(() => {
          this.loadings.updateInternalUser = false;
        });
    },
    ClearInternalUserData() {
      this.internalUserCreateEntity = {
        name: "",
        account: "",
        password: "",
        email: "",
      } as InternalUserCreateEntity;
    },
  },
});
