import { ApiResponseEntity, PaginationEntity } from "@/models/ApiModels";
import { PointHistorySearchEntity, PointHistoryCreateEntity, PointHistoryUpdateEntity, PointHistoryDetailEntity, PointHistoryListEntity } from "@/models/backstages/PointHistoryModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService"

/**
 * TODO: 短期維運使用點數計算
 * @param startDateTime 
 * @returns 
 */
export async function InstantCalcPoint(startDateTime: Date): Promise<AxiosResponse<ApiResponseEntity<null>, any>> {
    const url = "/api/backstage/PointHistory/InstantCalcPoint";
    const result = await Post<object, null>(url, { startDateTime: startDateTime });

    return result;
}

/**
 * 根據搜尋條件取得 PointHistory 清單
 * @param entity 搜尋 entity 
 * @returns PointHistory 清單
 */
export async function SearchPointHistory(entity: PointHistorySearchEntity): Promise<AxiosResponse<ApiResponseEntity<PaginationEntity<PointHistoryListEntity>>, any>> {
    const url = "/api/backstage/PointHistory/Search";
    const result = await Post<object, PaginationEntity<PointHistoryListEntity>>(url, entity);

    return result;
}

/** 
 * 根據點數歷程 ID 取得 PointHistory
 * */
export async function GetPointHistoryById(id: number): Promise<AxiosResponse<ApiResponseEntity<PointHistoryDetailEntity>, any>> {
    const url = "/api/backstage/PointHistory/GetById/" + id;
    const result = await Post<object, PointHistoryDetailEntity>(url, null!);

    return result;
}

/** 
 * 建立點數歷程
 * */
export async function CreatePointHistory(entity: PointHistoryCreateEntity): Promise<AxiosResponse<ApiResponseEntity<number>, any>> {
    const url = "/api/backstage/PointHistory/Create";
    const result = await Post<object, number>(url, entity);

    return result;
}

/** 
 * 批次建立 PointHistory
 * */
export async function BatchCreatePointHistory(list: Array<PointHistoryCreateEntity>): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
    const url = "/api/backstage/PointHistory/BatchCreate";
    const result = await Post<Array<PointHistoryCreateEntity>, any>(url, list);

    return result;
}

/** 
 * 更新 PointHistory
 * */
export async function UpdatePointHistory(entity: PointHistoryUpdateEntity): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
    const url = "/api/backstage/PointHistory/Update";
    const result = await Post<PointHistoryUpdateEntity, any>(url, entity);

    return result;
}

/** 
 * 批次更新 PointHistory
 * */
export async function BatchUpdatePointHistory(list: Array<PointHistoryUpdateEntity>): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
    const url = "/api/backstage/PointHistory/BatchUpdate";
    const result = await Post<Array<PointHistoryUpdateEntity>, any>(url, list);

    return result;
}

/** 
 * 刪除 PointHistory
 * */
 export async function DeletePointHistory(id: number): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
    const url = "/api/backstage/PointHistory/Delete/" + id;
    const result = await Post<object, any>(url, null!);

    return result;
}