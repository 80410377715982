export default class RegexPattern {
    /**
     * Regular expression for validating `Phone`
     */
    public static readonly phone: RegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

    /**
     * Regular expression for validating `Cellphone`
     * e.g. 
     * Valid: 0987654321, 0987654321
     * Invalid: 9876543210, a098765432
     */
    public static readonly cellPhone: RegExp = /^0[0-9]{9}$/;

    /**
     * Regular expression for validating `Account`
     */
    public static readonly account: RegExp = /^[0-9a-zA-Z]{3,50}$/;

    /**
     * Regular expression for validating `Password`
     * e.g.
     * Valid: 123456, 1234567`!@#$%^&*+=$, P@ssW0rd
     */
    public static readonly password: RegExp = /^[\w~`!@#$%^&*+=-]{6,20}$/;

    /**
     * Regular expression for validating `Email`
     */
    public static readonly email: RegExp = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/; 
    
}