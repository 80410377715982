import { ApiResponseEntity } from "@/models/ApiModels";
import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import { parseISO } from "date-fns";
import { ElMessageBox, ElNotification } from "element-plus";
import { useStorePersistStore } from "../../store/StorePersistStore";
import { useBackStagePersistStore } from "../../store/BackStagePersistStore";
import { useAdminPersistStore } from "../../store/AdminPersistStore";
import jwt_decode from "jwt-decode";
import { UserTackBaseEntity } from "@/models/commons/CommonModels";
import {
  ExceptionCodeEnum,
  IssueTypeEnum,
} from "@/models/commons/enums/CommonEnums";
import { GetMenu } from "../backstages/ViewService";

let isAlert = false;

// error status code need to exclude
const excludeStatusCode = [401, 403];

// check is iso date string
function IsIsoDateString(value: any): boolean {
  const isoDateFormat =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

  return value && typeof value === "string" && isoDateFormat.test(value);
}

// parse date string to date type
function HandleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object") {
    return body;
  }

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (IsIsoDateString(value)) {
      body[key] = parseISO(value);
    } else if (typeof value === "object") {
      HandleDates(value);
    }
  }
}

// get token by route
function GetTokenByRoute(url: string): string {
  const stage = url.split("/")[2];
  let token = "";

  if (stage == undefined) {
    return "";
  }

  switch (stage.toLowerCase()) {
    case "store": {
      const store = useStorePersistStore();
      token = store.jwtToken;
      break;
    }

    case "backstage": {
      const store = useBackStagePersistStore();
      token = store.jwtToken;
      break;
    }

    case "admin": {
      const store = useAdminPersistStore();
      token = store.jwtToken;
      break;
    }

    default:
      break;
  }

  return token;
}

//401 handler
function HandleUnauthorize(config: AxiosRequestConfig) {
  const headers = config.headers as AxiosRequestHeaders;

  const token = headers.Authorization.replace("Bearer", "").trim();

  const payload = jwt_decode<UserTackBaseEntity>(token);

  switch (payload.issueType) {
    case IssueTypeEnum.Store: {
      const store = useStorePersistStore();
      store.resetPermission();
      break;
    }

    case IssueTypeEnum.BackStage: {
      const store = useBackStagePersistStore();
      store.resetPermission();
      break;
    }

    case IssueTypeEnum.Admin: {
      const store = useAdminPersistStore();
      console.log("Admin");
      store.setJWT("");
      break;
    }
  }

  window.location.reload();
}

async function HandleClaimsChange(config: AxiosRequestConfig) {
  const res = await axios.post("/api/Auth/Renew", {}, config);

  if (res.status > 200) {
    ElMessageBox.alert("刷新權限失敗，請重新登入", "刷新權限失敗", {
      confirmButtonText: "確定",
      type: "info",
      center: true,
      customClass: "top-center-message-box",
    }).then(function () {
      HandleUnauthorize(config);
    });
  } else {
    const header = res.headers.authorization as string;
    const token = header.replace("Bearer", "");

    const payload = jwt_decode<UserTackBaseEntity>(token);

    switch (payload.issueType) {
      case IssueTypeEnum.Store: {
        const store = useStorePersistStore();
        store.setPermission(token);
        break;
      }

      case IssueTypeEnum.BackStage: {
        const store = useBackStagePersistStore();
        store.setPermission(token);

        const res = await GetMenu();
        store.setMenu(res.data.data);
        break;
      }

      case IssueTypeEnum.Admin: {
        const store = useAdminPersistStore();
        store.setJWT(token);
        break;
      }

      default:
        console.log("invalid issue type");
    }

    window.location.reload();
  }
}

export async function Post<T, TResult>(
  url: string,
  params: T,
  cfg: any = null
): Promise<AxiosResponse<ApiResponseEntity<TResult>, any>> {
  // response interceptor
  axios.interceptors.response.use((originalResponse) => {
    HandleDates(originalResponse.data);
    return originalResponse;
  });

  const token = GetTokenByRoute(url);

  // setting config with auth header
  const config =
    cfg == null
      ? token === ""
        ? {
            withCredentials: true,
          }
        : {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
      : cfg;

  try {
    const res = await axios.post(url, params, config);
    return res;
  } catch (error: any) {
    if (error.response.status == 403) {
      ElNotification({
        title: "使用者無權限",
        message: error.response.data.errorMessage,
        type: "error",
      });
    } else if (error.response.status == 401 && isAlert == false) {
      isAlert = true;

      ElMessageBox.alert(error.response.data.errorMessage, "", {
        confirmButtonText: "確定",
        type: "info",
        center: true,
        customClass: "top-center-message-box",
      }).then(async function () {
        switch (error.response.data.errorCode) {
          case ExceptionCodeEnum.ClaimsChange:
            await HandleClaimsChange(config);
            break;

          case ExceptionCodeEnum.UnAuthorized:
            HandleUnauthorize(config);
            break;
        }
      });
    }

    return error.response;
  }
}

export function GetAuthorizationHeaderByRoute(
  params: string
): Record<string, any> {
  const token = GetTokenByRoute(params);

  return { Authorization: `Bearer ${token}` } as Record<string, any>;
}

// is call api fail and need other error handling
export function FailAndNeedHandle(res: AxiosResponse): boolean {
  if (res.status > 200 && excludeStatusCode.includes(res.status) == false) {
    return true;
  }

  return false;
}
