import { ApiResponseEntity, PaginationEntity } from "@/models/ApiModels";
import {
    ExternalBusinessSearchEntity,
    ExternalBusinessResponseEntity,
    ExternalBusinessListResponseEntity,
    ExternalBusinessCreateEntity,
    ExternalBusinessUpdateEntity,
    ExternalBusinessAndUserCreateEntity,
    ExternalBusinessAndUserUpdateEntity
} from "@/models/backstages/ExternalBusinessModel";
import { DropdownEntity } from "@/models/commons/CommonModels";

import { ExcelExportRequestEntity, ExcelImportResponseEntity } from "@/models/commons/CommonModels";
import { useBackStagePersistStore } from "@/store/BackStagePersistStore";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService"

/** 
 * 條件搜尋客戶清單
 * */
export async function SearchExternalBusiness(request: ExternalBusinessSearchEntity): Promise<AxiosResponse<ApiResponseEntity<PaginationEntity<ExternalBusinessListResponseEntity>>, any>> {

    const url = "/api/backstage/ExternalBusiness/Search";
    const result = await Post<object, PaginationEntity<ExternalBusinessListResponseEntity>>(url, request);

    return result;
}

/** 
 * 建立客戶
 * 
 * @return 客戶 id
 * */
export async function CreateExternalBusiness(entity: ExternalBusinessCreateEntity): Promise<AxiosResponse<ApiResponseEntity<number>, any>> {

    const url = "/api/backstage/ExternalBusiness/Create";
    const result = await Post<object, number>(url, entity);

    return result;
}

/** 
 * 建立客戶 & 使用者帳號
 * */
export async function CreateExternalBusinessAndUser(entity: ExternalBusinessAndUserCreateEntity) : Promise<AxiosResponse<ApiResponseEntity<number>, any>> {
    
    const url = "/api/backstage/ExternalBusiness/CreateExternalBusinessAndUser";
    const result = await Post<object, number>(url, entity);

    return result;
}

/** 
 * 更新客戶 & 使用者帳號 & 新增使用者帳號
 * */
export async function UpdateExternalBusinessAndUser(entity: ExternalBusinessAndUserUpdateEntity) : Promise<AxiosResponse<ApiResponseEntity<number>, any>> {
    
    const url = "/api/backstage/ExternalBusiness/UpdateExternalBusinessAndUser";
    const result = await Post<object, number>(url, entity);

    return result;
}

/** 
 * 更新客戶
 * */
export function UpdateExternalBusiness(entity: ExternalBusinessUpdateEntity): Promise<AxiosResponse<ApiResponseEntity<any>>> {

    const url = "/api/backstage/ExternalBusiness/Update";
    const result = Post<object, any>(url, entity);

    return result;
}

/** 
 * 根據 客戶 id 取得 客戶 資訊
 * */
export async function GetExternalBusinessById(id: number): Promise<AxiosResponse<ApiResponseEntity<ExternalBusinessResponseEntity>, any>> {
    const url = "/api/backstage/ExternalBusiness/GetById/" + id;
    const result = await Post<object, ExternalBusinessResponseEntity>(url, null!);

    return result;
}

/** 
 * 移除客戶
 * */
export async function DeleteExternalBusiness(id: number): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
    const url = "/api/backstage/ExternalBusiness/Delete/" + id;
    const result = await Post<object, any>(url, null!);

    return result;
}

/**
 * 根據客戶查詢條件，輸出客戶資料為 Excel Stream
 * @param entity ExcelExportRequestEntity<ExternalBusinessSearchEntity>
 * @returns Stream
 */
export async function ExportExtBusinessAsExcel(entity: ExcelExportRequestEntity<ExternalBusinessSearchEntity>): Promise<AxiosResponse<ApiResponseEntity<any>>> {
    const store = useBackStagePersistStore();
    const token = store.jwtToken;
    const config: any = { withCredential: true, responseType: 'blob', headers: { 'Authorization': `Bearer ${token}` } };
    const url = "/api/backstage/ExternalBusiness/ExportExternalBusinessAsExcel";
    const result = await Post<object, any>(url, entity, config);

    return result;
}

/**
 * 匯入客戶資料
 * @param entity any
 * @returns ExcelImportResponseEntity
 */
export async function ImportExtBusiness(entity: any): Promise<AxiosResponse<ApiResponseEntity<ExcelImportResponseEntity>>> {
    const url = "/api/backstage/ExternalBusiness/Import";
    const result = await Post<object, any>(url, entity);

    return result;
}

/**
 * 取得企業下拉清單
 * @param labelFilter 文字過濾
 * @returns 企業下拉清單
 */
 export async function GetExternalBusinessDropdownList(labelFilter: string): Promise<AxiosResponse<ApiResponseEntity<DropdownEntity>>> {

    const url = "/api/backstage/ExternalBusiness/GetExternalBusinessDropdownList?labelFilter=" + labelFilter;
    const result = await Post<object, DropdownEntity>(url, null!);

    return result;
}