import { ApiResponseEntity } from "@/models/ApiModels";
import {
  SkuSearchEntity,
  SkuResponseEntity,
  SkuCreateEntity,
  SkuUpdateEntity,
  SkuUpdateStatusEntity,
  OuterSysCodeEntity,
} from "@/models/backstages/SkuModels";
import {
  DropdownEntity,
  FileUploadResultEntity,
  ExcelExportRequestEntity,
  ExcelImportResponseEntity,
} from "@/models/commons/CommonModels";
import { useBackStagePersistStore } from "@/store/BackStagePersistStore";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService";

/**
 * 根據搜尋條件取得 sku 清單
 * @param entity 搜尋 entity
 * @returns sku 清單
 */
export async function SearchSku(
  entity: SkuSearchEntity
): Promise<AxiosResponse<ApiResponseEntity<Array<SkuResponseEntity>>, any>> {
  const url = "/api/backstage/Sku/Search";
  const result = await Post<object, Array<SkuResponseEntity>>(url, entity);

  return result;
}

/**
 * 根據 product id 取得 sku 清單
 * */
export async function GetSkuListByProductId(
  productId: number
): Promise<AxiosResponse<ApiResponseEntity<Array<SkuResponseEntity>>, any>> {
  const url = "/api/backstage/Sku/GetListByProductId/" + productId;
  const result = await Post<object, Array<SkuResponseEntity>>(url, null!);

  return result;
}

/**
 * 模糊查詢 外部系統代碼 取得 sku 下拉清單
 * */
export async function GetSkuDropdownList(
  outerSysCode: string
): Promise<AxiosResponse<ApiResponseEntity<Array<DropdownEntity>>, any>> {
  const url =
    "/api/backstage/Sku/GetSkuDropdownList?outerSysCode=" + outerSysCode;
  const result = await Post<object, Array<DropdownEntity>>(url, null!);

  return result;
}

/**
 * 根據 sku id 取得 sku 資料
 * */
export async function GetSkuById(
  skuId: number
): Promise<AxiosResponse<ApiResponseEntity<SkuResponseEntity>, any>> {
  const url = "/api/backstage/Sku/GetById/" + skuId;
  const result = await Post<object, SkuResponseEntity>(url, null!);

  return result;
}

/**
 * 根據 外部系統代碼 取得 sku 資料
 * */
export async function GetSkuByOuterSysCode(
  outerSysCode: string
): Promise<AxiosResponse<ApiResponseEntity<SkuResponseEntity>, any>> {
  const params = {
    outerSysCode: outerSysCode,
  } as OuterSysCodeEntity;
  const url = "/api/backstage/Sku/GetByOuterSysCode";
  const result = await Post<object, SkuResponseEntity>(url, params);

  return result;
}

export async function CheckSkuOuterSysCodeDuplication(
  entity: OuterSysCodeEntity
): Promise<AxiosResponse<ApiResponseEntity<boolean>, any>> {
  
  const url = "/api/backstage/Sku/CheckOuterSysCodeDuplication";
  const result = await Post<OuterSysCodeEntity, boolean>(url, entity);
  
  return result;
}

/**
 * 建立 sku
 * */
export async function CreateSku(
  entity: SkuCreateEntity
): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
  const url = "/api/backstage/Sku/Create";
  const result = await Post<object, any>(url, entity);

  return result;
}

/**
 * 批次建立 sku
 * */
export async function BatchCreateSku(
  list: Array<SkuCreateEntity>
): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
  const url = "/api/backstage/Sku/BatchCreate";
  const result = await Post<Array<SkuCreateEntity>, any>(url, list);

  return result;
}

/**
 * 上傳 Sku 圖片
 * */
export async function UploadSkuImage(
  data: any
): Promise<AxiosResponse<ApiResponseEntity<FileUploadResultEntity>, any>> {
  const url = "/api/backstage/Sku/Upload";
  const result = await Post<object, FileUploadResultEntity>(url, data);

  return result;
}

/**
 * 更新 sku
 * */
export async function UpdateSku(
  entity: SkuUpdateEntity
): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
  const url = "/api/backstage/Sku/Update";
  const result = await Post<SkuUpdateEntity, any>(url, entity);

  return result;
}

/**
 * 批次更新 sku
 * */
export async function BatchUpdateSku(
  list: Array<SkuUpdateEntity>
): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
  const url = "/api/backstage/Sku/BatchUpdate";
  const result = await Post<Array<SkuUpdateEntity>, any>(url, list);

  return result;
}

/**
 * 更新 sku 狀態
 * */
export async function UpdateSkuStatus(
  entity: SkuUpdateStatusEntity
): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {
  const url = "/api/backstage/Sku/UpdateStatus";
  const result = await Post<SkuUpdateStatusEntity, any>(url, entity);

  return result;
}

/**
 * 根據查詢條件，輸出資料為 Excel Stream
 * @param entity ExcelExportRequestEntity<SkuSearchEntity>
 * @returns Stream
 */
export async function ExportSkuAsExcel(
  entity: ExcelExportRequestEntity<SkuSearchEntity>
): Promise<AxiosResponse<ApiResponseEntity<any>>> {
  const store = useBackStagePersistStore();
  const token = store.jwtToken;

  const config: any = {
    withCredential: true,
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  };

  const url = "/api/backstage/Sku/ExportSkuAsExcel";
  const result = await Post<object, any>(url, entity, config);

  return result;
}

/**
 * 匯入Excel檔並進行更新
 * @returns Stream
 */
export async function ImportAndBatchUpdateSku(): Promise<
  AxiosResponse<ApiResponseEntity<ExcelImportResponseEntity>>
> {
  const url = "/api/backstage/Sku/ImportAndBatchUpdate";
  const result = await Post<object, any>(url, {});

  return result;
}
