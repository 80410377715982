import { ApiResponseEntity } from "@/models/ApiModels";
import { KeyValuePairEntity } from "@/models/commons/CommonModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService"

const prefix = "/api/store"

export async function GetHashTagIdAndNameList(): Promise<AxiosResponse<ApiResponseEntity<Array<KeyValuePairEntity>>, any>> {

    const url = prefix + "/HashTag/GetIdAndNameList";

    const result = await Post<object, Array<KeyValuePairEntity>>(url, {});

    return result;
}