
import { defineComponent } from "vue";
import { debounce } from "vue-debounce";
import { ElNotification } from "element-plus";
import ElCurrencyInput from "../../../components/commons/ElCurrencyInput.vue";
import { DefinitionSearchEntity } from "../../../models/commons/DefinitionModels";
import { SearchOrder } from "../../../services/backstages/OrderService";
import { GetExternalBusinessDropdownList } from "../../../services/backstages/ExternalBusinessService";
import { GetInternalUserDropdownList } from "../../../services/backstages/InternalUserService";
import {
  OrderSearchEntity,
  OrderListResponseEntity,
} from "../../../models/backstages/OrderModels";
import { GetDefinitionDropdownList } from "../../../services/DefinitionService";
import { FailAndNeedHandle } from "../../../services/commons/CommonService";

export default defineComponent({
  name: "OrderListPicker",
  components: {
    ElCurrencyInput,
  },
  data() {
    return {
      isOrderDataLoading: false,
      isSpuStatusChanged: false,
      isOpenFilter: false,
      isOpenMenu: false,
      isOpenOrder: false,
      orderSearchEntity: {
        take: 10,
        skip: 0,
        code: "",
        status: "",
        skuCode: "",
        totalPriceStart: null!,
        totalPriceEnd: null!,
        shippingDateTimeStart: null!,
        shippingDateTimeEnd: null!,
        orderDateTimeStart: null!,
        orderDateTimeEnd: null!,
        finishDateTimeStart: null!,
        finishDateTimeEnd: null!,
        externalBusinessId: null!,
        externalBusinessName: "",
        externalUserId: null!,
        externalUserName: "",
        salesPersonId: null!,
        salesPersonName: "",
        orderCondition: {
          name: "Id",
          isAscending: false,
        },
      } as OrderSearchEntity,
      filterEntity: {
        launchDateStart: new Date(),
        launchDateEnd: new Date(),
        highestSellingPrice: undefined,
        lowestSellingPrice: undefined,
      },
      tableData: [] as Array<OrderListResponseEntity>,

      /**
       * 下拉清單
       */
      externalBusinessDropdownList: [] as any,
      orderStatusDropdownList: [] as any,
      internalUserDropdownList: [] as any,

      /**
       * Pagination vars
       */
      currentPage: 1,
      totalPage: 0,
      currentCount: 0,
      totalCount: 0,
      pageSize: 10,
      pageSizes: [10, 25, 50, 100],

      currentOrderRow: {} as OrderListResponseEntity,
    };
  },
  created() {
    this.ClearData();
  },
  mounted() {
    this.LoadData();
  },
  computed: {
    filterBtnText(): string {
      let text = "篩選";

      let filterCount = 0;
      if (this.orderSearchEntity.status != "") {
        filterCount++;
      }

      if (
        this.orderSearchEntity.totalPriceStart != undefined ||
        this.orderSearchEntity.totalPriceEnd != undefined
      ) {
        filterCount++;
      }

      if (
        this.orderSearchEntity.orderDateTimeStart != undefined ||
        this.orderSearchEntity.orderDateTimeEnd != undefined
      ) {
        filterCount++;
      }

      if (
        this.orderSearchEntity.shippingDateTimeStart != undefined ||
        this.orderSearchEntity.shippingDateTimeEnd != undefined
      ) {
        filterCount++;
      }

      if (
        this.orderSearchEntity.finishDateTimeStart != undefined ||
        this.orderSearchEntity.finishDateTimeEnd != undefined
      ) {
        filterCount++;
      }

      if (this.orderSearchEntity.code != "") {
        filterCount++;
      }

      if (this.orderSearchEntity.skuCode != "") {
        filterCount++;
      }

      if (this.orderSearchEntity.externalBusinessId != undefined) {
        filterCount++;
      }

      if (this.orderSearchEntity.externalUserName != "") {
        filterCount++;
      }

      if (this.orderSearchEntity.salesPersonId != undefined) {
        filterCount++;
      }

      if (filterCount != 0) {
        text = text + "(" + filterCount + ")";
      }

      return text;
    },
  },
  methods: {
    ClearConditionFilterData() {
      this.orderSearchEntity = {
        take: 10,
        skip: 0,
        code: "",
        status: "",
        skuCode: "",
        totalPriceStart: null!,
        totalPriceEnd: null!,
        shippingDateTimeStart: null!,
        shippingDateTimeEnd: null!,
        orderDateTimeStart: null!,
        orderDateTimeEnd: null!,
        finishDateTimeStart: null!,
        finishDateTimeEnd: null!,
        externalBusinessId: null!,
        externalUserName: "",
        salesPersonId: null!,
        orderCondition: {
          name: "Id",
          isAscending: false,
        },
      };
    },
    ClearData() {
      this.ClearConditionFilterData();
      this.currentOrderRow = {} as any;
    },
    LoadData() {
      this.LoadDropdownList();
      this.SearchOrderData();
    },
    LoadDropdownList() {
      let searchEntity: DefinitionSearchEntity = {
        tableName: "orders",
        columnName: "status",
      };

      GetDefinitionDropdownList(searchEntity)
        .then((result) => {
          this.orderStatusDropdownList = result.data.data;
        })
        .catch((err) => {
          ElNotification({
            title: "訂單狀態清單載入失敗",
            message: err,
            duration: 3000,
            type: "error",
          });
        });

      this.LoadExternalBusinessDropdownList("");
      this.LoadInternalUserDropdownList();
    },
    LoadExternalBusinessDropdownList(query: string) {
      debounce(() => {
        GetExternalBusinessDropdownList(query)
          .then((result) => {
            if (FailAndNeedHandle(result)) {
              ElNotification({
                title: "客戶清單載入失敗",
                message: result.data.errorMessage,
                duration: 3000,
                type: "error",
              });
            } else {
              this.externalBusinessDropdownList = result.data.data;
            }
          })
          .catch(() => {
            console.error("Load LoadExternalBusinessDropdownList error");
          });
      }, "400ms")();
    },
    LoadInternalUserDropdownList() {
      GetInternalUserDropdownList()
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "負責業務清單載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            this.internalUserDropdownList = result.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    ApplyConditionFilter() {
      this.isOpenFilter = false;
      this.SearchOrderData();
    },
    ClearConditionFilter() {
      this.ClearConditionFilterData();
    },
    SearchOrderData() {
      this.isOrderDataLoading = true;

      this.orderSearchEntity.skip = this.pageSize * (this.currentPage - 1);
      this.orderSearchEntity.take = this.pageSize;

      SearchOrder(this.orderSearchEntity)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "訂單載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let paginationResult = result.data.data;
            this.tableData = paginationResult.data;
            this.currentCount = paginationResult.currentCount;
            this.totalCount = paginationResult.totalCount;
            this.totalPage = paginationResult.totalPage;
          }
        })
        .catch(() => {
          ElNotification({
            title: "訂單載入失敗",
            message: "訂單載入失敗",
            duration: 3000,
            type: "error",
          });
        })
        .finally(() => {
          this.isOrderDataLoading = false;
        });
    },
    OpenUrl(url: string) {
      window.open(url, "_blank");
    },
    OpenFilter() {
      this.isOpenFilter = true;
    },
    OpenMenu() {
      this.isOpenMenu = true;
    },
    OpenOrder() {
      this.isOpenOrder = true;
    },
    DateFormatter(row: any, column: any) {
      let d = new Date();

      if (column.property == "orderDateTime") {
        if (row.orderDateTime == null) return "-";
        d = new Date(row.orderDateTime);
      } else if (column.property == "finishDateTime") {
        if (row.finishDateTime == null) return "-";
        d = new Date(row.finishDateTime);
      }

      let mm = d.getMonth() + 1;
      let dd = d.getDate();
      let h = d.getHours();
      let m = d.getMinutes();
      return `${d.getFullYear()}/${(mm > 9 ? "" : "0") + mm}/${
        (dd > 9 ? "" : "0") + dd
      } ${(h > 9 ? "" : "0") + h}:${(m > 9 ? "" : "0") + m}`;
    },
    HandleSizeChange(val: number) {
      this.pageSize = val;
      this.SearchOrderData();
    },
    HandleCurrentChange(val: number) {
      this.currentPage = val;
      this.SearchOrderData();
    },
    HandleSelectItemClear(elementName: any, element: any) {
      element[elementName] = undefined!;
    },
    HandleSortChange(column: any) {
      if (column.column !== null) {
        this.orderSearchEntity.orderCondition.name = this.CapitalizeFirstLetter(
          column.prop
        );
        this.orderSearchEntity.orderCondition.isAscending =
          column.order == "ascending";
      } else {
        this.orderSearchEntity.orderCondition.name = "";
        this.orderSearchEntity.orderCondition.isAscending = true;
      }

      this.SearchOrderData();
    },
    /**
     * 將英文的首字母轉換成大寫
     * @param str 要轉換的字串
     * @returns 首字母大寫
     */
    CapitalizeFirstLetter(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    HandleCurrentRowChange(val: OrderListResponseEntity) {
      this.currentOrderRow = val;
    },
    HandleConfirm() {
      if (
        this.currentOrderRow.id == undefined ||
        this.currentOrderRow.id == null
      ) {
        ElNotification({
          title: "選擇訂單",
          message: "請點選 1 筆訂單",
          duration: 2000,
          type: "error",
        });
        return;
      } else {
        this.$emit("confirm", this.currentOrderRow);
        this.ClearData();
      }
    },
  },
});
