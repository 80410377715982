import { ApiResponseEntity } from "@/models/ApiModels";
import { IdEntity } from "@/models/commons/CommonModels";
import { InternalBusinessShippingProfileInfoEntity } from "@/models/stores/InternalBusinessShippingProfileModels";
import {
  AddShoppingCartEntity,
  GiftInfoEntity,
  GiftListEntity,
  GiftListResultEntity,
  ShoppingCartEntity,
  ShoppingCartItemEntity,
  ShoppingCartQtyEntity,
  ShoppingCartRedeemItemEntity,
  SyncGiftEntity,
  UpdateShoppingCartEntity,
  UpdateShoppingCartItemEntity,
} from "@/models/stores/ShoppingCartModels";

import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService";

const prefix = "/api/store";

export async function GetShoppingCart(): Promise<
  AxiosResponse<ApiResponseEntity<ShoppingCartEntity>, any>
> {
  const url = prefix + "/ShoppingCart/GetShoppingCart";

  const result = await Post<object, ShoppingCartEntity>(url, {});

  return result;
}

export async function AddShoppingCart(
  params: AddShoppingCartEntity
): Promise<AxiosResponse<ApiResponseEntity<ShoppingCartQtyEntity>, any>> {
  const url = prefix + "/ShoppingCart/AddShoppingCart";

  const result = await Post<AddShoppingCartEntity, ShoppingCartQtyEntity>(
    url,
    params
  );

  return result;
}

export async function BatchAddShoppingCart(
  params: Array<AddShoppingCartEntity>
): Promise<AxiosResponse<ApiResponseEntity<ShoppingCartQtyEntity>, any>> {
  const url = prefix + "/ShoppingCart/BatchAddShoppingCart";

  const result = await Post<
    Array<AddShoppingCartEntity>,
    ShoppingCartQtyEntity
  >(url, params);

  return result;
}

export async function DeleteShoppingCart(
  params: ShoppingCartItemEntity
): Promise<AxiosResponse<ApiResponseEntity<ShoppingCartQtyEntity>, any>> {
  const url = prefix + "/ShoppingCart/DeleteShoppingCart";

  const result = await Post<ShoppingCartItemEntity, ShoppingCartQtyEntity>(
    url,
    params
  );

  return result;
}

export async function UpdateItemPurchaseQuantity(
  params: UpdateShoppingCartItemEntity
): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {
  const url = prefix + "/ShoppingCart/UpdateItemPurchaseQuantity";

  const result = await Post<UpdateShoppingCartItemEntity, object>(url, params);

  return result;
}

export async function UpdateShoppingCart(
  params: UpdateShoppingCartEntity
): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {
  const url = prefix + "/ShoppingCart/UpdateShoppingCart";

  const result = await Post<UpdateShoppingCartEntity, object>(url, params);

  return result;
}

export async function ReproduceByOrderId(
  params: IdEntity
): Promise<AxiosResponse<ApiResponseEntity<ShoppingCartQtyEntity>, any>> {
  const url = prefix + "/ShoppingCart/ReproduceByOrderId";

  const result = await Post<IdEntity, ShoppingCartQtyEntity>(url, params);

  return result;
}

export async function RedeemShoppingCartItem(
  params: ShoppingCartRedeemItemEntity
): Promise<AxiosResponse<ApiResponseEntity<ShoppingCartQtyEntity>, any>> {
  const url = prefix + "/ShoppingCart/RedeemShoppingCartItem";

  const result = await Post<
    ShoppingCartRedeemItemEntity,
    ShoppingCartQtyEntity
  >(url, params);

  return result;
}

export async function GetCartQty(): Promise<
  AxiosResponse<ApiResponseEntity<ShoppingCartQtyEntity>, any>
> {
  const url = prefix + "/ShoppingCart/GetCartQty";

  const result = await Post<object, ShoppingCartQtyEntity>(url, {});

  return result;
}

export async function GetGiftListByDiscountActivityId(
  params: IdEntity
): Promise<AxiosResponse<ApiResponseEntity<GiftListResultEntity>, any>> {
  const url = prefix + "/ShoppingCart/GetGiftListByDiscountActivityId";

  const result = await Post<IdEntity, GiftListResultEntity>(url, params);

  return result;
}

export async function SyncGift(
  params: SyncGiftEntity
): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {
  const url = prefix + "/ShoppingCart/SyncGift";

  const result = await Post<SyncGiftEntity, object>(url, params);

  return result;
}

export async function GetShippingProfileList(): Promise<AxiosResponse<ApiResponseEntity<Array<InternalBusinessShippingProfileInfoEntity>>, any>> {
  const url = prefix + "/ShoppingCart/GetShippingProfileList";

  const result = await Post<null, Array<InternalBusinessShippingProfileInfoEntity>>(url, null!);

  return result;
}

