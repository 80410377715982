
import { defineComponent } from "vue";
import Navbar from "../components/Navbar.vue";
import Sidebar from "../components/Sidebar.vue";
import { useBackStagePersistStore } from "../../../store/BackStagePersistStore";
import { mapStores } from "pinia";
import { GetInternalBizId } from "../../../services/auth/AuthService";
import { FailAndNeedHandle } from "../../../services/commons/CommonService";

export default defineComponent({
  name: "BackStageLayout",
  components: {
    Navbar,
    Sidebar,
  },
  data() {
    return {
      isShowSideBar: true,
    };
  },
  methods: {
    toggleSideBar() {
      this.isShowSideBar = !this.isShowSideBar;
    },
    isShowLayoutContent() {
      const notShowList = ["BackStageLogin"];

      const currentRouteName = this.$route.name as string;
      return notShowList.indexOf(currentRouteName) == -1 ? true : false;
    },
  },
  computed: {
    ...mapStores(useBackStagePersistStore),
  },

  async created() {
    const res = await GetInternalBizId();

    if (FailAndNeedHandle(res)) {
      return;
    }

    this.backStagePersistStore.setInternalBizId(res.data.data.id);
  }
});
