import { ApiResponseEntity, PaginationEntity } from "@/models/ApiModels";
import { GenericEntity } from "@/models/commons/CommonModels";
import { AxiosResponse } from "axios";
import { Post } from "../commons/CommonService";
import { NotificationEntity } from "@/models/backstages/NotificationModels";

/**
 * 同步訊息
 * */
export async function SyncNotificationData(): Promise<
  AxiosResponse<ApiResponseEntity<NotificationEntity[]>, any>
> {
  const url = "/api/backstage/Notification/SyncNotificationData";
  const result = await Post<object, NotificationEntity[]>(url, {});

  return result;
}

/**
 * 訊息設為已讀
 * */
export async function SetMsgRead(): Promise<
  AxiosResponse<ApiResponseEntity<any>, any>
> {
  const url = "/api/backstage/Notification/SetMsgRead";
  const result = await Post<object, NotificationEntity[]>(url, {});

  return result;
}
