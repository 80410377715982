
import { defineComponent } from "vue";
import {
  GetStoreProductByFuzzySearch,
  GetStoreProductList,
} from "../../services/stores/ProductService";

import { AddShoppingCart } from "../../services/stores/ShoppingCartService";
import { GetHashTagIdAndNameList } from "../../services/stores/HashTagService";

import {
  ProductSearchEntity,
  ProductSearchTemplateEntity,
  StoreProductDetailEntity,
  StoreProductFuzzySearchEntity,
  StoreProductListEntity,
  StoreStockKeepingUnitListEntity,
} from "../../models/stores/ProductModels";
import { SearchCriteriaEntity } from "../../models/ApiModels";
import { mapStores, mapState } from "pinia";
import { useStorePersistStore } from "../../store/StorePersistStore";
import { Icon } from "@iconify/vue";
import VueCurrencyInput from "../../components/commons/VueCurrencyInput.vue";
import { GetAsset } from "../../services/stores/AssetsHelper";

import {
  KeyValuePairEntity,
} from "../../models/commons/CommonModels";
import {
  AddShoppingCartEntity,
  AddShoppingCartItemEntity,
} from "../../models/stores/ShoppingCartModels";
import { Toast } from "vant";
import {
  ModifyFavoriteProduct
} from "../../services/stores/ExternalUserService";
import { ElLoading, ElNotification } from "element-plus";
import _ from "lodash";
import { FailAndNeedHandle } from "../../services/commons/CommonService";
import {
  SourceTypeEnum,
  TypeEnum,
} from "../../models/stores/enums/DiscountActivityEnums";
import ProductDetail from "../../components/stores/ProductDetail.vue";
import { ProductViewModeEnum } from "@/models/stores/enums/ProductEnums";

export default defineComponent({
  name: "ProductSearchView",
  components: { Icon, VueCurrencyInput, ProductDetail },
  data() {
    return {
      isMounted: false,
      isDisabledSearch: false,
      pageSize: 20,
      isSearch: false,
      currentPage: 0,
      searchMode: "FuzzySearch",
      productViewModeEnum: ProductViewModeEnum,
      productViewMode: ProductViewModeEnum.CASH,
      viewMode: "list",
      isOpenFilter: false,
      isOpenOrder: false,
      isOpenProductDetail: false,
      isOpenHashTagDialog: false,
      productList: [] as Array<StoreProductListEntity>,
      hashTagList: [] as Array<KeyValuePairEntity>,
      filterEntity: {
        branchId: 0,
        categoryId: undefined,
        highestSellingPrice: undefined,
        lowestSellingPrice: undefined,
        highestPoint: undefined,
        lowestPoint: undefined,
        name: "",
        skuCode: "",
        orderBy: "HotSales",
        hashTagList: [] as Array<number>,
      } as ProductSearchEntity,

      fuzzySearchEntity: {
        branchId: 0,
        keyWords: "",
      } as StoreProductFuzzySearchEntity,

      filterTemplateEntity: {
        highestSellingPrice: undefined,
        lowestSellingPrice: undefined,
        highestPoint: undefined,
        lowestPoint: undefined,
        name: "",
        skuCode: "",
        hashTagList: [] as Array<number>,
      } as ProductSearchTemplateEntity,

      orderTemplateEntity: "HotSales",

      //data for product detail
      productIdToSearch: null as number | null,
      productDetail: {} as StoreProductDetailEntity,
      tempStock: {} as StoreStockKeepingUnitListEntity,
      showPicker: false,
      num: 1,
      isAutoPlay: 2000,
      carouselImageList: [] as Array<string>,

      isFreeze: false,
      carouselSize: true,
      showBigPic: false,
      bigPic: ""
    };
  },

  methods: {
    //取得商品明細並開啟商品明細選單
    async GetProductDetailAndOpenDrawer(id: number) {
      this.productIdToSearch = id;
      this.isOpenProductDetail = true;
    },

    //關閉商品明細並回覆預設值
    CloseAndResetProductDetail() {
      this.isOpenProductDetail = false;
      this.productDetail = {
        skuList: [] as Array<StoreStockKeepingUnitListEntity>,
        imageUrlList: [] as Array<string>,
        hashTagList: [] as Array<KeyValuePairEntity>,
      } as StoreProductDetailEntity;
      this.tempStock = {} as StoreStockKeepingUnitListEntity;
      this.num = 1;
      this.carouselImageList = [] as Array<string>;
      this.isAutoPlay = 2000;

      this.carouselSize = true;
    },

    //選擇商品選項時，按下確認相關操作
    SelectSku(value: StoreStockKeepingUnitListEntity) {
      this.num = 1;
      this.showPicker = false;
      this.SetCarouselImage(value);
    },

    // 設定輪播圖圖片
    SetCarouselImage(value: StoreStockKeepingUnitListEntity) {
      ///為顯示該Sku的金額
      this.tempStock = value;

      if (
        value.imageUrl != undefined ||
        value.imageUrl != null ||
        value.imageUrl != ""
      ) {
        const index = this.carouselImageList.indexOf(value.imageUrl);
        (this.$refs as any).carousel.swipeTo(index);

        this.isAutoPlay = 0;
      } else {
        (this.$refs as any).carousel.swipeTo(0);
        this.isAutoPlay = 0;
      }
    },

    //商品查詢
    async SearchProduct(): Promise<void> {
      if (this.isMounted == false) {
        return;
      }

      const loading = ElLoading.service({
        lock: true,
        text: "搜尋中",
        background: "rgba(0, 0, 0, 0.7)",
      });

      this.isDisabledSearch = true;

      if (this.searchMode == "FuzzySearch") {
        const res = await GetStoreProductByFuzzySearch({
          condition: this.fuzzySearchEntity,
          skip: this.currentPage * this.pageSize,
          take: this.pageSize,
        } as SearchCriteriaEntity<StoreProductFuzzySearchEntity>);

        loading.close();

        if (FailAndNeedHandle(res)) {
          Toast({
            message: "取得列表失敗",
            icon: "warning",
            duration: 500,
            className: "vant-toast-custom",
          });

          this.isDisabledSearch = false;
        } else {
          const ids = new Set(this.productList.map((m) => m.id));

          this.productList = [
            ...this.productList,
            ...res.data.data.list.filter((m) => ids.has(m.id) == false),
          ];

          if (res.data.data.list.length == this.pageSize) {
            this.currentPage++;
          }

          this.isDisabledSearch = res.data.data.totalCount == this.productList.length ? true : false;
        }
      } else {
        this.filterEntity.branchId = this.storePersistStore.currentBranchId;

        const res = await GetStoreProductList({
          condition: this.filterEntity,
          skip: this.currentPage * this.pageSize,
          take: this.pageSize,
        } as SearchCriteriaEntity<ProductSearchEntity>);

        loading.close();

        if (FailAndNeedHandle(res)) {
          Toast({
            message: "取得列表失敗",
            icon: "warning",
            duration: 500,
            className: "vant-toast-custom",
          });

          this.isDisabledSearch = false;
        } else {
          const ids = new Set(this.productList.map((m) => m.id));

          this.productList = [
            ...this.productList,
            ...res.data.data.list.filter((m) => ids.has(m.id) == false),
          ];

          if (res.data.data.list.length == this.pageSize) {
            this.currentPage++;
          }

          this.isDisabledSearch = res.data.data.totalCount == this.productList.length ? true : false;
        }
      }

      this.isSearch = true;
    },

    //選擇分類時的轉倒
    GoRoute(url: string): void {
      window.location.href = url;
    },

    //清空查詢條件
    ResetCondition(): void {
      this.filterEntity.highestSellingPrice = undefined;
      this.filterEntity.lowestSellingPrice = undefined;
      this.filterEntity.name = "";
      this.filterEntity.skuCode = "";
      this.filterEntity.hashTagList = [] as Array<number>;
    },

    /**
     * 依條件篩選/排序
     */
    async ApplyFilters() {
      let query = {} as any

      if (this.filterEntity.isFavorite) {
        query.favorite = "true"
      }

      if (this.productViewMode === ProductViewModeEnum.POINT) {
        query.pointItem = "true"
      }

      if (this.filterEntity.highestSellingPrice)
      {
        query.highestSellingPrice = this.filterEntity.highestSellingPrice 
      }

      if (this.filterEntity.lowestSellingPrice)
      {
        query.lowestSellingPrice = this.filterEntity.lowestSellingPrice
      }

      if (this.filterEntity.highestPoint)
      {
        query.highestPoint = this.filterEntity.highestPoint
      }

      if (this.filterEntity.lowestPoint)
      {
        query.lowestPoint = this.filterEntity.lowestPoint
      }

      if (this.filterEntity.name)
      {
        query.name = this.filterEntity.name
      }

      if (this.filterEntity.skuCode)
      {
        query.skuCode = this.filterEntity.skuCode
      }

      if (this.filterEntity.hashTagList != undefined
       && this.filterEntity.hashTagList.length > 0)
      {
        query.tags = this.filterEntity.hashTagList.join(',')
      }

      if (this.filterEntity.orderBy)
      {
        query.orderBy = this.filterEntity.orderBy
      }

      this.$router.replace({
        ...this.$router.currentRoute,
        query: query
      })

      this.isOpenOrder = false
      this.isOpenFilter = false
    },

    //Reset 篩選條件
    ResetTemplateAndCloseFilter() {
      this.filterTemplateEntity.highestSellingPrice = undefined;
      this.filterTemplateEntity.lowestSellingPrice = undefined;
      this.filterTemplateEntity.name = "";
      this.filterTemplateEntity.skuCode = "";
      this.filterTemplateEntity.hashTagList = [] as Array<number>;

      this.isOpenFilter = false;
    },

    //Reset 排序條件
    ResetTemplateAndCloseOrder() {
      this.filterEntity.orderBy = "HotSales"
    },

    //開啟排序選單
    OpenOrder() {
      this.isOpenOrder = true
    },

    //開啟篩選選單
    OpenFilter() {
      this.filterTemplateEntity.lowestSellingPrice =
        this.filterEntity.lowestSellingPrice;

      this.filterTemplateEntity.highestSellingPrice =
        this.filterEntity.highestSellingPrice;

      this.filterTemplateEntity.skuCode = this.filterEntity.skuCode;
      this.filterTemplateEntity.name = this.filterEntity.name;
      this.filterTemplateEntity.hashTagList = this.filterEntity.hashTagList;

      this.isOpenFilter = true;
    },

    //將品項加入購物車
    async AddCart() {
      if (this.storePersistStore.isLogin() == false) {
        this.GoRoute("/Store/Login");
        return;
      }

      const params = this.GetAddCartParams();

      const loading = ElLoading.service({
        lock: true,
        text: "加入中",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const result = await AddShoppingCart(params);

      if (result.status == 200) {
        this.storePersistStore.setCartQty(result.data.data.quantity);
        this.CloseAndResetProductDetail();
      } else {
        Toast({
          message: result.data.errorMessage,
          icon: "fail",
          duration: 2000,
          className: "vant-toast-custom",
        });
      }

      loading.close();

      if (FailAndNeedHandle(result)) {
        ElNotification({
          title: "加入購物車失敗",
          message: result.data.errorMessage,
          duration: 3000,
          type: "error",
        });
      } else {
        this.storePersistStore.setCartQty(result.data.data.quantity);
        this.CloseAndResetProductDetail();
      }
    },

    // 根據不同的檢視模式，產生加入購物車的request payload
    GetAddCartParams(): AddShoppingCartEntity {
      if (this.viewMode == "single") {
        const params = {
          productId: this.productDetail.id,
          itemList: [
            {
              stockKeepingUnitId: this.tempStock.id,
              quantity: this.num,
              isUsingPoint: this.isProductPointViewMode === true,
              point: this.tempStock.point
            },
          ],
        } as AddShoppingCartEntity;

        return params;
      } else if (this.viewMode == "list") {
        const params = {
          productId: this.productDetail.id,
          itemList: this.productDetail.skuList
            .filter((m) => m.purchaseQty > 0)
            .map((element) => {
              return {
                stockKeepingUnitId: element.id,
                quantity: element.purchaseQty,
                isUsingPoint: this.isProductPointViewMode === true,
                point: element.point
              } as AddShoppingCartItemEntity;
            }),
        } as AddShoppingCartEntity;

        return params;
      }

      return {} as AddShoppingCartEntity;
    },

    async AddOrRemoveFavorite(entity: StoreProductListEntity) {
      if (this.storePersistStore.isLogin() == false) {
        return;
      }

      const loading = ElLoading.service({
        lock: true,
        text: "加入中",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const result = await ModifyFavoriteProduct({
        productId: entity.id,
        addOrRemove: !entity.isFavorite,
      });

      loading.close();

      if (result.status == 200) {
        entity.isFavorite = !entity.isFavorite;

        const message = entity.isFavorite ? "已加入收藏" : "已移出收藏";
        const iconName = entity.isFavorite ? "like" : "like-o";

        Toast({
          message: message,
          icon: iconName,
          duration: 1000,
          className: "vant-toast-custom",
        });
      } else {
        Toast({
          message: "加入收藏失敗",
          icon: "warning",
          duration: 1000,
          className: "vant-toast-custom",
        });
      }
    },

    SetViewMode(value: string): void {
      this.viewMode = value;

      if (value == "list") {
        this.tempStock = {} as StoreStockKeepingUnitListEntity;
        this.num = 1;
      } else if (value == "single") {
        this.productDetail.skuList.forEach((element) => {
          element.purchaseQty = 0;
        });
      }
    },

    async GetHashTagListAndOpenDialog() {
      this.isOpenHashTagDialog = true;
    },

    AddOrRemoveToTemplate(id: number) {
      if (this.filterTemplateEntity.hashTagList.includes(id)) {
        const index = this.filterTemplateEntity.hashTagList.indexOf(id);
        this.filterTemplateEntity.hashTagList.splice(index, 1);
      } else {
        this.filterTemplateEntity.hashTagList.push(id);
      }
    },

    IsHashTagCheck(id: number) {
      return this.filterTemplateEntity.hashTagList.includes(id);
    },

    IsHashTagDisabled(id: number): boolean {
      if (this.filterTemplateEntity.hashTagList.includes(id)) {
        return false;
      }

      return this.filterTemplateEntity.hashTagList.length >= 5;
    },

    ResetHashTagTemplate() {
      this.filterTemplateEntity.hashTagList = [] as Array<number>;
    },

    ApplyHashTagTemplate() {
      this.isOpenHashTagDialog = false;
    },

    CalculateNumberUpperLimit(
      isAllowStockNegative: boolean,
      qtyPerUnitUpperLimit: number | undefined,
      quantity: number
    ): number {
      if (isAllowStockNegative == true && !!qtyPerUnitUpperLimit) {
        return qtyPerUnitUpperLimit;
      }

      if (isAllowStockNegative == false && !qtyPerUnitUpperLimit) {
        return quantity;
      }

      if (isAllowStockNegative == false && !!qtyPerUnitUpperLimit) {
        return qtyPerUnitUpperLimit >= quantity
          ? quantity
          : qtyPerUnitUpperLimit;
      }

      return Infinity;
    },

    ResetSearchCriteriaAndResult() {
      this.productList = [];
      this.currentPage = 0;
    },

    Scrolling: _.throttle(function (this: any, scrollEvent: any) {
      if (scrollEvent.scrollTop > 0) {
        this.carouselSize = false;
        return;
      }

      const scrollbar = (this.$refs as any).elScrollbar.$el.clientHeight;
      const infinteScroll = (this.$refs as any).elInfiniteScroll.clientHeight;

      if (this.carouselSize == false && scrollbar > infinteScroll) {
        return;
      }

      this.carouselSize = true;
    }, 100),

    GetSourceTypeTagColor(sourceType: string) {
      switch (sourceType) {
        case SourceTypeEnum.Activity:
          return "";
        case SourceTypeEnum.Contract:
          return "";
        default:
          return "";
      }
    },
    GetTypeTagColor(type: string) {
      switch (type) {
        case TypeEnum.DiscountWhenFillPriceCondition:
          return "#FF0000";
        case TypeEnum.DiscountWhenFillQtyCondition:
          return "#F55E3C";
        case TypeEnum.FreeGiftWhenFillQtyCondition:
          return "#FF8F8F";
        case TypeEnum.NthSpecialPrice:
          return "#00A7CC";
        case TypeEnum.FreeShippingWhenFillPrice:
          return "#8FCE00";
        default:
          return "#FFFFFF";
      }
    },
    /**
     * 回傳當前使用者是否有權限操作點數功能
     */
    IsUserAllowPointOperation(): boolean {
      return this.storePersistStore.isAllowPointOperation();
    },
    IsShowProductPrice(): boolean {
      return this.storePersistStore.isShowProductPrice();
    },

    DoGetAsset(path: string): string {
      return GetAsset(path);
    },
    ShowPic(item: string): void {
      if (item.trim() == '') {
        return;
      }

      this.showBigPic = true;
      this.bigPic = item;
    }
  },

  computed: {
    ...mapStores(useStorePersistStore),

    ...mapState(useStorePersistStore, {
      Menu: (store: any) => (store.isLogin == false ? [] : store.menu),
      DefaultOpenedsIndexArray: (store: any) =>
        store.isLogin == false ? [] : [...Array(store.menu.length).keys()],
    }),

    getStockPrice(): string {
      if (this.tempStock.price == null || this.tempStock.price == undefined) {
        return "請選擇選項";
      }

      return "NT$" + this.tempStock.price;
    },

    getStockQty(): string {
      if (
        this.tempStock.quantity == null ||
        this.tempStock.quantity == undefined
      ) {
        return "請選擇選項";
      }

      return "剩餘庫存" + this.tempStock.quantity;
    },

    getPoint(): string {
      if (this.tempStock.canUsePoint == false || this.tempStock.point == null) {
        return "";
      }

      return `${this.tempStock.point}`;
    },

    getUnit(): string {
      if (this.tempStock.unit == null || this.tempStock.unit == undefined) {
        return "請選擇選項";
      }

      return "單位：" + this.tempStock.unit;
    },

    orderWording(): string {
      if (
        this.filterEntity.orderBy === "PriceLowToHigh" ||
        this.filterEntity.orderBy === "PriceHighToLow"
      ) {
        return "排序(價格)";
      }

      if (
        this.filterEntity.orderBy === "LaunchNewToOld" ||
        this.filterEntity.orderBy === "LaunchOldToNew"
      ) {
        return "排序(上架)";
      }

      if (
        this.filterEntity.orderBy === "UpdatedDateTimeNewToOld" ||
        this.filterEntity.orderBy === "UpdatedDateTimeOldToNew"
      ) {
        return "排序(更新)";
      }

      return "排序";
    },

    filterWording(): string {
      let result = "篩選";

      let filterCount = 0;

      if (
        this.filterEntity.lowestSellingPrice != undefined ||
        this.filterEntity.highestSellingPrice != undefined
      ) {
        filterCount++;
      }

      if (this.filterEntity.skuCode != "") {
        filterCount++;
      }

      if (this.filterEntity.name != "") {
        filterCount++;
      }

      if (this.filterEntity.hashTagList.length > 0) {
        filterCount++;
      }

      if (filterCount != 0) {
        result = result + "(" + filterCount + ")";
      }

      return result;
    },
    menuWording(): string {
      let result = "分類";

      if (
        this.filterEntity.categoryId != undefined &&
        this.filterEntity.categoryId != null &&
        isNaN(this.filterEntity.categoryId) == false
      ) {
        const name = this.storePersistStore.getMenuItemName(
          this.filterEntity.categoryId
        );

        result = result + "(" + name + ")";
      }

      return result;
    },

    isDisableAddCartButton(): boolean {
      if (this.productDetail.canPurchase == false) {
        return true;
      }

      if (this.viewMode == "list") {
        if (
          this.productDetail.skuList == null ||
          this.productDetail.skuList.find((m) => m.purchaseQty > 0) == null
        ) {
          return true;
        }
        const item = this.productDetail.skuList.find((m) => m.purchaseQty > 0);

        if (item == undefined) return true;

        return false;
      }

      if (this.viewMode == "single") {
        return this.tempStock.id == undefined || this.tempStock.id == null;
      }

      return false;
    },

    isDisableAddCartWithPointButton(): boolean {
      if (this.viewMode == "list") {
        if (
          this.productDetail.skuList == null ||
          this.productDetail.skuList.every((m) => m.canUsePoint == false)
        ) {
          return true;
        }

        return false;
      }

      if (this.viewMode == "single") {
        return (
          this.tempStock.canUsePoint == false || this.tempStock.point == null
        );
      }

      return false;
    },

    isProductPointViewMode(): boolean {
      return this.productViewMode === this.productViewModeEnum.POINT;
    },

    calculateTempStockNumberUpperLimit(): number {
      if (
        this.tempStock.isAllowStockNegative == true &&
        !!this.tempStock.qtyPerUnitUpperLimit
      ) {
        return this.tempStock.qtyPerUnitUpperLimit > 0
          ? this.tempStock.qtyPerUnitUpperLimit
          : 1;
      }

      if (
        this.tempStock.isAllowStockNegative == false &&
        !this.tempStock.qtyPerUnitUpperLimit
      ) {
        return this.tempStock.quantity > 0 ? this.tempStock.quantity : 1;
      }

      if (
        this.tempStock.isAllowStockNegative == false &&
        !!this.tempStock.qtyPerUnitUpperLimit
      ) {
        const value =
          this.tempStock.qtyPerUnitUpperLimit >= this.tempStock.quantity
            ? this.tempStock.quantity
            : this.tempStock.qtyPerUnitUpperLimit;

        return value > 0 ? value : 1;
      }

      return Infinity;
    },

    AddToCartDesc(): string {
      if (this.productDetail != undefined && this.productDetail.canPurchase == false) {
        return "商品僅供檢視";
      }

      return "加入購物車";
    }
  },

  async mounted() {

    const loading = ElLoading.service({
      lock: true,
      text: "資料初始化...",
      background: "rgba(0, 0, 0, 0.7)",
    });

    const res = await GetHashTagIdAndNameList();

    loading.close();

    this.hashTagList = res.data.data;

    if (
      this.$route.query.fuzzyCriteria != undefined &&
      this.$route.query.fuzzyCriteria != null
    ) {
      this.fuzzySearchEntity.keyWords = this.$route.query
        .fuzzyCriteria as string;
      this.searchMode = "FuzzySearch";
    } else {
      this.searchMode = "Sharp";
      if (this.$route.params.id != undefined && this.$route.params.id != null) {
        this.filterEntity.categoryId = parseInt(
          this.$route.params.id as string
        );
      }

      if (
        this.$route.query.favorite != undefined &&
        this.$route.query.favorite != null
      ) {
        this.filterEntity.isFavorite = this.$route.query.favorite === "true";
      }

      if (
        this.$route.query.pointItem != undefined &&
        this.$route.query.pointItem != null
      ) {
        this.filterEntity.isPointItem = this.$route.query.pointItem === "true";
        this.productViewMode = ProductViewModeEnum.POINT;
      }

      if (this.$route.query.tags != undefined && this.$route.query.tags != null) {
        const tagIds = this.$route.query.tags.toString().split(',')

        this.filterEntity.hashTagList = tagIds.map(m => parseInt(m)) as Array<number>;
      }

      if (this.$route.query.highestSellingPrice)
      {
        this.filterEntity.highestSellingPrice = parseInt(this.$route.query.highestSellingPrice as string)
      }

      if (this.$route.query.lowestSellingPrice)
      {
        this.filterEntity.lowestSellingPrice = parseInt(this.$route.query.lowestSellingPrice as string)
      }

      if (this.$route.query.highestPoint)
      {
        this.filterEntity.highestPoint = parseInt(this.$route.query.highestPoint as string)
      }

      if (this.$route.query.lowestPoint)
      {
        this.filterEntity.lowestPoint = parseInt(this.$route.query.lowestPoint as string)
      }

      if (this.$route.query.name)
      {
        this.filterEntity.name = this.$route.query.name as string
      }

      if (this.$route.query.skuCode)
      {
        this.filterEntity.skuCode = this.$route.query.skuCode as string
      }

      if (this.$route.query.orderBy)
      {
        this.filterEntity.orderBy = this.$route.query.orderBy as string
      }
    }
    this.isMounted = true;
    await this.SearchProduct();
  },

  watch: {
    isFreeze() {
      if (this.isFreeze) {
        (document.querySelector("body") as any).classList.add("freeze");
        (document.querySelector("html") as any).classList.add("freeze-html");
        (document.querySelector(".el-main") as any).style.overflow = "hidden";
      } else {
        (document.querySelector("body") as any).classList.remove("freeze");
        (document.querySelector("html") as any).classList.remove("freeze-html");
        (document.querySelector(".el-main") as any).style.overflow = "auto";
      }
    },
  },
});
