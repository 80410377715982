
import { defineComponent } from "vue";
import { ElNotification, ElLoading } from "element-plus";
import {
  SendRegisterVericationCode,
  RegisterUser
} from "../../services/stores/ExternalUserService";
import { GetInternalUserDropdownList } from "../../services/stores/InternalUserService";
import {
  ExternalUserRegisterEntity
} from "../../models/stores/ExternalUserModels";
import { mapStores } from "pinia";
import { useStorePersistStore } from "../../store/StorePersistStore";
import { FailAndNeedHandle } from "../../services/commons/CommonService";
import RegexPattern from "../../commons/RegexPattern";
import {
  GoogleRecaptcha,
  GoogleRecaptchaAction
} from "../../models/commons/GoogleRecaptchaModels"

export default defineComponent({
  name: "StoreUserRegister",
  data() {
    return {
      /**
       * Loading
       * */
      verificationCodeBtnLoading: false,

      /**
       * Google reCAPTCHA
       */
      googleRecaptcha: null! as GoogleRecaptcha,

      /**
       * Dropdown list
       */
      internalUserDropdownList: [] as any,

      registerEntity: {
        account: "",
        password: "",
        verficationCode: "",
        internalUserId: null,
        note: "",
        inviteCode: "",
        recaptchaToken: ""
      } as ExternalUserRegisterEntity,
      verificationCodeBtnText: '發送驗證碼',

      /**
       * Validation rules
       */
      registerValidationRules: {
        account: [
          { required: true, message: '請輸入手機號碼', trigger: 'blur' },
          { pattern: RegexPattern.cellPhone, message: "請輸入手機號碼，共 10 碼數字", trigger: "blur" }
        ],
        password: [
          { required: true, message: "請輸入密碼", trigger: "blur" },
          { pattern: RegexPattern.password, message: "請輸入 6 ~ 20 字元大小寫英數字以及特殊符號 ~`!@#$%^&*+=-", trigger: "blur" }
        ],
        verficationCode: [
          { required: true, message: "請輸入驗證碼", trigger: "blur" },
          { max: 4, message: "長度上限為 4 字元", trigger: "blur" },
        ],
        name: [
          { required: true, message: "請輸入姓名", trigger: "blur" },
          { max: 30, message: "長度上限為 30 字元", trigger: "blur" },
        ],
        email: [
          { type: 'email', message: "請輸入正確的 Email", trigger: "blur" },
          { max: 100, message: "長度上限為 100 字元", trigger: "blur" },
        ],
        shippingAddress: [
          { required: true, message: "請輸入地址", trigger: "blur" },
          { max: 50, message: "長度上限為 50 字元", trigger: "blur" }
        ],
        internalUserId: [
          { required: true, message: "請選擇業務員", trigger: "change" }
        ],
        inviteCode: [
          { max: 10, message: "長度上限為 10 字元", trigger: "blur" }
        ]
      },
    };
  },
  methods: {
    LoadData() {
      this.LoadInternalUserDropdownList();
      this.LoadRecaptcha();
    },
    LoadInternalUserDropdownList() {
      GetInternalUserDropdownList()
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "負責業務清單載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            this.internalUserDropdownList = result.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    LoadRecaptcha() {
      this.googleRecaptcha = new GoogleRecaptcha();
      this.googleRecaptcha.LoadRecaptchaEnterprise();
    },
    ResetVerificationBtn() {
      this.verificationCodeBtnText = '發送驗證碼';
      this.verificationCodeBtnLoading = false;
    },
    VerificationBtnCountingDown() {
      let secToWait = 15;

      let intervalId = setInterval(() => {
        if (secToWait > 0) {
          secToWait--;
          this.verificationCodeBtnText = `已寄送 (${secToWait})`;
        }
        else {
          this.ResetVerificationBtn();
          clearInterval(intervalId);
        }
      }, 1000);
    },
    async SendVericationCode(): Promise<void> {
      // 目前帳號驗證只支援手機，
      let regex = new RegExp(RegexPattern.cellPhone);
      if (regex.test(this.registerEntity.account) == false) {
        ElNotification({
          title: "寄送驗證簡訊",
          message: "手機格式錯誤，請確認",
          duration: 3000,
          type: "error",
        });
        return;
      }

      if (this.storePersistStore.isB2CEnabled == false) {
        ElNotification({
          title: "尚未啟用",
          message: "寄送驗證簡訊失敗，請洽系統管理員",
          duration: 3000,
          type: "error",
        });
        return
      }

      this.verificationCodeBtnLoading = true;

      // 取得 reCAPTCHA Token
      let recaptchaToken = await this.googleRecaptcha.GetEnterpriseTokenAsync(GoogleRecaptchaAction.VerificationCode);

      SendRegisterVericationCode(this.registerEntity.account, recaptchaToken, GoogleRecaptchaAction.VerificationCode)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "寄送驗證簡訊失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });

            this.verificationCodeBtnLoading = false;
            return;
          }

          this.VerificationBtnCountingDown();
        })
        .catch((err) => {
          ElNotification({
            title: "寄送驗證簡訊失敗",
            message: err,
            duration: 3000,
            type: "error",
          });

          this.verificationCodeBtnLoading = false;
        })
    },
    async Register(): Promise<void> {
      if (await this.FormValidation(this.$refs.storeUserRegisterFormRef) == false) {
        return;
      }

      const loading = ElLoading.service({
        lock: true,
        text: "註冊會員中",
        background: "rgba(0, 0, 0, 0.7)",
      });

      var result = await RegisterUser(this.registerEntity);

      loading.close();

      if (FailAndNeedHandle(result)) {
        ElNotification({
          title: "註冊失敗",
          message: result.data.errorMessage,
          duration: 3000,
          type: "error",
        });
      } else {
        const token = result.headers.authorization.replace("Bearer", "").trim();
        this.storePersistStore.setPermission(token);

        this.storePersistStore.setCurrentBranchId(
          this.storePersistStore.externalUserEntity.permission.branchSetting.defaultBranchId
        );

        this.$router.push("ProductSearch");
      }
    },
    async FormValidation(refs: any): Promise<boolean> {
      return refs.validate((valid: any) => {
        if (valid == false) {
          return valid;
        }
      });
    },
    HandleInviteCodeInput(val: string) {
      if (val == undefined) {
        return
      }
      const reg = /[^A-Z0-9]/g
      this.registerEntity.inviteCode = val.toUpperCase().replace(reg, '');
    }
  },
  computed: {
    ...mapStores(useStorePersistStore)
  },
  mounted() {
    this.LoadData();
    this.ResetVerificationBtn();
  }
});
