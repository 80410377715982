import { ApiResponseEntity } from "@/models/ApiModels";
import { AxiosResponse } from "axios";
import { 
  ExternalUserForgetPasswordEntity,
  ExternalUserInfoEntity, 
  ExternalUserResetPasswordEntity,
  ModifyFavoriteProductEntity,
  ExternalUserRegisterEntity,
  ExternalUserUpdateEntity,
  ExternalUserLineRegisterEntity,
  ExternalUserLineBindingEntity,
  ManageExtUserResponseEntity,
  GetModuleSettingsResponseEntity
} from "../../models/stores/ExternalUserModels"
import { DropdownEntity, LoginEntity, LoginResponseEntity, SubMenuEntity } from "@/models/commons/CommonModels";
import { Post } from "../commons/CommonService"

const prefix = "/api/store"

export async function ExternalUserLogin(params: LoginEntity): Promise<AxiosResponse<ApiResponseEntity<LoginResponseEntity>, any>> {

  const url = prefix + "/ExternalUser/Login";

  const result = await Post<LoginEntity, LoginResponseEntity>(url, params);

  return result;
}

export async function ExternalUserForgetPassword(params: ExternalUserForgetPasswordEntity): Promise<AxiosResponse<ApiResponseEntity<string>, any>> {

  const url = prefix + "/ExternalUser/ForgetPassword";

  const result = await Post<ExternalUserForgetPasswordEntity, string>(url, params);

  return result;
}

export async function ExternalUserResetPassword(params: ExternalUserResetPasswordEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

  const url = prefix + "/ExternalUser/ResetPassword";

  const result = await Post<ExternalUserResetPasswordEntity, object>(url, params);

  return result;
}

export async function ExternalUserLogout(): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

  const url = prefix + "/ExternalUser/Logout";

  const result = await Post<object, object>(url, {});

  return result;
}

export async function ModifyFavoriteProduct(params: ModifyFavoriteProductEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

  const url = prefix + "/ExternalUser/ModifyFavoriteProduct";

  const result = await Post<ModifyFavoriteProductEntity, object>(url, params);

  return result;
}

export async function GetUserInfo(): Promise<AxiosResponse<ApiResponseEntity<ExternalUserInfoEntity>, any>> {

  const url = prefix + "/ExternalUser/GetUserInfo";

  const result = await Post<object, ExternalUserInfoEntity>(url, {});

  return result;
}

export async function Update(params: ExternalUserUpdateEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

  const url = prefix + "/ExternalUser/Update";

  const result = await Post<ExternalUserUpdateEntity, object>(url, params);

  return result;
}

export async function GetMenu(): Promise<AxiosResponse<ApiResponseEntity<Array<SubMenuEntity>>, any>> {

  const url = prefix + "/ExternalUser/GetMenu";

  const result = await Post<object, Array<SubMenuEntity>>(url, {});
  return result;
}

export async function GetMenuByBranch(branchId : number): Promise<AxiosResponse<ApiResponseEntity<Array<SubMenuEntity>>, any>> {

  const url = prefix + `/ExternalUser/GetMenuByBranch?branchId=${branchId}`;

  const result = await Post<object, Array<SubMenuEntity>>(url, {});
  return result;
}


export async function SendRegisterVericationCode(account: string, recaptchaToken: string, recaptchaAction: string): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

  const url = prefix + `/ExternalUser/SendRegisterVericationCode?account=${account}&recaptchaToken=${recaptchaToken}&recaptchaAction=${recaptchaAction}`;

  const result = await Post<object, ExternalUserInfoEntity>(url, null!);

  return result;
}

export async function RegisterUser(entity: ExternalUserRegisterEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

  const url = prefix + "/ExternalUser/RegisterUser";

  const result = await Post<ExternalUserRegisterEntity, object>(url, entity);

  return result;
}

export async function GetModuleSettings(): Promise<AxiosResponse<ApiResponseEntity<GetModuleSettingsResponseEntity>, any>> {

  const url = prefix + "/ExternalUser/GetModuleSettings";

  const result = await Post<object, GetModuleSettingsResponseEntity>(url, {});

  return result;
}

export async function RegisterAndBindLineUser(request: ExternalUserLineRegisterEntity): Promise<AxiosResponse<ApiResponseEntity<any>, any>> {

  const url = prefix + "/ExternalUser/RegisterAndBindLineUser";

  const result = await Post<object, any>(url, request);

  return result;
}

export async function BindLineUser(request: ExternalUserLineBindingEntity): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {
  
  const url = prefix + "/ExternalUser/BindLineUser";

  const result = await Post<object, any>(url, request);

  return result;
}

export async function GetManageUserList(): Promise<AxiosResponse<ApiResponseEntity<Array<ManageExtUserResponseEntity>>, any>> {

  const url = prefix + "/ExternalUser/GetManageUserList";

  const result = await Post<object, any>(url, null!);

  return result;
}

export async function ActivateUser(extUserId: number): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

  const url = prefix + "/ExternalUser/ActivateUser?externalUserId=" + extUserId;

  const result = await Post<object, any>(url, null!);

  return result;
}

export async function DeactivateUser(extUserId: number): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

  const url = prefix + "/ExternalUser/DeactivateUser?externalUserId=" + extUserId;

  const result = await Post<object, any>(url, null!);

  return result;
}

export async function UnbindLineUser(extUserId: number): Promise<AxiosResponse<ApiResponseEntity<object>, any>> {

  const url = prefix + "/ExternalUser/UnbindLineUser?externalUserId=" + extUserId;

  const result = await Post<object, any>(url, null!);

  return result;
}