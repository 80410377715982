
import { defineComponent } from "vue";
import ElCurrencyInput from "../../components/commons/ElCurrencyInput.vue";
import moment from "moment";
import { ElNotification, ElMessageBox } from "element-plus";
import {
  UpdateStatus,
  GetProductCategoryDropdownList,
  SearchSpu,
  DeleteSpuById,
  ExportSpuAsExcel,
} from "../../services/backstages/SpuService";
import { ExportSkuAsExcel } from "../../services/backstages/SkuService";
import {
  SpuSearchEntity,
  SpuListRequestEntity,
  SpuListResponseEntity,
  SpuUpdateStatusEntity,
} from "../../models/backstages/SpuModels";
import { SkuSearchEntity } from "../../models/backstages/SkuModels";
import { ExcelExportRequestEntity } from "../../models/commons/CommonModels";
import { FailAndNeedHandle } from "../../services/commons/CommonService";
import FileImportDialog from "../../components/commons/FileImportDialog.vue";

export default defineComponent({
  name: "SPUListView",
  components: {
    ElCurrencyInput,
    FileImportDialog
  },
  data() {
    return {
      isSpuDataLoading: false,
      isSpuStatusChanged: false,
      isOpenFilter: false,
      isOpenMenu: false,
      isOpenOrder: false,
      spuSearchEntity: {
        take: 10,
        skip: 0,
        name: "",
        skuName: "",
        productCategoryId: null!,
        launchDateStart: null!,
        launchDateEnd: null!,
        updatedDateStart: null!,
        updatedDateEnd: null!,
        highestSellingPrice: null!,
        lowestSellingPrice: null!,
        productOuterSysCode: "",
        skuOuterSysCode: "",
        status: "",
        orderCondition: {
          name: "Id",
          isAscending: false,
        },
      } as SpuSearchEntity,
      filterEntity: {
        launchDateStart: new Date(),
        launchDateEnd: new Date(),
        highestSellingPrice: undefined,
        lowestSellingPrice: undefined,
      },
      requestEntity: {
        skip: 0,
        take: 50,
      } as SpuListRequestEntity,
      tableData: [] as Array<SpuListResponseEntity>,

      productCategoryMenu: [] as any,
      selectedProductCategoryId: null! as number,

      /**
       * Pagination vars
       */
      currentPage: 1,
      totalPage: 0,
      currentCount: 0,
      totalCount: 0,
      pageSize: 10,
      pageSizes: [10, 25, 50, 100],

      /**
       * imnport file url
       */
      importApiUrl: "",
      isOpenImportFile: false,
      importMsg: ""
    };
  },
  created() {
    this.ClearData();
    this.LoadData();
  },
  computed: {
    menuBtnText(): string {
      let text = "分類";
      if (
        this.selectedProductCategoryId != undefined &&
        this.selectedProductCategoryId != null &&
        isNaN(this.selectedProductCategoryId) == false
      ) {
        let selectedCategory = this.productCategoryMenu
          .flatMap((parent: any) => parent.children)
          .find((child: any) => child.id == this.selectedProductCategoryId);
        text = text + "(" + selectedCategory.name + ")";
      }

      return text;
    },
    filterBtnText(): string {
      let text = "篩選";

      let filterCount = 0;
      if (this.spuSearchEntity.name != "") {
        filterCount++;
      }

      if (this.spuSearchEntity.skuName != "") {
        filterCount++;
      }

      if (
        this.spuSearchEntity.launchDateStart != undefined ||
        this.spuSearchEntity.launchDateEnd != undefined
      ) {
        filterCount++;
      }

      if (
        this.spuSearchEntity.updatedDateStart != undefined ||
        this.spuSearchEntity.updatedDateEnd != undefined
      ) {
        filterCount++;
      }

      if (
        this.spuSearchEntity.lowestSellingPrice != undefined ||
        this.spuSearchEntity.highestSellingPrice != undefined
      ) {
        filterCount++;
      }

      if (this.spuSearchEntity.productOuterSysCode != "") {
        filterCount++;
      }

      if (this.spuSearchEntity.skuOuterSysCode != "") {
        filterCount++;
      }

      if (filterCount != 0) {
        text = text + "(" + filterCount + ")";
      }

      return text;
    },
  },
  methods: {
    ClearConditionFilterData() {
      this.spuSearchEntity = {
        take: 10,
        skip: 0,
        name: "",
        skuName: "",
        productCategoryId: null!,
        launchDateStart: null!,
        launchDateEnd: null!,
        updatedDateStart: null!,
        updatedDateEnd: null!,
        highestSellingPrice: null!,
        lowestSellingPrice: null!,
        productOuterSysCode: "",
        skuOuterSysCode: "",
        status: "",
        orderCondition: {
          name: "Id",
          isAscending: false,
        },
      };
    },
    ClearData() {
      this.ClearConditionFilterData();
    },
    LoadData() {
      this.SearchSpuData();
      this.LoadProductCategory();
    },
    /**
     * 載入商品分類清單
     */
    async LoadProductCategory() {
      GetProductCategoryDropdownList()
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "商品分類載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            this.productCategoryMenu = result.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    ApplyProductCategoryFilter() {
      this.spuSearchEntity.productCategoryId = this.selectedProductCategoryId;
      this.isOpenMenu = false;
      this.SearchSpuData();
    },
    ClearProductCategoryFilter() {
      // temporary solution trying to remove ACTIVE effect on menu item
      var elements = document.getElementsByClassName("el-menu-item is-active");
      if (elements.length > 0) {
        elements[0].classList.remove("is-active");
      }

      this.selectedProductCategoryId = null!;
    },
    ApplyConditionFilter() {
      this.isOpenFilter = false;
      this.SearchSpuData();
    },
    ClearConditionFilter() {
      this.ClearConditionFilterData();
    },
    SearchSpuData() {
      this.isSpuDataLoading = true;

      this.spuSearchEntity.skip = this.pageSize * (this.currentPage - 1);
      this.spuSearchEntity.take = this.pageSize;

      SearchSpu(this.spuSearchEntity)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElNotification({
              title: "商品載入失敗",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });
          } else {
            let paginationResult = result.data.data;
            this.tableData = paginationResult.data;
            this.currentCount = paginationResult.currentCount;
            this.totalCount = paginationResult.totalCount;
            this.totalPage = paginationResult.totalPage;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isSpuDataLoading = false;
        });
    },
    OpenUrl(url: string) {
      window.open(url, "_blank");
    },
    OpenFilter() {
      this.isOpenFilter = true;
    },
    OpenMenu() {
      this.isOpenMenu = true;
    },
    OpenOrder() {
      this.isOpenOrder = true;
    },
    HandleSizeChange(val: number) {
      this.pageSize = val;
      this.SearchSpuData();
    },
    HandleCurrentChange(val: number) {
      this.currentPage = val;
      this.SearchSpuData();
    },
    DateFormatter(row: any, column: any) {
      let d = new Date();

      if (column.property == "createdDateTime") {
        d = new Date(row.createdDateTime);
      } else {
        d = new Date(row.updatedDateTime);
      }

      let mm = d.getMonth() + 1;
      let dd = d.getDate();
      let hh = d.getHours();
      let m = d.getMinutes();
      return `${d.getFullYear()}/${(mm > 9 ? "" : "0") + mm}/${(dd > 9 ? "" : "0") + dd
        } ${(hh > 9 ? "" : "0") + hh}:${(m > 9 ? "" : "0") + m}`;
    },
    MenuItemClickAction(element: any) {
      this.selectedProductCategoryId = element.index;
    },
    EditSpuAction(index: any, row: any) {
      this.OpenUrl("Edit/" + row.id);
    },
    DeleteSpuAction(index: any, row: any) {
      let id = row.id;

      ElMessageBox.confirm(
        `是否要刪除商品 id: ${id}？ <br>
         <b style='color:red;'>會連相關的 SKU 也一併刪除，但不影響舊訂單</b>`,
        "警告",
        {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning",
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          DeleteSpuById(id)
            .then(() => {
              ElNotification({
                title: `刪除商品`,
                message: `商品 Id:${id} 刪除成功`,
                duration: 3000,
                type: "success",
              });

              //// reload spu data
              this.SearchSpuData();
            })
            .catch(() => {
              ElNotification({
                title: `刪除商品`,
                message: `商品 Id:${id} 刪除失敗，請確認！`,
                duration: 3000,
                type: "error",
              });
            });
        })
        .catch(() => { });
    },
    HandleSpuStatusChange(index: any, row: any) {
      let entity: SpuUpdateStatusEntity = {
        id: row.id,
        status: row.status,
      };

      UpdateStatus(entity)
        .then((result) => {
          if (result.status === 200) {
            ElNotification({
              title: "更新商品",
              message: "更新商品狀態成功",
              duration: 3000,
              type: "success",
            });
          } else {
            ElNotification({
              title: "更新商品",
              message: result.data.errorMessage,
              duration: 3000,
              type: "error",
            });

            //// reload spu data
            this.SearchSpuData();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    HandleSortChange(column: any) {
      if (column.column !== null) {
        this.spuSearchEntity.orderCondition.name = this.CapitalizeFirstLetter(
          column.prop
        );
        this.spuSearchEntity.orderCondition.isAscending =
          column.order == "ascending";
      } else {
        this.spuSearchEntity.orderCondition.name = "";
        this.spuSearchEntity.orderCondition.isAscending = true;
      }

      this.SearchSpuData();
    },
    /**
     * 將英文的首字母轉換成大寫
     * @param str 要轉換的字串
     * @returns 首字母大寫
     */
    CapitalizeFirstLetter(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    /**
     * 根據商品搜尋條件，輸出資料為 Excel
     */
    ExportSpuAsExcel() {
      let fileName = `商品匯出_${moment().format("yyyy_MM_DD_hh_mm_ss")}.xlsx`;
      let entity: ExcelExportRequestEntity<SpuSearchEntity> = {
        searchCriteria: this.spuSearchEntity,
        fileName: fileName,
      };

      ExportSpuAsExcel(entity).then((result) => {
        if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "商品匯出失敗",
            message: result.data.errorMessage,
            duration: 3000,
            type: "error",
          });
        } else {
          let data: any = result.data;
          let blob = new Blob([data]);
          var el = document.createElement("a");
          el.href = URL.createObjectURL(blob);
          el.download = fileName;
          el.click();
        }
      });
    },
    /**
     * 根據 SKU 搜尋條件，輸出資料為 Excel
     */
    ExportSkuAsExcel() {
      let fileName = `SKU 匯出_${moment().format("yyyy_MM_DD_hh_mm_ss")}.xlsx`;

      let entity: ExcelExportRequestEntity<SkuSearchEntity> = {
        searchCriteria: {
          productId: null!,
          outerSysCode: this.spuSearchEntity.skuOuterSysCode,
          name: this.spuSearchEntity.skuName,
          highestPrice: this.spuSearchEntity.highestSellingPrice,
          lowestPrice: this.spuSearchEntity.lowestSellingPrice,
          status: "",
          highestInventoryQty: null!,
          lowestInventoryQty: null!,
          orderBy: "",
        } as SkuSearchEntity,
        fileName: fileName,
      };

      ExportSkuAsExcel(entity).then((result) => {
        if (FailAndNeedHandle(result)) {
          ElNotification({
            title: "SKU 匯出失敗",
            message: result.data.errorMessage,
            duration: 3000,
            type: "error",
          });
        } else {
          let data: any = result.data;
          let blob = new Blob([data]);
          var el = document.createElement("a");
          el.href = URL.createObjectURL(blob);
          el.download = fileName;
          el.click();
        }
      });
    },
    BatchCreate() {
      this.importApiUrl = "/api/backstage/Spu/BatchCreate";
      this.isOpenImportFile = true;
      this.importMsg = "匯入新增商品檔";
    },
    BatchUpdateSpu() {
      this.importApiUrl = "/api/backstage/Spu/ImportAndBatchUpdateSpu";
      this.isOpenImportFile = true;
      this.importMsg = "匯入更新商品檔";
    },
    BatchUpdateSku() {
      this.importApiUrl = "/api/backstage/Sku/ImportAndBatchUpdate";
      this.isOpenImportFile = true;
      this.importMsg = "匯入更新 SKU 檔";
    },
    CloseFileImportDialog() {
      this.importApiUrl = "";
      this.isOpenImportFile = false;
      this.importMsg = "";
    }
  },
});
