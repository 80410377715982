
import { defineComponent } from "vue";
import { ElNotification, ElMessageBox } from "element-plus";
import {
  InternalUserLogin,
  InternalUserForgetPassword,
  InternalUserResetPassword,
} from "../../services/backstages/InternalUserService";
import {
  InternalUserForgetPasswordEntity,
  InternalUserResetPasswordEntity,
} from "../../models/backstages/InternalUserModels";
import { mapStores } from "pinia";
import { useBackStagePersistStore } from "../../store/BackStagePersistStore";
import { LoginEntity } from "../../models/commons/CommonModels";
import { GetMenu } from "../../services/backstages/ViewService";
import { FailAndNeedHandle } from "../../services/commons/CommonService";
import { GetAsset } from "../../services/backstages/AssetsHelper";
import _ from "lodash";
import {
  GoogleRecaptcha,
  GoogleRecaptchaAction
} from "../../models/commons/GoogleRecaptchaModels"

export default defineComponent({
  name: "BackStageLoginView",
  data() {
    return {
      /**
       * Loading
       * */
      loading: false,
      forgetPasswordBtnLoading: false,
      resetPasswordBtnLoading: false,

      googleRecaptcha: null! as GoogleRecaptcha,

      loginEntity: {
        account: "",
        password: "",
        recaptchaToken: "",
        recaptchaAction: ""
      } as LoginEntity,
      forgetPasswordEntity: {
        account: "",
        recaptchaToken: "",
        recaptchaAction: ""
      } as InternalUserForgetPasswordEntity,
      resetPasswordEntity: {
        account: "",
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      } as InternalUserResetPasswordEntity,
      rememberPassword: false,
      forgetPasswordDialogFormVisible: false,
      resetPasswordDialogFormVisible: false,
      formLabelWidth: "90px",
    };
  },
  methods: {
    LoadRecaptcha() {
      this.googleRecaptcha = new GoogleRecaptcha();
      this.googleRecaptcha.LoadRecaptchaEnterprise();
    },
  async Login(): Promise<void> {

      // validation
      if (_.isEmpty(this.loginEntity.account) || _.isEmpty(this.loginEntity.password)) {
        ElNotification({
          title: "登入失敗",
          message: "請輸入帳號及密碼",
          duration: 3000,
          type: "error",
        });

        return;
      }

      this.loading = true;

      // 取得 reCAPTCHA Token
      this.loginEntity.recaptchaToken = await this.googleRecaptcha.GetEnterpriseTokenAsync(GoogleRecaptchaAction.ForgetPassword);
      this.loginEntity.recaptchaAction = GoogleRecaptchaAction.Login;

      const res = await InternalUserLogin(this.loginEntity);

      this.loading = false;

      if (FailAndNeedHandle(res)) {
        ElNotification({
          title: "登入失敗",
          message: res.data.errorMessage,
          duration: 3000,
          type: "error",
        });
      } else if (res.data.data.isFirstTimeLogin) {
        // 首次登入處理
        this.resetPasswordDialogFormVisible = true;
      } else {
        const token = res.headers.authorization.replace("Bearer", "").trim();

        this.backStagePersistStore.setPermission(token);

        const menu = await GetMenu();

        this.backStagePersistStore.setMenu(menu.data.data);

        this.$router.push({ name: "SPUList" });
      }
    },
    async ForgetPassword() {
      if (_.isEmpty(this.forgetPasswordEntity.account)) {
        ElMessageBox.alert("帳號不得為空", "重設密碼", {
          confirmButtonText: "確定",
        });
        return;
      }

      this.forgetPasswordBtnLoading = true;
      
      // 取得 reCAPTCHA Token
      this.forgetPasswordEntity.recaptchaToken = await this.googleRecaptcha.GetEnterpriseTokenAsync(GoogleRecaptchaAction.ForgetPassword);
      this.forgetPasswordEntity.recaptchaAction = GoogleRecaptchaAction.ForgetPassword;

      // call forget password API
      InternalUserForgetPassword(this.forgetPasswordEntity)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElMessageBox.alert(result.data.errorMessage, "忘記密碼", {
              confirmButtonText: "確定",
            });
          } else {
            ElMessageBox.alert("新密碼已寄出到您設定的信箱", "忘記密碼", {
              confirmButtonText: "確定",
              callback: () => {
                this.forgetPasswordDialogFormVisible = false;
              },
            });
          }
        })
        .catch(() => {
          ElMessageBox.alert("系統錯誤，請重新嘗試", "忘記密碼", {
            confirmButtonText: "確定",
          });
        })
        .finally(() => {
          this.forgetPasswordBtnLoading = false;
        });
    },
    ResetPassword() {
      if (_.isEmpty(this.resetPasswordEntity.oldPassword)) {
        ElMessageBox.alert("舊密碼不得為空", "重設密碼", {
          confirmButtonText: "確定",
        });
        return;
      } else if (_.isEmpty(this.resetPasswordEntity.newPassword)) {
        ElMessageBox.alert("新密碼不得為空", "重設密碼", {
          confirmButtonText: "確定",
        });
        return;
      } else if (_.isEmpty(this.resetPasswordEntity.confirmNewPassword)) {
        ElMessageBox.alert("確認密碼不得為空", "重設密碼", {
          confirmButtonText: "確定",
        });
        return;
      }

      this.resetPasswordEntity.account = this.loginEntity.account;

      this.resetPasswordBtnLoading = true;
      InternalUserResetPassword(this.resetPasswordEntity)
        .then((result) => {
          if (FailAndNeedHandle(result)) {
            ElMessageBox.alert(result.data.errorMessage, "重設密碼", {
              confirmButtonText: "確定",
            });
          } else {
            ElMessageBox.alert("重設密碼成功! 請重新登入", "重設密碼", {
              confirmButtonText: "確定",
              callback: () => {
                this.resetPasswordDialogFormVisible = false;
                this.loginEntity.password = "";
              },
            });
          }
        })
        .catch(() => {
          ElMessageBox.alert("系統錯誤，請重新嘗試", "忘記密碼", {
            confirmButtonText: "確定",
          });
        })
        .finally(() => {
          this.resetPasswordBtnLoading = false;
        });
    },

    DoGetAsset(path: string): string {
      return GetAsset(path);
    }
  },

  computed: {
    ...mapStores(useBackStagePersistStore),
  },

  mounted() {
    this.LoadRecaptcha();
  }
});
